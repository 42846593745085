import {KycPreviewDto, KycProvider} from "../types";
import {KycReviewReasonType, KycStatusType} from "../constants";

const providersBaseLink: Record<KycProvider, string> = {
    VERIFF: '',
    DOJAH: 'https://app.dojah.io/verifications/bio-data/',
    SMILE: 'https://portal.usesmileid.com/partner/job_results/production/',
}

const buildSmileLink = (verification: KycPreviewDto): string => {
    const baseUrl = providersBaseLink[KycProvider.SMILE];
    if(verification.data.status === KycStatusType.REVIEW_REQUIRED &&  [KycReviewReasonType.AML_CHECK_ERROR, KycReviewReasonType.AML_CHECK_ERROR].includes(verification.data.reviewReason) && verification.job.responseData?.amlResponseRef) {
        return baseUrl  + verification.job.responseData?.amlResponseRef;
    }
    if(verification.job.sessionId) {
        return baseUrl  + verification.job.sessionId;
    }
    return "#"
}

const buildVeriffLink = (verification: KycPreviewDto) => {
    return "#";
}

const buildDojahLink = (verification: KycPreviewDto) => {
    if(verification.job.sessionId) {
        return providersBaseLink[KycProvider.VERIFF] + verification.job.sessionId;
    }

    return "#";

}

const buildVerificationLink = (data: KycPreviewDto) => {
    if(data.job.provider === KycProvider.SMILE) {
        return buildSmileLink(data);
    } else if(data.job.provider === KycProvider.VERIFF) {
        return buildVeriffLink(data)
    } else if(data.job.provider === KycProvider.DOJAH) {
        return buildDojahLink(data)
    }
    return "#"
}

export default buildVerificationLink;