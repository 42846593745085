import { useAdminPermission } from '../../../../hooks';
import { AdsTable } from '../../../tables';


const AdsList = () => {
  const { hasRequiredPermissions } = useAdminPermission();
  const showFull = hasRequiredPermissions(['MANAGE_USERS'], 'ADMIN');

  return (
    <div>
      <AdsTable limit={20} showFull={showFull} enableFilter showPagination />
    </div>
  );
};

export default AdsList;