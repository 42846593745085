import React, { useEffect, useMemo, useState } from 'react';
import { getAppModules } from '../../../api';
import { useAdminPermission } from '../../../hooks';
import { AppModuleDto } from '../../../types';
import { UpdateServiceStatusModal } from '../../Modals';
import Table from '../../Table';
import { AppModuleColums } from './config';

const AppModulesTable: React.FC<unknown> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [appModules, setAppModules] = useState<AppModuleDto[]>([]);
  const columns = useMemo(() => AppModuleColums, []);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [moduleId, setAppModuleId] = useState<string>('');

  const { hasRequiredPermissions } = useAdminPermission();
  const showViewMore = hasRequiredPermissions([], 'ADMIN');

  const closeModal = () => setOpenModal(false);


  const openStatusModal = (id: string)=>{
    setAppModuleId(id)
    setOpenModal(true);
  }

  const UpdateTable = ()=> {
    setLoading(true);
    getAppModules()
      .then((data ) => setAppModules(data))
      .catch(console.log)
      .finally(() => {setLoading(false)});
  }

  useEffect(() => {
    setLoading(true);
    getAppModules()
      .then((data ) => setAppModules(data))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);


  return (
    <div>
      <Table columns={columns(openStatusModal, showViewMore)} data={appModules} loading={loading} />
      <UpdateServiceStatusModal closeModal={closeModal} id={moduleId} open={openModal} type='AppModule' updateTable={UpdateTable} />
    </div>
  );
};

export default AppModulesTable;

