import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { RelativeTimeCell } from '../common';

export const tradeDisputeColumns = (showFull?: boolean, canViewMore?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Order Id',
    accessor: 'trade.tradeOrderId',
  },
  {
    Header: 'Order Type',
    accessor: 'trade.tradeType',
  },
  
  {
    Header: 'Amount',
    accessor: 'trade.amountToTransfer',
  },
  {
    Header: 'Buyer',
    accessor: 'trade.maker.username',
  },
  {
    Header: 'seller',
    accessor: 'trade.taker.username',
  },
  
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  ...(canViewMore? [{
    Header: ' ',
    accessor: 'id',
    Cell: ({value}: Cell)=>(<Link to={`${value}`}>view more</Link>)
  }]:[])
];

