import { useEffect, useMemo, useState } from 'react';
import { VscChevronLeft } from 'react-icons/vsc';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAdminUserById } from '../../../api';
import Button from '../../../components/Button';
import MainWrapper from '../../../components/MainWrapper';
import { PermissionsModal, RolesModal, UpdateAdminStatusModal } from '../../../components/Modals';
import { AuditTable } from '../../../components/tables';
import { AdminUserDto } from '../../../types';

const AdminUserDetails = () => {
  const [user, setUser] = useState<AdminUserDto>();
  const [openRoleModal, setOpenRoleModal] = useState<boolean>(false);
  const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
  const [openStatusModal, setOpenStatusModal] = useState<boolean>(false);

  const { userId } = useParams();

  const closeStatusModal = () => setOpenStatusModal(false);
  const closeRoleModal = () => setOpenRoleModal(false);
  const closePermissionModal = () => setOpenPermissionModal(false);

  const setUserCallback = (user: AdminUserDto) => {
    setUser(user);
    toast.success('Update successful');
  };

  useEffect(() => {
    if (userId) {
      getAdminUserById(userId)
        .then((data) => setUser(data))
        .catch(console.log);
    }
  }, [userId]);

  const actionType = useMemo(() => {
    return user?.status === 'ENABLED' ? 'DEACTIVATE' : 'ACTIVATE';
  }, [user]);

  return (
    <MainWrapper title='Users Manager'>
      <div className='flex flex-col gap-3'>
        <Link to={'/admin'} className='flex items-center gap-1'>
          <VscChevronLeft />
          <div className='rounded'>Back to users manager</div>
        </Link>

        <div className='flex  rounded-lg shadow gap-6'>
          <div className='flex gap-2 px-6 py-9'>
            <div className='flex rounded-full h-14 w-14 text-white justify-center items-center bg-black'>
              {user?.firstName[0]}
              {user?.lastName[0]}
            </div>
            <div className='flex flex-col'>
              <div className='text-14'>Email</div>
              <div className='text-16 font-semibold'>{user?.email}</div>
              <Button className='px-6 py-1 mt-2 text-14 bg-amber-50 text-yellow-700 font-medium'>
                {user?.status === 'ENABLED' ? 'Active' : 'Disabled'}
              </Button>
            </div>
          </div>
          <div className='h-34 border border-gray-200'></div>

          <div className='flex flex-col pr-8 py-9'>
            <div className='text-14'>Role</div>
            <div className='text-16 font-semibold'>{user?.type}</div>
          </div>
          <div className='h-34 border border-gray-200'></div>

          <div className='flex flex-col flex-1 w-20 pr-8 py-9'>
            <div className='text-14'>Permissions</div>
            <div className='flex flex-wrap'>
              {user?.permissions.map((permission) => (
                <span className='text-10 p-1 mr-1 mb-1 font-semibold rounded-md border border-green-500'>
                  {permission}
                </span>
              ))}
            </div>
          </div>
          <div className='flex flex-1  pr-8 py-9 justify-end items-center gap-2'>
            <Button
              onClick={() => setOpenStatusModal(true)}
              className='bg-white border border-[#931414] text-[#A72828]'>
              {actionType}
            </Button>
            <Button
              onClick={() => setOpenRoleModal(true)}
              className='bg-white border border-black text-black'>
              Set roles
            </Button>
            <Button onClick={() => setOpenPermissionModal(true)} className='bg-black text-white'>
              Set permission
            </Button>
          </div>
        </div>

        <AuditTable userId={userId} />
      </div>
      <RolesModal
        open={openRoleModal}
        closeModal={closeRoleModal}
        user={user}
        setUser={setUserCallback}
      />

      <UpdateAdminStatusModal
        open={openStatusModal}
        closeModal={closeStatusModal}
        userId={user?.id}
        actionType={actionType}
        setUser={setUser}
      />

      <PermissionsModal
        open={openPermissionModal}
        closeModal={closePermissionModal}
        user={user}
        setUser={setUserCallback}
      />
    </MainWrapper>
  );
};

export default AdminUserDetails;
