import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getTransactions } from '../../../api';
import { GetTxFilter, IPaginationMeta, ITxCategory, TransactionDto } from '../../../types';
import Table from '../../Table';
import { transactionColums } from './config';
import TransactionFilter from './TransactionFilter';

interface IProps {
  enableFilter?: boolean;
  enableViewMore?: boolean;
  showPagination?: boolean;
  limit?: number;
  category?: ITxCategory;
}
const TransactionsTable: React.FC<IProps> = ({limit, enableFilter, enableViewMore, showPagination, category}) => {
  const [page, setPage] = useState<number>(1);
  const [transactions, setTransactions] = useState<TransactionDto[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery, setFilterQuery] = useState<GetTxFilter>({});

  const columns = useMemo(() => transactionColums(enableViewMore), [enableViewMore]);

  const filter: GetTxFilter = useMemo(
    () => ({ limit, page, category, ...filterQuery }),
    [filterQuery, limit, category, page]
  );

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getTransactions(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setTransactions(data);
    },
  });

  return (
    <div>
      {enableFilter &&
      <TransactionFilter
        updateQueryFilter={setFilterQuery}
      />
      }
      
      <Table columns={columns} data={transactions} loading={isFetching} {...(showPagination && { pagination: meta })} setTablePage={setPage} />
    </div>
  );
};

export default TransactionsTable;
