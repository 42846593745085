import dayjs from 'dayjs';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AccountUserDto, UserProfileDto } from '../../../types';
import { MultiStatusCell, RelativeTimeCell, StatusCell, VerifiedCell } from '../common';

const getProfileName = (profile: UserProfileDto): string => {
  return profile?.firstName && profile?.lastName
    ? `${profile.firstName} ${profile?.lastName}`
    : 'N/A';
};

const NameCell = ({ row }: Cell) => {
  const name = getProfileName((row.original as any).profile);
  return <Fragment>{name}</Fragment>;
};

export const userColumns = (showFull?: boolean, showViewMore?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'UID',
    accessor: 'uid',
  },
  {
    Header: 'Username',
    accessor: 'username',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: NameCell,
  },
  
  ...(showFull
    ? [
        {
          Header: 'Roles',
          accessor: 'roles',
          Cell: MultiStatusCell,
        },
        {
          Header: 'Email',
          accessor: 'email',
          Cell: ({ value }: Cell) => <Fragment>{value ?? 'N/A'}</Fragment>,
        },
        {
          Header: 'Phone',
          accessor: 'phone',
          Cell: ({ value }: Cell) => <Fragment>{value ?? 'N/A'}</Fragment>,
        },
      ]
    : []),
  {
    Header: 'Email Status',
    accessor: 'isEmailVerified',
    className: 'text-center',
    Cell: VerifiedCell,
  },
  {
    Header: 'Phone Status',
    accessor: 'isPhoneVerified',
    className: 'text-center',
    Cell: VerifiedCell,
  },
  {
    Header: 'Level',
    accessor: 'level',
    className: 'text-center',
    Cell: StatusCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    Cell: StatusCell,
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  ...(showViewMore ?
    [
    {
      Header: '',
      accessor: 'id',
      Cell: ({value}: Cell)=> { return <Link to={value}>view more</Link>},
    }
  ]: []
  )
  
  
];

export const transformForDownload = (data: AccountUserDto[], showFull?: boolean): any[] => {
  return data.map(
    (
      {
        uid,
        username,
        roles,
        email,
        phone,
        isEmailVerified,
        isPhoneVerified,
        level,
        status,
        createdAt,
        profile,
      }: AccountUserDto,
      index
    ) => {
      return {
        '#': index + 1,
        uid,
        username,
        name: getProfileName(profile),
        ...(showFull && {
          roles: roles.join(' | '),
          email,
          phone: phone ?? 'N/A',
        }),
        'account status': status,
        'account level': level,
        'email status': isEmailVerified ? 'verified' : 'unverified',
        'phone status': isPhoneVerified ? 'verified' : 'unverified',
        'kyc status': profile.idKycStatus,
        'registered at': dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      };
    }
  );
};
