import dayjs from 'dayjs';
import { Cell } from 'react-table';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toSentenceCase } from '../../../../helpers';

dayjs.extend(relativeTime);

const RelativeTimeCell = ({ value, column }: Cell) => (
  <div className={(column as any).className}>
    <span>{!value ? "N/A" : toSentenceCase(dayjs().to(dayjs(value)))}</span>
  </div>
);

export default RelativeTimeCell;
