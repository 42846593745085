import { Option, Select } from '@material-tailwind/react';
import React, { Fragment, SetStateAction } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { OrderType, TradeStatus } from '../../../../constants';
import { IOrderType, ITradeStatus } from '../../../../types';
import Input from '../../../Input';

export type IDisputeSelect = 'all' | 'open' | 'resolved';
export type IOrderTypeSelect = 'all' | IOrderType;
export type TradeStatusSelect = 'all' | ITradeStatus;
export type All = 'all';

interface IProps {
  dateRange: any;
  disputed: IDisputeSelect;
  orderType: IOrderTypeSelect;
  status: TradeStatusSelect;
  tradeOrderId:string;
  handleDateChange: (value: any) => void;
  setDisputed: React.Dispatch<SetStateAction<IDisputeSelect>>;
  setOrderType: React.Dispatch<SetStateAction<IOrderTypeSelect>>;
  setStatus: React.Dispatch<SetStateAction<TradeStatusSelect>>;
  setTradeOrderId: (value: any) => void;
}

const TradeFilterFields: React.FC<IProps> = (props) => {
  const labelClassName = 'text-10 text-gray-500 font-semibold mb-2';
  return (
    <Fragment>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Trade type</div>
        <Select
          variant='outlined'
          value={props.orderType}
          onChange={(value) => props.setOrderType(value as any)}>
          <Option value='all'>BUY/SELL</Option>
          {OrderType.map((val, i) => 
            <Option key={i} value={val}>{val}</Option>
          )}
        
        </Select>
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Trade status</div>
        <Select
          variant='outlined'
          value={props.status}
          onChange={(value) => props.setStatus(value as any)}>
          {['all', ...TradeStatus].map((val, i) => 
            <Option key={i} value={val}>{val === 'all' ? 'All Status' : val}</Option>
          )}
        </Select>
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Dispute Status</div>
        <Select
          variant='outlined'
          value={props.disputed}
          onChange={(value) => props.setDisputed(value as any)}>
          <Option value='all'>All Status</Option>
          <Option value='open'>open</Option>
          <Option value='resolved'>resolved</Option>
        </Select>
      </div>
      
      <div className='col-span-2'>
        <div className={labelClassName}>Transaction Date</div>
        <Datepicker
          useRange={false}
          value={props.dateRange}
          onChange={props.handleDateChange}
          containerClassName='border rounded-md text-13'
        />
      </div>

      <div className='col-span-2'>
        <div className={labelClassName}>Search Order Id</div>
        <Input
          value={props.tradeOrderId}
          onChange={(e) => props.setTradeOrderId(e.target.value as any)}
          className='border rounded-md text-13'
        />
      </div>
    </Fragment>
  );
};

export default TradeFilterFields;
