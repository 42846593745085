import { Fragment } from 'react';
import { Cell } from 'react-table';
import { AuditTrailDto } from '../../../types';
import { RelativeTimeCell } from '../common';

export const auditColumns = (showFull?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Email',
    accessor: 'actor.email',
  },
  {
    Header: 'module',
    accessor: 'module',
  },
  {
    Header: 'Activity',
    accessor: 'action',
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  {
    Header: 'Extra Detail',
    accessor: 'meta',
    Cell: ({row}: Cell<AuditTrailDto>) => `${JSON.stringify(row.original.meta)}`
    
  },

];

