import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { KycDto } from '../../../types';

export const kycColumns = (showFull?: boolean, canViewMore?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Name',
    accessor: 'lastName',
  },
  {
    Header: 'Review Reason',
    accessor: 'reviewReason',
  },
  {
    Header: 'Rejection Reason',
    accessor: 'rejectionReason',
  },
  {
    Header: 'Status',
    accessor: 'status',
    
  },
  ...(canViewMore?[{
    Header: '',
    accessor: 'userId',
    Cell: ({value}: Cell<KycDto>)=> { return <Link to={`${value}`}>view more</Link>},
  }]:[])
];

