import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getFullUsers, getUsers } from '../../../api';
import { DOWNLOAD_LIMIT } from '../../../constants';
import { exportDataToCsv } from '../../../helpers';
import { AccountUserDto, IPaginationMeta, IUserFilter, LeanAccountUserDto } from '../../../types';
import Table from '../../Table';
import { transformForDownload, userColumns } from './config';
import UserFilter from './UserFilter';

interface IProps {
  enableFilter?: boolean;
  enableViewMore?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
}

const UsersTable: React.FC<IProps> = ({ showFull, limit, enableFilter, enableViewMore, showPagination }) => {
  const [page, setPage] = useState<number>(1);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery, setFilterQuery] = useState<IUserFilter>({});
  const [users, setUsers] = useState<LeanAccountUserDto[] | AccountUserDto[]>([]);

  const columns = useMemo(() => userColumns(showFull, enableViewMore), [showFull, enableViewMore]);

  const filter: IUserFilter = useMemo(
    () => ({ limit, page, ...filterQuery }),
    [filterQuery, limit, page]
  );
  
  const queryFn = useMemo(() => (showFull ? getFullUsers : getUsers), [showFull]);

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => queryFn(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setUsers(data);
    },
  });

  const handleDownLoad = () => {
    setDownloading(true);
    const total = meta?.total!;
    const limit = DOWNLOAD_LIMIT;
    const downloadPages = Math.ceil(total / limit);
    Promise.all(
      Array.from(Array(downloadPages), (x, k) => k + 1).map(
        async (page) => await queryFn({ ...filter, page, limit })
      )
    )
      .then((dataArr) => {
        const downloadData = transformForDownload(
          dataArr.map(({ data }) => data).flat(1) as any[],
          showFull
        );
        exportDataToCsv(downloadData, 'bitbarter-users');
      })
      .catch(console.error)
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <div>
      {enableFilter && (
        <UserFilter
          updateQueryFilter={setFilterQuery}
          handelDownload={handleDownLoad}
          downloading={downloading}
        />
      )}
      <Table
        columns={columns}
        data={users}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default UsersTable;
