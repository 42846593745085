import { IPaginatedDto, IWalletFilter, UpdateWalletInput, WalletDto } from "../types";
import AxiosInstance from "./config";

export const getWallets = async (params?: IWalletFilter): Promise<IPaginatedDto<WalletDto>> => {
  try {
    const { data } = await AxiosInstance.get<IPaginatedDto<WalletDto>>(`/wallets`,  {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWalletById = async (id: string): Promise<WalletDto> => {
  try {
    const { data } = await AxiosInstance.get<WalletDto>(`/wallets/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateWalletLimit = async (id: string, payload: UpdateWalletInput): Promise<WalletDto> => {
  try {
    const { data } = await AxiosInstance.put<WalletDto>(`/wallets/${id}/limit`, {
      payload
    });
    return data;
  } catch (error) {
    throw error;
  }
};