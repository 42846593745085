import { Option, Select } from "@material-tailwind/react";
import { ArcElement, CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import Datepicker from "react-tailwindcss-datepicker";
import { getTransactionAggregate } from "../../../../api";
import { Currency } from "../../../../constants";
import { ICurrency, ITransactionAggregateFilter, TransactionAggregateDto } from "../../../../types";


interface IChart{
    labels: string[]
    data: number[],
    totalQty: number[]
    backgroundColor: string[]
    borderColor: string[]

}

interface ITransactionObj{
    totalQty: number;
    totalAmount: number

}

interface IDonutGraphData{
    bgColor?: string
    quantity?: number
    amount?: number
    label?: string
}
export type ICurrencyTypeSelect = 'All' | ICurrency;

const DonutGraphDetails = ({bgColor, quantity, label, amount}: IDonutGraphData)=>{
    return (
        <div className="flex-col gap-2 pb-4">
            <div className="flex w-full justify-between">
                <div className="flex w-2/3 justify-between items-center">
                    <div className="flex gap-1">
                        <span className="inline-flex items-center rounded-md w-7 py-0.5" style={{background: bgColor}}></span>
                        <div className="text-14">{label}</div>
                    </div>
                    <div className="flex">≈</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-16 font-bold">{quantity}</div>
                    <div className="flex text-14 font-medium">{amount}</div>
                </div>
                
            </div>
        </div>
    )
}

const DonutWalletGraphDetails = ({bgColor, quantity, label, amount}: IDonutGraphData)=>{
    return (
        <div className="flex-col gap-2 pb-4">
            <div className="flex w-full  gap-2">
                <div className="flex flex-1 justify-between items-center">
                    <div className="flex gap-1">
                        <span className="inline-flex items-center rounded-md w-7 py-0.5" style={{background: bgColor}}></span>
                        <div className="text-14">{label}</div>
                    </div>
                    
                </div>
                <div className="flex flex-1 justify-center items-center">≈</div>
                
                <div className="flex flex-1 justify-end items-center gap-4">
                    <div className="flex flex-1 justify-start items-start text-16 font-bold">{quantity}</div>
                    <div className="flex flex-1 text-14 font-medium">{amount}</div>
                </div>
                
            </div>
        </div>
    )
}
const TransactionMetric = ()=> { 
    const [transactionMetric, setTransactionMetrics] = useState<TransactionAggregateDto[]>([]);
    const [walletMetricData, setWalletMetricData] = useState<IChart>();
    const [bitcodeMetricData, setBitcodeMetricData] = useState<IChart>();
    const [walletDateRange, setwalletDateRange] = useState<any>({ endDate: null, startDate: null });
    const [bitcodeDateRange, setBitcodeDateRange] = useState<any>({ endDate: null, startDate: null });
    const [filter, setFilter] = useState<ITransactionAggregateFilter>();
    const [currencyType, setCurrencyType] = useState<ICurrencyTypeSelect|undefined>('All');

    const handleDateChange = (_date: any) => {
        setwalletDateRange(_date);
        setFilter({fromDate: walletDateRange?.startDate ? new Date(walletDateRange?.startDate).toISOString() : undefined,
            toDate: walletDateRange?.endDate ? new Date(walletDateRange?.endDate).toISOString() : undefined, category: 'WALLET'})
    };


    const handleBitcodeDateChange = (_date: any) => {
        setBitcodeDateRange(_date);
        setFilter({fromDate: bitcodeDateRange?.startDate ? new Date(bitcodeDateRange?.startDate).toISOString() : undefined,
            toDate: bitcodeDateRange?.endDate ? new Date(bitcodeDateRange?.endDate).toISOString() : undefined, category: 'BITCODE'})
    };


    useEffect(()=>{
        getTransactionAggregate(filter)
        .then(data => {
            setTransactionMetrics(data)
        }).catch(console.log)
    }, [filter])

    useEffect(()=>{
        if(currencyType === 'All'){
            const filteredTrans = transactionMetric?.filter(val => val.category === 'WALLET');

            setTransactionMetrics(filteredTrans)
        }else{
            const filteredTrans = transactionMetric?.filter(val => val.category === 'WALLET' && val.currency === currencyType );
            setTransactionMetrics(filteredTrans)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencyType])
   
    const getDonutMetricData = (backgroundColor:string[]): IChart =>{

        const groupedWalletData = new Map<string, ITransactionObj>();
        const walletTransations = transactionMetric.filter(metric => metric.category === 'WALLET');

        const walletDeposits = walletTransations.filter(metric =>metric.side === 'DEPOSIT')
        const totalWalletDeposits = walletDeposits.reduce((total, val) => total + Number(val.totalAmount), 0);
        groupedWalletData.set("Deposits", {totalQty: walletDeposits.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalWalletDeposits});

        const walletWithdrawals = walletTransations.filter(metric => metric.side === 'WITHDRAWAL');
        const totalWalletWithdrawals = walletWithdrawals.reduce((total, val) => total + Number(val.totalAmount), 0);
        groupedWalletData.set("Withdrawals", {totalQty: walletWithdrawals.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalWalletWithdrawals});


        const totalWalletWithdrawalFee = walletTransations.filter(metric => metric.side === 'WITHDRAWAL').reduce((total, val) => total + Number(val.paidFee), 0);
        groupedWalletData.set("Withdrawals fees", {totalQty: walletWithdrawals.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalWalletWithdrawalFee});

        
        const labels: string[] = [];
        const data: number[] = [];
        const qty: number[] = [];
        groupedWalletData?.forEach((value, key) => {
            labels.push(key);
            data.push(value.totalAmount)
            qty.push(value.totalQty)
        })


        return {labels: labels,  backgroundColor, borderColor:backgroundColor, data: data, totalQty: qty}
    }

    const getBitCodeDonutMetricData = (backgroundColor:string[]): IChart =>{

        const groupedWalletData = new Map<string, ITransactionObj>();
        const bitcodeTransation = transactionMetric.filter(metric => metric.category === 'BITCODE');

        const voucherCompleted = bitcodeTransation.filter(metric =>metric.recipient !== null && metric.status === 'COMPLETED');
        const totalVoucherCompleted = voucherCompleted.reduce((total, val) => total + Number(val.totalAmount), 0);
         groupedWalletData.set("Bitcode voucher (Completed)", {totalQty: voucherCompleted.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalVoucherCompleted});

        const voucherIncompleted = bitcodeTransation.filter(metric =>metric.recipient !== null && metric.status !== 'COMPLETED');
        const totalVoucherIncompleted = voucherIncompleted.reduce((total, val) => total + Number(val.totalAmount), 0);
        groupedWalletData.set("Bitcode voucher (Uncompleted)", {totalQty: voucherIncompleted.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalVoucherIncompleted});



        
        const invoiceCompleted = bitcodeTransation.filter(metric =>metric.recipient === null && metric.status === 'COMPLETED');

        const totalInvoiceCompleted = invoiceCompleted.reduce((total, val) => total + Number(val.totalAmount), 0);
         groupedWalletData.set("Bitcode invoice (Completed)", {totalQty: invoiceCompleted.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalInvoiceCompleted});

        const invoiceUncompleted = bitcodeTransation.filter(metric =>metric.recipient === null && metric.status !== 'COMPLETED');
        const totalInvoiceUncompleted = invoiceUncompleted.reduce((total, val) => total + Number(val.totalAmount), 0);
         groupedWalletData.set("Bitcode invoice (Uncompleted)", {totalQty: invoiceUncompleted.reduce((total, val) => total + Number(val.totalTransactions), 0), totalAmount: totalInvoiceUncompleted});

        
        const labels: string[] = [];
        const data: number[] = [];
        const qty: number[] = [];
        groupedWalletData?.forEach((value, key) => {
            labels.push(key);
            data.push(value.totalAmount)
            qty.push(value.totalQty)
        })
        return {labels: labels,  backgroundColor, borderColor:backgroundColor, data: data, totalQty: qty}
    }

    useEffect(()=> {
        setWalletMetricData(getDonutMetricData(["#5D73E8", '#18C1CC','#CC0A96','#E6DD15']))
        setBitcodeMetricData(getBitCodeDonutMetricData(["#5D73E8", '#18C1CC','#CC0A96','#E6DD15']))
        // eslint-disable-next-line
    },[transactionMetric])

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
    )
    const optionsc = {
        plugins: {
            legend: {
            display: false,
            },
        },
    };

    const walletMetricConfig = {
        
        labels: walletMetricData?.labels, 
        datasets: [
            {
            label: '',
            data: walletMetricData?.data,
            backgroundColor: [
                '#5D73E8',
                '#18C1CC',
                '#CC0A96',
                '#E6DD15',
            ],
            borderColor: [
                '#5D73E8',
                '#18C1CC',
                '#CC0A96',
                '#E6DD15',   
            ],
            borderWidth: 1,
            },
        ],
    };

    const bitcodeMetricConfig = {
        
        labels: bitcodeMetricData?.labels, 
        datasets: [
            {
            label: '',
            data: bitcodeMetricData?.data,
            backgroundColor: [
                '#5D73E8',
                '#18C1CC',
                '#CC0A96',
                '#E6DD15',
            ],
            borderColor: [
                '#5D73E8',
                '#18C1CC',
                '#CC0A96',
                '#E6DD15',   
            ],
            borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
            },
            legend: {
                display: false, // Set display to false to remove the label
            },
        },
        scales: {
            y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            },
            y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
            },
        },
    };
    const labels = ['12:00am', '1:00am', '2:00am', '3:00am', '4:00am', '5:00am', '6:00am'];

    const data = {
        labels,
        datasets: [
            {
                data: [10, 54, 88, 80, 2, 32, 71],
                borderColor: '#CF5050',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                data: [73, 99, 200, 43, 16, 30, 100],
                borderColor: '#52B96F',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            },
            {
                data: [73, 99, 20, 43, 16, 30, 100],
                borderColor: '#52B96F',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y2',
            },
            {
                data: [72, 99, 20, 40, 16, 30, 100],
                borderColor: '#962720',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y3',
            },
        ],
    };

    const walletGraphDetail = walletMetricConfig.labels?.map((label, i)=>{
        return (
            <>
                <DonutWalletGraphDetails key={i} label={label} amount={walletMetricData?.data[i]} quantity={walletMetricData?.totalQty[i]} bgColor={walletMetricData?.backgroundColor[i]}/>
            </>
            
        );
    })

    const bitcodeGraphDetail = bitcodeMetricConfig.labels?.map((label, i)=>{
        return (
            <>
                <DonutGraphDetails key={i} label={label} amount={bitcodeMetricData?.data[i]} quantity={bitcodeMetricData?.totalQty[i]} bgColor={walletMetricData?.backgroundColor[i]} />
            </>
            
        );
    })

    return (
        <div className="flex gap-1">
            <div className="flex-col w-1/2 shadow-md rounded">
                <div className="flex px-5 py-3 p-2 justify-between items-center shadow gap-2">
                    <div className="flex flex-1 text-14 font-medium">Wallet transactions value</div>
                    <div className="flex">
                        <Datepicker
                            useRange={false}
                            containerClassName='border rounded-md text-13'
                            value={walletDateRange}
                            onChange={handleDateChange}
                        />
                    </div>
                    
                </div>
                <div className="flex flex-col p-4">
                    <Line options={options} data={data} />
                    <div className="flex items-center justify-center py-11">
                        <div className="h-40 w-40">
                            <Doughnut options={optionsc} data={walletMetricConfig}/>
                        </div>
                    </div>
                    <div className="flex flex-col px-9">
                        <div className="flex justify-between">
                            <div className="text-14 font-semibold mb-3">Total wallet transactions: <span className="text-16 font-bold pl-2">{transactionMetric.filter((val)=> val.category === 'WALLET').reduce((total, val) => total + Number(val.totalTransactions), 0)}</span></div>
                            <div className="flex">
                                <Select
                                    value={currencyType}
                                    variant='outlined'
                                    onChange={(value) => setCurrencyType(value as any)}>
                                    {['All', ...Currency].map((val, i) => {
                                        return <Option key={i} value={val}>{val}</Option>
                                    })}
                                </Select>
                            </div>
                            
                        </div>
                        <div className="flex justify-end mt-3">
                            <div className="flex flex-1"></div>
                            <div className="flex flex-1"></div>
                            <div className="flex flex-1 justify-between">
                                <div className="flex flex-1 justify-start items-start text-12 text-[#5D5D5D]">Count</div>
                                <div className="flex flex-1 justify-center text-12 text-[#5D5D5D]">Quantity</div>
                            </div>
                        </div>
                        
                        {walletGraphDetail}
                    </div>
                    
                </div>
                
            </div>
            <div className="flex-col w-1/2 shadow-md rounded">
                <div className="flex px-5 py-3 p-2 justify-between items-center shadow gap-2">
                    <div className="flex flex-1 text-14 font-medium">Completed Bitcode transaction value</div>
                    <div className="flex">
                        <Datepicker
                            useRange={false}
                            containerClassName='border rounded-md text-13'
                            value={bitcodeDateRange}
                            onChange={handleBitcodeDateChange}
                        />
                    </div>
                    
                </div>
                <div className="flex flex-col p-4">
                    <Line options={options} data={data} />
                    <div className="flex items-center justify-center py-11">
                        <div className="h-40 w-40">
                            <Doughnut options={optionsc} data={bitcodeMetricConfig}/>
                        </div>
                    </div>
                    
                    <div className="px-9">
                        <div className="text-14 font-semibold mb-3">Total bitcode transactions: <span className="text-16 font-bold pl-2">{transactionMetric.filter((val)=> val.category === 'BITCODE').reduce((total, val) => total + Number(val.totalTransactions), 0)}</span></div>
                        {bitcodeGraphDetail}
                        <div className="flex-col gap-2 pb-4">
                        <div className="flex w-full">
                            <div className="flex w-2/3 justify-between items-center">
                                <div className="flex gap-1">
                                    <span className="inline-flex items-center rounded-md w-7 py-0.5" style={{background: '#962720'}}></span>
                                    <div className="text-14">{'Completed Bitcode fees'}</div>
                                </div>
                                <div className="flex">≈</div>
                                </div>
                                <div className="flex w-1/3 justify-end items-end">
                                    <div className="flex text-14 font-medium">{transactionMetric.filter(x => x.category === 'BITCODE').reduce((total , val)=> total + Number(val.paidFee), 0)}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )};
export default TransactionMetric;