import {
  Dialog, Typography
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { updateUserRoles } from "../../api";
import { tokenSchema } from "../../schema";
import { AccountUserDto, IUserRole } from "../../types";
import Button from "../Button";
import Input from "../Input";
  
  
  interface IForm {
    token: string;
    roles: IUserRole[];
  }
  
  
  interface IModal{
    closeModal : ()=> void,
    open: boolean,
    userId?: string
    isMerchant?: boolean;
    setUser: (user:AccountUserDto)=>void,
  }
  
  export default function UpgradeUserToMerchantModal({open, closeModal, userId, isMerchant, setUser}: IModal) {
      const [loading, setLoading] = useState<boolean>(false);
  
      const handleCloseModal = ()=>{
          closeModal();
      }
      const roleType = isMerchant ? 'USER': 'MERCHANT'
      const initialValues: IForm = {
        token: '',
        roles:[roleType]
      };
  
      const formik = useFormik({
        initialValues:initialValues,
        validationSchema: tokenSchema,
        onSubmit: (value)=>{
          setLoading(true)
          updateUserRoles(userId!, {...value, roles: [roleType]})
          .then((data)=>{
            setUser(data)
            closeModal()
            toast.success("Request was successful");
          })
          .catch((error) => {
              const errors =error?.response?.data;
              if(Array.isArray(errors)){
                  for (const key in errors) {
                    formik.setFieldError(key, errors[key]);
                  }
              }else{
                const message = error?.response?.data?.message || 'Verification failed. Please retry';
                  toast.error(message);
                  formik.setFieldError('token', message);
              }
          })
          .finally(()=>{
            setLoading(false)
          })
        }
    });
    return (
      <Fragment>
        
        <Dialog className="flex flex-col px-6 py-8 items-center gap-1" open={open} handler={handleCloseModal} >
          <div className="text-24 font-medium">{`${isMerchant? 'Revoke' : 'Upgrade to'} merchant`}</div>   
          <div className="text-14 font-medium mb-4">Enter OTP from you BitBarter/Google authenticator</div>
          <form className="flex flex-col w-full" onSubmit={formik.handleSubmit}>
            <div className="w-full">
              <Typography
                variant="small"
                color="#5D5D5D"
                className="mb-2 font-normal text-14"
              >
                Enter OTP from your Google authenticator
              </Typography>
              <Input  
                name="token"
                value={formik.values.token}
                error={formik.errors.token}
                onChange={formik.handleChange}/>
            </div>
            <div className="hidden">
                <Input readOnly hidden name="roles" value={formik.values.roles} />
            </div>
  
            <Button 
              className="bg-blue-gray-100 w-full mt-2"
              loading={loading}
              type='submit'
              disabled={!formik.dirty || !formik.isValid}>Confirm</Button>
          </form>
        </Dialog>
      </Fragment>
    );
  }