import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getAuditTrail } from '../../../api';
import { AuditTrailDto, IAuditFilter, IPaginationMeta } from '../../../types';
import Table from '../../Table';
import AuditFilter from './AuditFilter';
import { auditColumns } from './config';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
  userId?: string;
}

const AuditTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination, userId }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [auditTrail, setAuditTrail] = useState<AuditTrailDto[]>([]);
  const [filterQuery, setFilterQuery] = useState<IAuditFilter>({});

  const columns = useMemo(() => auditColumns(showFull), [showFull]);
  const filter: IAuditFilter = useMemo(
    () => ({ limit, page, userId, ...filterQuery }),
    [filterQuery, limit, page, userId]
  );
  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getAuditTrail(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setAuditTrail(data);
    },
  });


  return (
    <div>
      {enableFilter && (
        <AuditFilter
          updateQueryFilter={setFilterQuery}
        />
      )}
      <Table
        columns={columns}
        data={auditTrail}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default AuditTable;
