import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { VscArrowRight, VscChevronLeft, VscFile } from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getTradeDisputeById, resolveTradeDispute } from "../../../../api";
import Button from "../../../../components/Button";
import MainWrapper from "../../../../components/MainWrapper";
import { DisputeActions } from "../../../../constants";
import { toSentenceCase } from "../../../../helpers";
import { TradeDisputeDto } from "../../../../types";

const TradeDisputeDetails = () => {
  const { tradeDisputeId } = useParams();
  const [tradeDispute, setTradeDispute] = useState<TradeDisputeDto>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(()=>{
    if(tradeDisputeId){
      getTradeDisputeById(tradeDisputeId)
      .then(data => setTradeDispute(data))
      .catch(console.log)
    }
  }, [tradeDisputeId])

  
  const formik = useFormik({
      initialValues: {
          id:"",
          comment: "",
          action: "",
      },
      onSubmit: (value)=>{
          setLoading(true)
          resolveTradeDispute(value)
          .then((data)=>{
              toast.success(data?.message)
          })
          .catch((error) => {
              toast.error(error?.response?.data?.message || 'Request failed. Please retry!');
          })
          .finally(()=>{setLoading(false)})
      }
  });


  return (
    <MainWrapper title="Manage Dispute">

      <div className="flex flex-col pb-6">
        <Link to={'/marketplace/disputes'}><VscChevronLeft className="inline pb-1"/> Back</Link>
        <div className="flex justify-between gap-2">
          <div className="flex flex-col shadow rounded-lg flex-1">
            <div className="flex gap-6 py-4 px-8 rounded-t-lg bg-blue-gray-50">
              <div className="text-20 font-medium">Trade Details</div>
              <div className="text-16 font-semibold">{tradeDispute?.trade?.tradeType === "OFFRAMP" ? "Buy" : "Sell"} {tradeDispute?.trade?.crypto} with {tradeDispute?.trade?.fiat}</div>
            </div>
            <div className="flex flex-col px-7 py-4 bd-[#F5FFF8]">
              <div className="flex gap-3">
                <div className="flex justify-center tems-center rounded-full p-2 text-12 bg-[#BDFFCF]" >Buyer</div>
                
                  
                
                {tradeDispute?.trade?.maker?.roles?.includes("MERCHANT") === true && <div className="flex justify-center items-center rounded-full px-2 text-12 bg-[#E9E9E9]">Merchant</div>
                }
                
              </div>
              <div className="pt-5 text-16 font-semibold">{tradeDispute?.trade?.maker?.profile?.fullName}</div>
              <div className="pt-1 text-14 font-medium">@{tradeDispute?.trade?.maker?.username}</div>
              <div className="pt-2 text-14">1,234 Completion | 88% Completion</div>
              <div className="flex">
                <div>5.0</div>
              </div>
            </div>
            <div className="flex flex-col px-7 py-4 bg-[#FFF0F0]">
              <div className="flex gap-3">
                <div className="flex justify-center tems-center rounded-full p-2 text-12 bg-[#FFC8C8]">Seller</div>
                {tradeDispute?.trade?.taker?.roles?.includes("MERCHANT") === true && <div className="flex justify-center items-center rounded-full px-2 text-12 bg-[#E9E9E9]">Merchant</div>
                }
              </div>
              <div className="pt-5 text-16 font-semibold">{tradeDispute?.trade?.taker?.profile?.fullName}</div>
              <div className="pt-1 text-14 font-medium">@{tradeDispute?.trade?.taker?.username}</div>
              <div className="pt-2 text-14">1,234 Completion | 88% Completion</div>
              <div className="flex">
                <div>5.0</div>
              </div>
            </div>
            <div className="flex flex-col px-7 py-5">
              <div className="flex gap-2">
                <div className="text-14">Amount</div>
                <div className="text-18 font-semibold">{tradeDispute?.trade?.fiat}{Number(tradeDispute?.trade?.amount).toFixed(2)}</div>
              </div>
              <div className="flex gap-2 pt-4">
                <div className="text-14">Trade Price</div>
                <div className="text-16 font-medium">{tradeDispute?.trade?.fiat}{Number(tradeDispute?.trade?.quantityToTransfer).toFixed(2)}</div>
              </div>
              <div className="flex gap-2 pt-4">
                <div className="text-14">Quantity</div>
                <div className="text-14 font-medium">{tradeDispute?.trade?.quantity} {tradeDispute?.trade?.crypto}</div>
              </div>
              <div className="flex gap-2 pt-4">
                <div className="text-14">Created</div>
                <div className="text-14 font-semibold">{toSentenceCase(dayjs().to(dayjs(tradeDispute?.createdAt)))}</div>
              </div>
              <div className="flex gap-2 pt-4">
                <div className="text-14">Payment Method</div>
                
                <div className="flex flex-col">
                  <div className="text-14 font-medium">{tradeDispute?.trade?.sellerPaymentMethod.accountTag || "Bank Transfer"}</div>
                  <div className="text-12">({tradeDispute?.trade?.sellerPaymentMethod.bankName} {tradeDispute?.trade?.sellerPaymentMethod.accountNumber})</div>
                </div>
                
              </div>
              <div className="flex gap-2 pt-4">
                <div className="text-14">Order ID</div>
                <div className="text-14 font-semibold">{tradeDispute?.trade?.tradeOrderId}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col shadow rounded-lg flex-1">
            <div className="flex  py-4 pl-12 rounded-t-lg bg-blue-gray-50">
              <div className="text-20 font-medium">Open Dispute detail</div>
            </div>
            <div className="flex flex-col px-4 pt-8">
              <div className="flex flex-col">
                <div className="text-14 mb-1">Reason for Dispute</div>
                <div className="px-3 py-3 text-14 font-medium bg-gray-100">{tradeDispute?.description}</div>
              </div>

              <div className="flex flex-col mt-9">
                <div className="text-14 mb-1">Dispute description</div>
                <div className="px-5 py-8 text-14 font-medium bg-gray-100">{tradeDispute?.description}</div>
              </div>

              <div className="flex flex-col mt-10">
                <div className="text-14 mb-1">Files</div>
                <div className="flex gap-2">
                  <VscFile className="w-14 h-20"/>
                  <VscFile className="w-14 h-20"/>
                  <VscFile className="w-14 h-20"/>
                  
                </div>
              </div>
              <div className="mt-20 px-9 py-3 w-52 rounded-lg bg-deep-orange-100">
                Go to freshdesk <VscArrowRight className="inline-flex"/>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 shadow rounded-lg border-amber-200 border-2">
            <div className="flex  py-4 pl-12 rounded-t-lg bg-amber-100">
              <div className="text-20 font-medium text-[#CB7916]">Support log</div>
            </div>
            <div className="flex flex-col px-4 pt-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col">
                  <div className="text-14 mb-1">Support comment</div>
                  <div className="w-4/5">
                    <textarea name="comment" className="form-textarea" onChange={formik.handleChange}>

                    </textarea>
                  </div>
                </div>

                <div className="flex flex-col mt-12">
                  <div className="text-14 ">Choose action</div>
                  <div className="mb-2">
                    <select name="action" className="w-full p-3 rounded-lg" onChange={formik.handleChange}>
                      <option value=""> -- </option>
                      {DisputeActions.map((value, index) => 
                        <option key={index} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex mt-36 justify-center items-center">
                  <Button type="submit" className="w-4/5 bg-amber-100" loading={loading}>Confirm</Button>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>

    </MainWrapper>
  )
}
  
  export default TradeDisputeDetails;