import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from '../../../../components/AuthWrapper';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { appRoutes } from '../../../../config';
import { useAuth } from '../../../../hooks';
import { authTokenSchema } from '../../../../schema';

interface IForm {
  token: string;
}

const Verify2fa = () => {
  const navigate = useNavigate();
  const { verifyLogin, loggedIn, loading } = useAuth();
  const initialValues: IForm = {
    token: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: authTokenSchema,
    onSubmit: (values) => {
      verifyLogin(values.token).catch((error) => {
        const message = error?.response?.data?.message || 'Verification failed. Please retry';
        formik.setFieldError('token', message);
      });
    },
  });

  useEffect(() => {
    if (loggedIn) {
      navigate(appRoutes.dashboard, { replace: true });
    }
  }, [loggedIn, navigate]);

  return (
    <AuthWrapper title='Confirm Login' siteTitle='Verify Login | BitBarter Technology'>
      <form className='flex flex-col gap-6 max-w-sm' onSubmit={formik.handleSubmit}>
        <Input
          label='Enter BitBarter/Goodle Auth Token'
          name='token'
          value={formik.values.token}
          onChange={formik.handleChange}
          error={formik.errors.token}
        />

        <Button
          size='sm'
          type='submit'
          loading={loading}
          disabled={!formik.dirty || !formik.isValid}>
          Confirm
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default Verify2fa;
