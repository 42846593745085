
import { Fragment } from 'react';
import { Cell } from 'react-table';
import {  RelativeTimeCell} from '../common';

export const adColumns = (showFull?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Fiat',
    accessor: 'fiat',
  },
  {
    Header: 'crypto',
    accessor: 'crypto',
  },
  {
    Header: 'orderType',
    accessor: 'orderType',
  },
  {
    Header: 'orderBookId',
    accessor: 'orderBookId',
  },
  {
    Header: 'advertisedRate',
    accessor: 'advertisedRate',
  },
  {
    Header: 'quantity',
    accessor: 'quantity',
  },
  {
    Header: 'paymentWindow',
    accessor: 'paymentWindow',
  },
  {
    Header: 'minQuantity',
    accessor: 'minQuantity',
  },
  {
    Header: 'maxQuantity',
    accessor: 'maxQuantity',
  },
  {
    Header: 'minOrderAmount',
    accessor: 'minOrderAmount',
  },
  {
    Header: 'maxOrderAmount',
    accessor: 'maxOrderAmount',
  },
  {
    Header: 'availableQuantity',
    accessor: 'availableQuantity',
  },
  {
    Header: 'status',
    accessor: 'status',
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  
];

