import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getTradeDisputes } from '../../../api';
import { useAdminPermission } from '../../../hooks';
import { IPaginationMeta, ITradeDisputeFilter, TradeDisputeDto } from '../../../types';
import Table from '../../Table';
import { tradeDisputeColumns } from './config';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
  recievedResponse?: string;
}

const TradeDisputeTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination, recievedResponse }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery] = useState<ITradeDisputeFilter>({});
  const [tradeDisputes, setTradeDisputes] = useState<TradeDisputeDto[]>([]);

  const { hasRequiredPermissions } = useAdminPermission();
  const canViewMore= hasRequiredPermissions([], 'SUPPORT');

  const columns = useMemo(() => tradeDisputeColumns(showFull, canViewMore), [showFull, canViewMore]);

  const filter: ITradeDisputeFilter = useMemo(
    () => ({ limit, page, recievedResponse, ...filterQuery }),
    [filterQuery, limit, page, recievedResponse]
  );
  

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getTradeDisputes(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setTradeDisputes(data);
    },
  });

  return (
    <div>
      <Table
        columns={columns}
        data={tradeDisputes}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default TradeDisputeTable;
