import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getWalletById } from "../../../api";
import Button from "../../../components/Button";
import MainWrapper from "../../../components/MainWrapper";
import { UpdateWalletLimitModal } from "../../../components/Modals";
import Pill from "../../../components/Pill";
import { toSentenceCase } from "../../../helpers";
import { WalletDto } from "../../../types";

const WalletDetails = () => {
  const { walletId } = useParams();
  const [wallet, setWallet] = useState<WalletDto|null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(()=>{
    if(walletId){
      getWalletById(walletId)
      .then(data => setWallet(data))
      .catch(console.log)
    }
  }, [walletId])

  const closeModal = () => setOpenModal(false);

  return (
    <MainWrapper title="Manager Wallets">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Wallet Details</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Total Balance</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.totalBalance}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Total Available Balance</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.totalAvailableBalance}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Total Locked Balance</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.totalLockedBalance}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Spent Today</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.spentToday}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Daily Limit</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.dailyLimit}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Daily Trade Limit</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.dailyTradeLimit}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Amount Traded Today</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.amountTradedToday}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Display Currency</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{wallet?.displayCurrency}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">last Debit At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span>{toSentenceCase(dayjs().to(dayjs(wallet?.lastDebitAt)))}</span>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created Date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span>{toSentenceCase(dayjs().to(dayjs(wallet?.createdAt)))}</span>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"> 
                <Pill variant={wallet?.status === 'ACTIVE' ? 'success' : 'danger'} text={!!wallet?.status ? 'Active' : 'Inactive'} />
              </dd>
            </div>
            
            <div className="p-2 flex">
              <div className="w-1/2"></div>
                <div className="w-1/2 flex justify-end">
                  <Button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => setOpenModal(true)}>
                    Update Limit
                  </Button>
                </div>
            </div>
          </dl>
        </div>
      </div>

      <UpdateWalletLimitModal open={openModal} closeModal={closeModal} walletId={wallet?.id}/>
    </MainWrapper>
  )
}
  
  export default WalletDetails;