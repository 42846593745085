import MainWrapper from '../../components/MainWrapper';
import { AdminUsersList } from '../../components/pageComponents/adminUser';
import AdminUserMetricCards from '../../components/pageComponents/adminUser/adminUserList/Metrics';

const AdminUser = () => {
  return (
    <MainWrapper title='Admin Users'>
      <AdminUserMetricCards />
      <AdminUsersList />
    </MainWrapper>
  );
};

export default AdminUser;
