import React, { SetStateAction, useState } from 'react';
import cn from 'classnames';
import { AppModulesTable, AppServiceTable} from '../../../tables';

type ITabTitle = 'AppService' | 'AppModule';

type ITab = React.FC<{
  title: ITabTitle;
  activeTab: ITabTitle;
  setActiveTab: React.Dispatch<SetStateAction<ITabTitle>>;
}>;

const Tab: ITab = ({ title, activeTab, setActiveTab }) => (
  <span
    className={cn([
      'py-5 px-8 border-b-2 hover:border-blue-500',
      {
        'border-transparent': title !== activeTab,
        'border-blue-500': title === activeTab,
      },
    ])}
    onClick={() => setActiveTab(title)}>
    {title}
  </span>
);

const ServicesTable = () => {
  const [activeTable, setActiveTable] = useState<ITabTitle>('AppService');

  return (
    <div className='mt-8'>
      <div className='bg-white px-5 md:px-8 mb-5 rounded-md py-0 flex justify-start align-center shadow'>
        <Tab title='AppService' activeTab={activeTable} setActiveTab={setActiveTable} />
        <Tab title='AppModule' activeTab={activeTable} setActiveTab={setActiveTable} />
      </div>
      <div>
        {activeTable === 'AppService' && <AppServiceTable />}
        {activeTable === 'AppModule' && <AppModulesTable />}
      </div>
    </div>
  );
};

export default ServicesTable;
