import MainWrapper from "../../../components/MainWrapper";
import { PaymentChannelList } from "../../../components/pageComponents";

const PaymentChannel = () => {
    return (
      <MainWrapper title="Settings/Payment channel">
        <PaymentChannelList />
      </MainWrapper>
    )
  }
  
export default PaymentChannel;