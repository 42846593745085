import MainWrapper from '../../components/MainWrapper';
import { UserMetric, UsersList } from '../../components/pageComponents';
import { useAdminPermission } from '../../hooks';

const User = () => {
  const { hasRequiredPermissions } = useAdminPermission();
  const showMetrics = hasRequiredPermissions([], 'ADMIN');
  return (
    <MainWrapper title='Manage Users'>
      {showMetrics &&
        <UserMetric />
      }
      <UsersList />
    </MainWrapper>
  );
};

export default User;
