import cn from 'classnames';
import { SetStateAction, useState } from "react";
import { useAdminPermission } from "../../../../../hooks";
import { TradeDisputeTable } from "../../../../tables";
type ITabTitle = 'All' | 'Open' | 'Processing' | 'Resolved';

type ITab = React.FC<{
  title: ITabTitle;
  activeTab: ITabTitle;
  setActiveTab: React.Dispatch<SetStateAction<ITabTitle>>;
}>;

const Tab: ITab = ({ title, activeTab, setActiveTab }) => (
  <span
    className={cn([
      'py-5 px-8 border-b-2 hover:border-blue-500',
      {
        'border-transparent': title !== activeTab,
        'border-blue-500': title === activeTab,
      },
    ])}
    onClick={() => setActiveTab(title)}>
    {title}
  </span>
);
const TradeDisputeList = () => {
    const { hasRequiredPermissions } = useAdminPermission();
    const showFull = hasRequiredPermissions(['MANAGE_USERS'], 'ADMIN');
    const [activeTable, setActiveTable] = useState<ITabTitle>('All');
  
    return (
      <div className='mt-8'>
        <div className='bg-white px-5 md:px-8 mb-5 rounded-md py-0 flex justify-start align-center shadow'>
          <Tab title='All' activeTab={activeTable} setActiveTab={setActiveTable} />
          <Tab title='Open' activeTab={activeTable} setActiveTab={setActiveTable} />
          <Tab title='Processing' activeTab={activeTable} setActiveTab={setActiveTable} />
          <Tab title='Resolved' activeTab={activeTable} setActiveTab={setActiveTable} />
        </div>
        <div>
          {activeTable === 'All' && <TradeDisputeTable limit={20} showFull={showFull} enableFilter showPagination />}
          {activeTable === 'Open' && <TradeDisputeTable limit={20} showFull={showFull} recievedResponse="OPEN" enableFilter showPagination />}
          {activeTable === 'Processing' && <TradeDisputeTable limit={20} showFull={showFull} recievedResponse="PROCESSING" enableFilter showPagination />}
          {activeTable === 'Resolved' && <TradeDisputeTable limit={20} showFull={showFull} recievedResponse="CLOSED" enableFilter showPagination />}
        </div>
      </div>
      
    );
  };
  
export default TradeDisputeList;