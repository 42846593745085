import { createContext, PropsWithChildren, useContext } from "react";

import { io as SocketIO, Socket } from 'socket.io-client';
import { websocketBaseUrl } from '../config';
import { MarketTrendData } from "../types/common/marketTrendData";
import { PriceTickerData } from "../types/common/priceTickerData";

type SocketType = 'DATA' | 'TRADE'
type DATA_CHANNEL_GROUP = 'MARKET_UPDATE' | 'MARKET_TREND'

interface IProviderPros {}

interface IWebSocketContext {
    dataWs: {
      subscribeForData: (type: DATA_CHANNEL_GROUP) => Socket
      socket: Socket | undefined
    }
    tradeWs: {
      connectTradeSocket: () => void
      socket: Socket | undefined
    }
    dataChannel: {
      listenToRate: (
        pair: string,
        callback: (data: PriceTickerData) => void
      ) => void
      listenToAllRates: (
        callback: (data: PriceTickerData) => void
      ) => void
      listenToMarketTrends: (
        callback: (data: MarketTrendData[]) => void
      ) => void
    }
  }

  type IWebSocketProvider = React.FC<PropsWithChildren<IProviderPros>>

export const WebSocketContext = createContext<IWebSocketContext | null>(null)

export const WebSocketProvider: IWebSocketProvider = ({ children }) => {
  const dataSubs: Map<DATA_CHANNEL_GROUP, Socket> = new Map()
  const loadedSockets: Map<SocketType, Socket> = new Map()

  const getOrSetSocket = (
    type: SocketType,
    path: string,
    options: Record<string, unknown> = {}
  ): Socket => {
    if (!loadedSockets.has(type)) {
      loadedSockets.set(
        type,
        SocketIO(`${websocketBaseUrl}${path}`, options)
      )
    }
    return loadedSockets.get(type)!
  }

  const getDataChannelSocketOrJoin = (channel: DATA_CHANNEL_GROUP): Socket => {
    let channelSocket = dataSubs.get(channel)
    if (!channelSocket) {
      channelSocket = subscribeForData(channel)
    }
    return channelSocket
  }

  const connectTradeSocket = () => {
    const tradeSocket = getOrSetSocket('TRADE', 'trades', {
      withCredentials: true,
    })

    tradeSocket.on('connect', () => {
      console.log('Connected To Trade NameSpace')
    })

    tradeSocket.on('error', (err) => {
      // console.error(err);
    })
  }

  const subscribeForData = (type: DATA_CHANNEL_GROUP) => {
    const dataSocket = getOrSetSocket('DATA', 'data', {
      transports: ['websocket'],
    })
    dataSocket.on('connect', () => {
      dataSocket.emit('sub', { type })
      dataSubs.set(type, dataSocket)
    })
    return dataSocket
  }

  const listenToRate = (
    pair: string,
    callback: (data: PriceTickerData) => void
  ): void => {
    const _socket = getDataChannelSocketOrJoin('MARKET_UPDATE')
    _socket.on(pair, callback)
  }
  const listenToAllRates = (
    callback: (data: PriceTickerData) => void
  ): void => {
    const _socket = getDataChannelSocketOrJoin('MARKET_UPDATE')
    _socket.on('MARKET_UPDATE', callback)
  }

  const listenToMarketTrends = (
    callback: (data: MarketTrendData[]) => void
  ): void => {
    const _socket = getDataChannelSocketOrJoin('MARKET_TREND')
    _socket.on('MARKET_TREND', callback)
  }

  const dataWs = {
    socket: loadedSockets.get('DATA'),
    subscribeForData,
  }

  const tradeWs = {
    socket: loadedSockets.get('TRADE'),
    connectTradeSocket,
  }

  const dataChannel = {
    listenToRate,
    listenToAllRates,
    listenToMarketTrends,
  }

  const value = {
    dataWs,
    tradeWs,
    dataChannel,
  }

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebsocket = (): IWebSocketContext => {
  const context = useContext(WebSocketContext)
  if (!context) {
    throw new Error(`'useWebsocket' requires WebSocketProvider`)
  }
  return context
}