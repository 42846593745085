import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import {VscChevronLeft} from "react-icons/vsc";
import {Link, useParams} from "react-router-dom";
import {getIdentityByUserId, getUserById} from "../../../api";
import Button from "../../../components/Button";
import MainWrapper from "../../../components/MainWrapper";
import {UpdateKycStatusModal} from "../../../components/Modals";
import {buildVerificationLink, toSentenceCase} from "../../../helpers";
import {useAdminPermission} from "../../../hooks";
import {AccountUserDto, IActionType, KycPreviewDto} from "../../../types";
import {LoadingCard} from "../../../components";
import Pill from "../../../components/Pill";
import {kycStatusProps} from "../../../constants";

const KycDetails = () => {

    const {id} = useParams();
    const [loading, setLoading] = useState<boolean>(false)
    const [user, setUser] = useState<AccountUserDto>()
    const [userKyc, setUserKyc] = useState<KycPreviewDto>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [actionType, setActionType] = useState<IActionType>('Reject');

    const closeModal = () => setOpenModal(false);
    const {hasRequiredPermissions} = useAdminPermission();
    const canReview = hasRequiredPermissions(['MANAGE_USER_KYC'], 'SUPPORT');

    const handleOpenModal = (type: IActionType) => {
        setActionType(type);
        setOpenModal(true);
    }

    useEffect(() => {
        if (id && canReview) {
            setLoading(true);
            Promise.all([getUserById(id), getIdentityByUserId(id)])
                .then(([userData, userKycData]) => {
                    setUser(userData);
                    setUserKyc(userKycData);
                })
                .catch((error) => {
                    // TODO: Handle error
                }).finally(() => {
                setLoading(false);
            });
        }
    }, [id, canReview])

    const statusProps = useMemo(() => userKyc?.data?.status ? kycStatusProps[userKyc?.data?.status] : undefined, [
        userKyc?.data?.status
    ])

    return (
        <MainWrapper title="Manual KYC Verification">
            {loading ? <LoadingCard/> : <>
                <div className="flex flex-col">
                    <Link to={"/kyc"} className="flex items-center gap-1">
                        <VscChevronLeft/>
                        <div className="rounded">Back to kyc manager</div>
                    </Link>
                    <div className="flex flex-col">
                        <p className="text-20 font-bold pt-9">KYC Review Required</p>
                        <p className="text-16 font-medium pt-9 pb-2">User information </p>
                    </div>
                    <div className="flex flex-col rounded shadow-md mt-5 mb-9">
                        <div className="flex p-6 bg-gray-100 gap-8 w-full">
                            <div className="flex gap-2 pr-5">
                                <div
                                    className="flex rounded-full h-20 w-20 text-white bg-black items-center justify-center text-16 font-semibold ">NA
                                </div>
                                <div className="flex flex-col justify-center">
                                    {/* TODO: Have a function that formats full name*/}
                                    <p className="text-14 font-normal pb-1">{userKyc?.data?.lastName || ''} {userKyc?.data?.firstName || ''} {userKyc?.data?.otherNames || ''}</p>
                                    <p className="text-14 font-normal pb-1">UID: <span>{user?.uid || 'N/A'}</span></p>
                                    <p className="text-14 font-normal pb-1">Email: <span>{user?.email || 'N/A'}</span>
                                    </p>
                                    {statusProps && (
                                        <Pill variant={statusProps.pillVariant} text={statusProps.text}/>
                                    )}
                                </div>
                            </div>

                            <div className="h-20 border border-gray-400"></div>

                            <div className="flex flex-col pr-24 justify-center">
                                <p className="text-14 font-normal">Username</p>
                                <p className="text-14 font-semibold pb-1">{user?.username || 'N/A'}</p>
                            </div>

                            <div className="h-20 border border-gray-400"></div>

                            <div className="flex flex-col pr-12 justify-center">
                                <p className="text-14 font-normal">Gender</p>
                                <div className="flex pb-2">
                                    <div className="text-14">{userKyc?.data?.gender || 'N/A'}</div>
                                </div>
                            </div>

                            <div className="h-20 border border-gray-400"></div>

                            <div className="flex flex-col justify-center">
                                <p className="text-14 font-normal">Date of birth</p>
                                <div className="flex pb-2">
                                    <div
                                        className="text-14">{userKyc?.data.status ? toSentenceCase(dayjs().to(dayjs(userKyc?.data.status))) : "N/A"}
                                    </div>
                                </div>
                            </div>

                            <div className="h-20 border border-gray-400"></div>

                            <div className="flex flex-col justify-center">
                                <p className="text-14 font-normal">Country</p>
                                <div className="flex pb-2">
                                    <div className="text-14">{userKyc?.data?.country || 'N/A'}</div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="flex m-4 w-full gap-16">
                        <div className="flex flex-col w-2/5 ">
                            {userKyc?.data?.reviewReason && (
                                <>
                                    <p className="mb-4 text-16 font-medium">Reason for review</p>
                                    <div className="flex justify-items-start p-7 bg-[#CBCBCB] rounded-lg border">
                                        {userKyc?.data?.reviewReason}
                                    </div>
                                </>
                            )}
                            {canReview && userKyc?.data?.status === 'REVIEW_REQUIRED' &&
                                <div className="flex mt-16 justify-between">
                                    <Button onClick={() => {
                                        handleOpenModal('Reject')
                                    }}
                                            className="text-14 font-medium rounded-lg bg-white text-black border border-[#757575]">Reject
                                        verification</Button>
                                    <Button onClick={() => {
                                        handleOpenModal('Approve')
                                    }}
                                            className="text-14 font-medium rounded-lg bg-white text-black border border-[#757575]">Approve
                                        verification</Button>
                                </div>
                            }
                            {canReview && userKyc && (
                                <div className="flex mt-16 justify-between">
                                    <Link className="text-14 font-medium rounded-lg bg-[#1F1F1F] text-white py-3 px-5" to={buildVerificationLink(userKyc)} target="_blank">View verification</Link>
                                </div>
                            )}

                        </div>
                        {userKyc?.data?.reviewReason && userKyc?.data?.reviewReason === 'DUPLICATE_RECORD' &&
                            <div className="flex flex-col w-3/5">
                                <p className="mb-4 text-16 font-medium">Other similar names</p>
                                <div className="flex flex-col bg-[#F7F7F7] shadow-md">
                                    <div className="flex w-full">
                                        <div className="py-3 px-4 border border-[#E9E9E9]  w-2/6">Similar names</div>
                                        <div className="py-3 px-4 border border-[#E9E9E9]  w-1/6">Country</div>
                                        <div className="py-3 px-4 border border-[#E9E9E9]  w-1/6">Gender</div>
                                        <div className="py-3 px-4 border border-[#E9E9E9] w-2/6">Email</div>
                                    </div>
                                    <>
                                        {
                                            userKyc?.duplicates?.forEach((val, i) => (
                                                <div key={i} className="flex">
                                                    <div
                                                        className="flex py-3 px-4 border border-[#E9E9E9]  w-2/6 gap-2 justify-items-center items-center">
                                                        <div
                                                            className="flex py-2 px-3 text-8 rounded-full text-white bg-black">{val.lastName[0]}{val.firstName[0]}</div>
                                                        <div
                                                            className="text-12 text-[#353535]">{val.lastName || ''} {val.firstName || ''} {val.otherNames || ''}</div>
                                                    </div>
                                                    <div
                                                        className="flex py-3 px-4 border border-[#E9E9E9]  w-1/6 text-12 text-[#353535] justify-items-center items-center">{val.country}</div>
                                                    <div
                                                        className="flex py-3 px-4 border border-[#E9E9E9]  w-1/6 text-12 text-[#353535] justify-items-center items-center">{val.gender.toString()}</div>
                                                    <div
                                                        className="flex py-3 px-4 border border-[#E9E9E9] w-2/6 text-12 text-[#353535] justify-items-center items-center">{val.email}</div>
                                                </div>
                                            ))

                                        }
                                    </>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <UpdateKycStatusModal closeModal={closeModal} open={(openModal)} userId={userKyc?.data.userId} actionType={actionType}/>
            </>}
        </MainWrapper>
    )
}

export default KycDetails;



