import React, { useMemo } from 'react';
import { useClock } from '../../../../hooks';
import { useAuthStore } from '../../../../store';

const GreetAdmin: React.FC<unknown> = () => {
  const { periodOfDay } = useClock();
  const { user } = useAuthStore();
  const greeting = useMemo(
    () => `Good ${periodOfDay}, ${user.firstName || ''}.`,
    [periodOfDay, user.firstName]
  );
  return (
    <section className='pb-2 mb-12 border-b'>
      <h1 className='title'>{greeting}</h1>
    </section>
  );
};

export default GreetAdmin;
