import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../middlewares";
import { Service } from "../pages";

const SettingRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthGuard />}>
          <Route index element={<Service />} />
        </Route>
        
      </Routes>
    );
  };
  
  export default SettingRoutes;