import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getWallets } from "../../../api";
import { IPaginationMeta, IWalletFilter, WalletDto } from "../../../types";
import Table from "../../Table";
import { walletColumns } from "./config";

interface IProps {
  enableFilter?: boolean;
  showPagination?: boolean;
  limit?: number;
}
const WalletTable: React.FC<IProps> = ({limit, enableFilter, showPagination }) => {
  const [page, setPage] = useState<number>(1);
    const [wallet, setWallets] = useState<WalletDto[]>([]);
    const [meta, setMeta] = useState<IPaginationMeta | undefined>();
    const [filterQuery] = useState<IWalletFilter>({});
    const columns = useMemo(() => walletColumns, []);
    
    const filter: IWalletFilter = useMemo(
      () => ({ limit, page, ...filterQuery }),
      [filterQuery, limit, page]
    );


    const { isFetching } = useQuery({
      queryKey: ['projects', filter],
      queryFn: () => getWallets(filter),
      onSuccess: ({ data, meta }) => {
        setMeta(meta);
        setWallets(data);
      },
    });

    return (
      <div>
        <Table columns={columns()} data={wallet} loading={isFetching} setTablePage={setPage} {...(showPagination && { pagination: meta })}/>
      </div>
    );
  };
  
  export default WalletTable;