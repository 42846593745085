import { Cell } from 'react-table';
import Pill from '../../../Pill';

const VerifiedCell = ({ column, value }: Cell) => (
  <div className={(column as any).className}>
    <Pill variant={value ? 'success' : 'danger'} text={!!value ? 'Verified' : 'Unverified'} />
  </div>
);

export default VerifiedCell;
