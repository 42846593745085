import React, { Fragment, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { appRoutes, authRoutes } from '../config';
import { useAdminPermission, useAuth } from '../hooks';
import { useIdleTimer } from 'react-idle-timer';
import { AdminPermission, AdminType } from '../types';

interface IProp {
  userType?: AdminType;
  permissions?: AdminPermission[];
}

const AuthGuard: React.FC<IProp> = ({ permissions, userType }) => {
  const IDLE_TIMEOUT = (1000 * 60 * 5)*30; // 2 minutes
  const navigate = useNavigate();
  const { hasRequiredPermissions } = useAdminPermission();

  const { loggedIn, getAuthUser, loading, clearSession } = useAuth();

  const isPermitted = useMemo(
    () => hasRequiredPermissions(permissions, userType),
    [hasRequiredPermissions, permissions, userType]
  );

  const onIdle = () => {
    if (isIdle()) {
      clearSession();
      return;
    }
    reset();
  };

  const onAction = () => {
    reset();
  };

  const onActive = () => {
    reset();
  };

  const { reset, isIdle } = useIdleTimer({
    onIdle,
    onAction,
    onActive,
    debounce: 250,
    element: document,
    timeout: IDLE_TIMEOUT,
  });

  useEffect(() => {
    getAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    useEffect(() => {
      if (!loading && !loggedIn) {
        navigate(authRoutes.login, { replace: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loggedIn]);

    useEffect(() => {
      if (!loading && !isPermitted) {
        navigate(appRoutes.dashboard, { replace: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isPermitted]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default AuthGuard;
