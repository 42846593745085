import { Outlet } from 'react-router-dom';
import { useMenu } from '../../../providers';
import { SideNav } from './components';
import cn from 'classnames';

const MainLayout = () => {
  const { isSmallScreen, showSideNav } = useMenu();
  return (
    <main
      className={cn([
        'grid min-h-screen w-full gap-0 overflow-x-hidden',
        {
          'grid-cols-mobile-menu': isSmallScreen && showSideNav,
          'grid-cols-1': !showSideNav,
          'grid-cols-12': !isSmallScreen && showSideNav,
        },
      ])}>
      <div
        className={cn([
          'relative max-h-screen overflow-y-scroll shadow',
          {
            'col-span-4 lg:col-span-3 xl:col-span-2': !isSmallScreen,
            hidden: !showSideNav,
          },
        ])}>
        <SideNav />
      </div>
      <div
        className={cn([
          'bg-gray-50 max-h-screen m-0 p-0 overflow-x-scroll relative',
          { 'col-span-8 lg:col-span-9 xl:col-span-10': !isSmallScreen && showSideNav },
        ])}>
        <Outlet />
      </div>
    </main>
  );
};

export default MainLayout;
