import cn from 'classnames';
import { Fragment } from "react";
import { Cell } from "react-table";
import { PaymentChannelDto } from '../../../types';
import Button from '../../Button';

const BooleanCell = ({ value }: Cell) => (
  value ? 'Active' : 'Inactive'
);
export const paymentChannelColumns = (openStatusModal: (channelId: number)=>void, canActivate: boolean) => [
    {
      Header: '#',
      Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    
    {
      Header: 'Slug',
      accessor: 'slug',
    },
    {
      Header: 'Is Global',
      accessor: 'isGlobal',
      Cell: BooleanCell
    },
    {
      Header: 'Channel Color',
      accessor: 'channelColor',
      Cell: ({value}: Cell)=>(
        <div>
          <span className={cn([`py-1 px-4 text-10 text-white font-semibold rounded-md ${value}`])} style={{backgroundColor: value}}>{value}</span>
        </div>
      )
    },

    {
      Header: 'Status',
      accessor: 'isActive',
      Cell: BooleanCell
    },
    ...(canActivate? [
      {
        Header: ' ',
        Cell: ({ row }: Cell<PaymentChannelDto>)=>(
          <div className='flex gap-4'>
            <Button onClick={()=> openStatusModal(row.original.id)} className='p-2 bg-[#BB3C3C] text-white font-medium'> { row.original.isActive? 'Deactivate' : 'Activate'} </Button>
          </div>
          
        )
      }
    ]:[])
    
  ];