import MainWrapper from "../../../components/MainWrapper";
import { DisputeLogList } from "../../../components/pageComponents";

const DisputeLogs = () => {
    return (
      <MainWrapper title='Manager Users'>
        <DisputeLogList />
      </MainWrapper>
    );
  };
  
  export default DisputeLogs;