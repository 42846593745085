import { Option, Select } from "@material-tailwind/react";
import { Fragment, SetStateAction } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { TxCategories, TxSides, TxStatuses } from "../../../../constants";
import { ITxCategory, ITxSide, ITxStatus } from "../../../../types";

export type IVerifiedSelect = 'all' | 'verified' | 'unverified';

interface IProps {
  dateRange: any;
  status: ITxStatus;
  category: ITxCategory;
  side: ITxSide;
  handleDateChange: (value: any) => void;
  setStatus: React.Dispatch<SetStateAction<ITxStatus>>;
  setCategory: React.Dispatch<SetStateAction<ITxCategory>>;
  setSide: React.Dispatch<SetStateAction<ITxSide>>;
}

const TransactionFilterFields: React.FC<IProps> = (props) => {
  const labelClassName = 'text-10 text-gray-500 font-semibold mb-2';
  return (
    <Fragment>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}> Status</div>
        <Select
          variant='outlined'
          value={props.status}
          onChange={(value) => props.setStatus(value as any)}
        >
          {TxStatuses.map((status, i) => 
            <Option key={i} value={status}>{status}</Option>
          )}
          
        </Select>
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Category</div>
        <Select
          variant='outlined'
          value={props.category}
          onChange={(value) => props.setCategory(value as any)}
          >
            {TxCategories.map((category, i) => 
              <Option key={i} value={category}>{category}</Option>
            )}
            
        </Select>
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Side</div>
        <Select
          variant='outlined'
          value={props.side}
          onChange={(value) => props.setSide(value as any)}
          >
          {TxSides.map((side, i) => 
            <Option key={i} value={side}>{side}</Option>
          )}
        </Select>
      </div>
      <div className='col-span-2'>
        <div className={labelClassName}>Registration Date</div>
        <Datepicker
          useRange={false}
          value={props.dateRange}
          onChange={props.handleDateChange}
          containerClassName='border rounded-md text-13'
        />
      </div>
    </Fragment>
  );
};

export default TransactionFilterFields;