import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getIdentities } from '../../../api';
import { useAdminPermission } from '../../../hooks';
import { IKycFilter, IPaginationMeta, KycDto } from '../../../types';
import { KycMetricCards } from '../../pageComponents';
import Table from '../../Table';
import { kycColumns } from './config';
import KycFilter from './KycFilter';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
}

const KycTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [kyc, setKyc] = useState<KycDto[]>([]);
  const [filterQuery, setFilterQuery] = useState<IKycFilter>({});
  
  const { hasRequiredPermissions } = useAdminPermission();
  const canViewMetric = hasRequiredPermissions([], 'SUPPORT');
  const canViewMore = hasRequiredPermissions(['MANAGE_USER_KYC'], 'SUPPORT');

  const filter: IKycFilter = useMemo(
    () => ({ limit, page, ...filterQuery }),
    [filterQuery, limit, page]
  );
  const columns = useMemo(() => kycColumns(showFull, canViewMore), [showFull, canViewMore]);

  const reviewedKycCount = useMemo(() => kyc?.filter(x => x.status === 'REVIEW_REQUIRED').length, [kyc]);
  const rejectedKycCount = useMemo(() => kyc?.filter(x => x.status === 'REJECTED').length, [kyc]);
  const approvedKycCount = useMemo(() => kyc?.filter(x => x.status === 'VERIFIED').length, [kyc]);


  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getIdentities(filter),
    onSuccess: ({ data, meta }) => {
        setMeta(meta);
        setKyc(data);
    },
  });


  return (
    <div>
      {canViewMetric && <KycMetricCards rejectedKycCount={rejectedKycCount} approvedKycCount={approvedKycCount} reviewedKycCount={reviewedKycCount} />}
      
      {enableFilter && (
        <KycFilter
          updateQueryFilter={setFilterQuery}
        />
      )}
      <Table
        columns={columns}
        data={kyc}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default KycTable;
