import Table from '../../Table';
import { disputeLogColumns } from './config';
import { getDisputeLogs } from '../../../api';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DisputeLogDto, IDisputeLogFilter, IPaginationMeta } from '../../../types';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
}

const DisputeLogTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery] = useState<IDisputeLogFilter>({});
  const [disputeLogs, setDdisputeLogs] = useState<DisputeLogDto[]>([]);

  const columns = useMemo(() => disputeLogColumns(showFull), [showFull]);

  const filter: IDisputeLogFilter = useMemo(
    () => ({ limit, page, ...filterQuery }),
    [filterQuery, limit, page]
  );
  

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getDisputeLogs(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setDdisputeLogs(data);
    },
  });

  return (
    <div>
      <Table
        columns={columns}
        data={disputeLogs}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default DisputeLogTable;
