import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MainLayout from '../components/layouts/MainLayout';
import { AuthGuard } from '../middlewares';
import { Dashboard, NotFound } from '../pages';
import { MenuProvider } from '../providers';
import { useWebsocket } from '../providers';
import { useRateStore } from '../store';
import AdminUserRoutes from './AdminUserRoutes';
import AuditRoutes from './AuditRoutes';
import AuthRoutes from './AuthRoutes';
import KycRoutes from './KycRoutes';
import MarketPlaceRoutes from './MarketPlaceRoute';
import PaymentChannelRoutes from './PaymentChannelRoutes';
import SettingRoutes from './SettingRoutes';
import TransactionRoutes from './TransactionRoutes';
import UserRoutes from './UserRoutes';
import WalletRoutes from './WalletRoutes';

const AppRoot = () => {
  const {setRate} = useRateStore();
  const ws = useWebsocket()
  ws.dataChannel.listenToAllRates((data) => {
    setRate(data);
  })
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
        <MenuProvider>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path='/' element={<AuthGuard />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route path='user/*' element={<UserRoutes />} />
              <Route path='setting/*' element={<SettingRoutes />} />
              <Route path='paymentChannel/*' element={<PaymentChannelRoutes />} />
              <Route path='wallet/*' element={<WalletRoutes />} />
              <Route path='Marketplace/*' element={<MarketPlaceRoutes />} />
              <Route path='Transaction/*' element={<TransactionRoutes />} />
              <Route path='admin/*' element={<AdminUserRoutes />} />
              <Route path='audit/*' element={<AuditRoutes />} />
              <Route path='kyc/*' element={<KycRoutes />} />
              
              
            </Route>
            <Route path='auth/*' element={<AuthRoutes />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </MenuProvider>
        <ToastContainer
          draggable
          rtl={false}
          pauseOnHover
          closeOnClick
          theme='dark'
          hideProgressBar
          autoClose={5000}
          pauseOnFocusLoss
          newestOnTop={false}
          position='top-right'
        />
    </QueryClientProvider>
  );
};

export default AppRoot;
