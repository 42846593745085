import {IKycStatus, PillVariant} from "../types"

interface KycStatusProp {
    text: string;
    pillVariant: PillVariant
}

export const kycStatusProps: Record<IKycStatus, KycStatusProp> = {
    BLANK: {
        text: 'Pending',
        pillVariant: 'primary'
    },
    PENDING: {
        text: 'Pending',
        pillVariant: "warning"
    },
    REVIEW_REQUIRED: {
        text: 'Review Required',
        pillVariant: 'danger'
    },
    FAILED: {
        text: 'Failed',
        pillVariant: 'danger'
    },
    REJECTED: {
        text: 'Review Required',
        pillVariant: 'danger'
    },
    VERIFIED: {
        text: 'Verified',
        pillVariant: 'success'
    }
}