import useRate from "../../../hooks/useRate";

interface IProps {
  asset: string;
  fiat?: string;
  qty: number;
}
const PriceSpan = ({ asset, fiat= 'USD' , qty }: IProps) => {
    const {getFiatValue} = useRate();
    return (
        <span>${getFiatValue({asset, fiat, qty})}</span>
    )
};

export default PriceSpan;