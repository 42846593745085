import Table from '../../Table';
import { adColumns } from './config';
import { getAds } from '../../../api';
import { AdsDto, IPaginationMeta } from '../../../types';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IAdFilter } from '../../../types';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
}

const AdsTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery] = useState<IAdFilter>({});
  const [ads, setAds] = useState<AdsDto[]>([]);

  const columns = useMemo(() => adColumns(showFull), [showFull]);

  const filter: IAdFilter = useMemo(
    () => ({ limit, page, ...filterQuery }),
    [filterQuery, limit, page]
  );
  

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getAds(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setAds(data);
    },
  });

  return (
    <div>
      <Table
        columns={columns}
        data={ads}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default AdsTable;
