export enum KycReviewReasonType {
    DUPLICATE_RECORD = 'DUPLICATE_RECORD',
    AML_CHECK_ERROR = 'AML_CHECK_ERROR',
    AML_RECORD_FOUND = 'AML_RECORD_FOUND'
}

export enum KycStatusType {
    BLANK = 'BLANK',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    FAILED = 'FAILED',
    VERIFIED = 'VERIFIED',
    REVIEW_REQUIRED = 'REVIEW_REQUIRED'
}
export const DOWNLOAD_LIMIT = 1000;
export const UserGenders = ['MALE', 'FEMALE'] as const;
export const UserStatuses = ['ACTIVE', 'SUSPENDED', 'LOCKED'] as const;
export const UserRoles = ['USER', 'MERCHANT', 'ADMIN'] as const;
export const UserLevels = ['T0', 'T1', 'T2', 'T3', 'T4', 'T5'] as const;

export const ActionType = ['ACTIVATE', 'DEACTIVATE'] as const;
export const KycStatuses = [KycStatusType.BLANK, KycStatusType.PENDING, KycStatusType.REJECTED, KycStatusType.FAILED, KycStatusType.VERIFIED, KycStatusType.REVIEW_REQUIRED] as const;
export const KycReviewReason = [KycReviewReasonType.DUPLICATE_RECORD, KycReviewReasonType.AML_CHECK_ERROR, KycReviewReasonType.AML_RECORD_FOUND] as const;
