import { useEffect, useState } from 'react';
import { getStats } from '../api';
import { SysStatsDto } from '../types';
import useAuth from './useAuth';


const useSys = () => {
  const {loggedIn} = useAuth();
  const [stats, setStats] = useState<SysStatsDto | undefined>();
  const [statsLoading, setStatsLoading] = useState<boolean>(false);

  const fetchStats = () => {
    if(!loggedIn) return;
    setStatsLoading(true);
    getStats()
      .then(setStats)
      .catch(console.error)
      .finally(() => setStatsLoading(false));
  };

  useEffect(() => {
    if(loggedIn) {
      fetchStats();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn,])
  

  return {
    stats,
    fetchStats,
    statsLoading
  }
};

export default useSys;
