import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { VscChevronLeft } from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { getTransactionAggregate, getUserById, getUserWallet } from "../../../api";
import Button from "../../../components/Button";
import MainWrapper from "../../../components/MainWrapper";
import { UpdateApiAcccessModal, UpdateUserStatusModal, UpgradeUserToMerchantModal } from "../../../components/Modals";
import { useAdminPermission } from "../../../hooks";
import { AccountUserDto, TransactionAggregateDto, WalletDto } from "../../../types";


interface IUserPortfolio  {
  currency?: string;
  balance?: number;
  availableBalance?: number;
  lockedBalance?: number;
};
const UserPortfolioRow = ({currency, balance, availableBalance, lockedBalance}:IUserPortfolio) => (
  <div className="flex items-center">
    <div className="text-14 w-1/5">{currency}</div>
    <div className="text-14 w-2/5">{Number(balance).toFixed(8)} {currency}</div>
    <div className="flex flex-col justify-start w-3/5">
      <p className="text-14 text-gray-500">Available: <span className="font-medium text-black">{Number(availableBalance).toFixed(8)} {currency}</span></p>
      <p className="text-14 text-gray-500">Locked: <span className="font-medium text-black">{Number(lockedBalance).toFixed(8)} {currency}</span></p>
    </div>
  </div>
);

interface ITransactionCategory  {
  totalAmount?: number;
  usageCount?: number;
  label?: string;
}

const TransactionCategoryDetail = ({totalAmount, usageCount, label}:ITransactionCategory)=>(
  <div className="flex justify-between items-center">
    <div className="flex flex-1 text-14">{label}</div>
    <div className="flex flex-1 text-14">{totalAmount}</div>
    <div className="flex flex-1 text-14">${usageCount}</div>
  </div>
)

const UserDetails = () => {
  const [user, setUser] = useState<AccountUserDto>();
  const [wallet, setWallet] = useState<WalletDto>();
  const [transactionAgg, setTransactionAgg] = useState<TransactionAggregateDto[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState<boolean>(false);
  const [openApiAccessModal, setOpenApiAccessModal] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({ endDate: null, startDate: null });

  const { hasRequiredPermissions } = useAdminPermission();
  const enableUpgradeMerchant = hasRequiredPermissions(['UPGRADE_TO_MERCHANT'], 'MANAGER');
  const enableApiAccess = hasRequiredPermissions(['MANAGE_API_ACCESS'], 'MANAGER');
  const enableSuspendAccount = hasRequiredPermissions(['SUSPEND_ACCOUNT'], 'MANAGER');
  

  const handleDateChange = (_date: any) => {
      setDateRange(_date);
  };
  const { userId } = useParams();

  const closeModal = () => setOpenModal(false);
  const closeUpgradeModal = () => setOpenUpgradeModal(false);
  const closeApiAccessModal = () => setOpenApiAccessModal(false);
  
  useEffect(()=>{
    if(userId){
      getUserById(userId)
      .then(data => {
        setUser(data)
      })
      .catch(console.log)

      getUserWallet(userId)
      .then(data => setWallet(data))
      .catch(console.log)

      getTransactionAggregate({userId})
      .then(data => setTransactionAgg(data))
      .catch(console.log)
    }
  }, [userId])

  useEffect(()=>{
    if(userId){
      getTransactionAggregate({userId, fromDate: dateRange?.startDate ? new Date(dateRange?.startDate).toISOString() : undefined,
        toDate: dateRange?.endDate ? new Date(dateRange?.endDate).toISOString() : undefined})
      .then(data => setTransactionAgg(data))
      .catch(console.log)
    }
  }, [userId, dateRange])

  useEffect(()=>{
    getUserById(userId!)
      .then(data => {
        setUser(data)
      })
      .catch(console.log)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const actionType = useMemo(()=>{
    return user?.status === 'ACTIVE' ? 'DEACTIVATE' : 'ACTIVATE'
  }, [user])

  return (
    <MainWrapper title="Users Manager">
      <div className="flex flex-col">
        <Link to={"/user"} className="flex items-center gap-1">
          <VscChevronLeft />
          <div className="rounded">Back to users manager</div>
        </Link>

        <div className="flex flex-col rounded shadow-md mt-5 mb-9">
          <div className="flex p-6 bg-gray-100 gap-8 w-full">
            <div className="flex gap-1 pr-5">
              <div className="rounded-full h-14 w-14 bg-gray-200 p-4 text-16 font-semibold items-center">NA</div>
              <div className="flex flex-col">
                <p className="text-14 font-normal pb-1">UID</p>
                <div className="flex pb-2">
                  <div className="text-12">{user?.uid}</div>
                </div>
                <div className="flex text-12 font-medium px-3 text-white bg-purple-400 rounded-2xl justify-center">{user?.status}</div>
              </div>
            </div>

            <div className="h-16 border border-gray-400"></div>

            <div className="flex flex-col pr-24">
              <p className="text-14 font-normal">Name</p>
              <p className="text-16 font-semibold pb-1">{user?.profile.fullName ?? "N/A"}</p>
              <div className="flex pb-2">
                <div className="text-14">@{user?.username}</div>
              </div>
            </div>

            <div className="h-16 border border-gray-400"></div>
            
            <div className="flex flex-col pr-12">
              <p className="text-14 font-normal">Phone number</p>
              <div className="flex pb-2">
                <div className="text-14">{user?.phone ?? "N/A"}</div>
              </div>
            </div>

            <div className="h-16 border border-gray-400"></div>

            <div className="flex flex-col">
              <p className="text-14 font-normal">Email</p>
              <div className="flex pb-2">
                <div className="text-14">{user?.email}</div>
              </div>
            </div>

            <div className="h-16 border border-gray-400"></div>

            <div className="flex flex-col">
              <p className="text-14 font-normal">Level</p>
              <div className="flex pb-2">
                <div className="text-14">{`${user?.level} - ${user?.roles.includes('MERCHANT')? 'MERCHANT' : 'USER'}`}</div>
              </div>
            </div>

          </div>
          {user?.status === 'SUSPENDED' && 
            <div className="flex mx-8 mt-4 p-3 bg-red-100 rounded border-2 border-red-800 gap-4">
              <div className="flex rounded-full h-14 w-14 justify-center items-center text-white bg-red-500">i</div>
              <div className="flex flex-col">
                <div className="text-16 text-red-400">Attention</div>
                <div className="text-16 text-red-400">{user?.comment}</div>
              </div>
            </div>
          }
          <div className="flex mx-8 my-4 gap-2">
            <div className="flex flex-col w-1/2 rounded shadow-md">
              <div className="flex bg-black px-9 py-4 justify-between w-full rounded-t">
                <div className="flex text-white">User portfolio</div>
                <div className="flex text-white">{`Registration Date ~ ${dayjs(user?.createdAt).format('DD-MM-YYYY')}`}</div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex px-8 py-3 bg-gray-200">
                  <div className="text-14 w-1/5">Name</div>
                  <div className="text-14 w-2/5">Balance</div>
                  <div className="text-14 w-3/5">Available/Locked</div>
                </div>

                <div className="flex flex-col px-8 py-3 gap-2">
                  
                  {wallet?.assets?.map((walletAsset, index)=> (
                    
                    <UserPortfolioRow key={index} currency={walletAsset?.currency} balance={walletAsset?.balance} availableBalance={walletAsset?.availableBalance} lockedBalance={walletAsset?.lockedBalance}/>
                  ))}
                </div>
              </div>
              
            </div>



            <div className="flex flex-col w-1/2 rounded">
              <div className="flex w-full justify-between">
                <div className="flex flex-col">
                  <div className="text-16 font-semibold">Services</div>
                  <div className="text-14 font-medium">Total transaction value ~ <span className="text-16 font-semibold">${transactionAgg?.reduce((total, tran) => total + Number(tran.totalAmount), 0)}</span></div>
                </div>
                
                <div className="items-center">
                  <Datepicker
                      useRange={false}
                      containerClassName='border rounded-md text-13'
                      value={dateRange}
                      onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className="flex flex-col pt-3 gap-2">
                <div className="flex px-8 py-3 justify-between bg-gray-200">
                  <div className="flex flex-1 text-14">Category</div>
                  <div className="flex flex-1 text-14">Usage count</div>
                  <div className="flex flex-1 text-14">Transaction value</div>
                </div>

                <div className="flex flex-col px-8 py-3 gap-3">
                  <TransactionCategoryDetail
                     label="Wallet Deposit"
                     totalAmount={transactionAgg?.filter(x => x.category === 'WALLET' && x.side === 'DEPOSIT').reduce((total, val) => total + Number(val.totalTransactions), 0)}
                     usageCount={transactionAgg?.filter(x => x.category === 'WALLET' && x.side === 'DEPOSIT').reduce((total, val) => total + Number(val.totalAmount), 0)}
                   />

                  <TransactionCategoryDetail
                     label="Wallet Withdrawer"
                     totalAmount={transactionAgg?.filter(x => x.category === 'WALLET' && x.side === 'WITHDRAWAL').reduce((total, val) => total + Number(val.totalTransactions), 0)}
                     usageCount={transactionAgg?.filter(x => x.category === 'WALLET' && x.side === 'WITHDRAWAL').reduce((total, val) => total + Number(val.totalAmount), 0)}
                   />

                  <TransactionCategoryDetail
                     label="Bitcode Invoice"
                     totalAmount={transactionAgg?.filter(x => x.category === 'BITCODE' && x.recipient).reduce((total, val) => total + Number(val.totalTransactions), 0)}
                     usageCount={transactionAgg?.filter(x => x.category === 'BITCODE' && x.recipient).reduce((total, val) => total + Number(val.totalAmount), 0)}
                   />
                  
                  <TransactionCategoryDetail
                     label="Bitcode Voucher"
                     totalAmount={transactionAgg?.filter(x => x.category === 'BITCODE' && !x.recipient).reduce((total, val) => total + Number(val.totalTransactions), 0)}
                     usageCount={transactionAgg?.filter(x => x.category === 'BITCODE' && !x.recipient).reduce((total, val) => total + Number(val.totalAmount), 0)}
                   />

                  <TransactionCategoryDetail
                     label="Swap"
                     totalAmount={transactionAgg?.filter(x => x.category === 'SWAP').reduce((total, val) => total + Number(val.totalTransactions), 0)}
                     usageCount={transactionAgg?.filter(x => x.category === 'SWAP').reduce((total, val) => total + Number(val.totalAmount), 0)}
                   />
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
        <div className="flex justify-end gap-3">
          {
            enableSuspendAccount && 
            <Button 
              onClick={() => {
                setOpenModal(true)
                
              }} className={`text-14 font-medium ${user?.status === 'ACTIVE' ? ' bg-white text-yellow-600 shadow-none border border-yellow-600':'bg-red-200 text-red-800'}`}>{`${user?.status === 'ACTIVE'? 'Suspend': 'Activate'}`} </Button>
          }

          {
            enableApiAccess && 
            <Button 
              onClick={() => setOpenApiAccessModal(true)} 
              className={`text-14 font-medium ${user?.apiAccessEnabled ? 'bg-red-200 text-red-800': 'bg-white text-yellow-600  border-yellow-600'}  shadow-none border`}> {`${user?.apiAccessEnabled ? 'Restrict API Access' : 'Activate API Access '}`}</Button>
          }
          
          {
            enableUpgradeMerchant && 
            <Button 
              onClick={()=>{
                setOpenUpgradeModal(true)
              }}
              className={`text-14 font-medium ${user?.roles.includes('MERCHANT')? 'bg-red-200 text-red-800 ': ' bg-black text-white'}`}>{`${user?.roles.includes('MERCHANT')? 'Revoke merchant': 'Upgrade to merchant'}`}</Button>
          }
        </div>
      </div>
      <UpdateUserStatusModal open={openModal} closeModal={closeModal} userId={user?.id} actionType={actionType} setUser ={setUser}/>
      <UpgradeUserToMerchantModal open={openUpgradeModal} closeModal={closeUpgradeModal} userId={user?.id} isMerchant={user?.roles.includes('MERCHANT')} setUser={setUser}/>
      <UpdateApiAcccessModal open={openApiAccessModal} closeModal={closeApiAccessModal} userId={user?.id} apiAccessEnabled={user?.apiAccessEnabled}/>
    </MainWrapper>
  )
}

export default UserDetails;



