import { Switch } from "@material-tailwind/react";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { addPaymentChannel } from "../../../api";
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import MainWrapper from "../../../components/MainWrapper";
import { FormErrorText } from "../../../components/shared";

const AddChannel = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleCloseModal = ()=>{
        formik.resetForm();
        //closeModal();
    }
    const formik = useFormik({
        initialValues: {
            slug:"",
            title: "",
            channelColor:"",
            isGlobal: false,
            options: ""
        },
        validationSchema: Yup.object().shape({
            slug: Yup.string().required(),
            title: Yup.string().required(),
        }),
        onSubmit: (value)=>{
            
            setError("");
            setLoading(true)
            addPaymentChannel(value)
            .then((_)=>{
                toast.success("Request was successful")
            })
            .catch((error) => {
                const errors =error?.response?.data;
                if(error){
                    for (const key in errors) {

                        console.log(`${key}: ${errors[key]}`);
                        formik.setFieldError(key, errors[key]);
                    }
                }else{
                    toast.error(error?.response?.data?.message || 'Request failed. Please retry!');
                }
                
                
            })
            .finally(()=>{setLoading(false)})
        }
    });
    return (
      <MainWrapper title="Settings/ Add Payment channel">
        <div className="flex flex-col w-full pt-2 pb-10 sm:justify-center sm:pt-0 ">
            <div className="pt-4">
                <h3 className="text-4xl font-bold ">
                    Create Payment Channel
                </h3>
            </div>
            <div className="w-full py-4 mt-4 overflow-hidde sm:max-w-lg sm:rounded-lg">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-2 flex justify-center">
                        <FormErrorText error={error}/>
                    </div>
                    <div className="flex flex-row justify-start">
                        <div className="mt-4">
                            <Input 
                                label="Slug" 
                                name="slug" 
                                value={formik.values.slug}
                                onChange={formik.handleChange}
                                error={formik.errors.slug}
                            />
                        </div>

                        <div className="mt-4">
                            <Input 
                                label="Title" 
                                name="title" 
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.errors.title}
                                />
                                
                        </div>
                    </div>
                    

                    <div className="mt-4">
                        <Input 
                            label="Channel Color" 
                            name="channelColor" 
                            value={formik.values.channelColor}
                            onChange={formik.handleChange}
                            />
                    </div>
                    
                    <div className="mt-4">
                        <Switch 
                            label="Is Global" 
                            name="isGlobal"
                            checked={formik.values.isGlobal}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="grid grid-cols-1 divide-y-4 divide-teal-600  my-1"></div>
                    <div className="p-2 flex">
                        add payment options
                    </div>
                    <div className="flex flex-col px-2 ">
                        <div className="flex flex-row px-2 pb-2 justify-around">
                            <Input 
                                label="type" 
                                name="type" 
                                value={formik.values.channelColor}
                                onChange={formik.handleChange}
                                />
                            <Input 
                                label="name" 
                                name="name" 
                                value={formik.values.channelColor}
                                onChange={formik.handleChange}
                                />
                        </div>
                        <div className="flex flex-row px-2 justify-around">
                            <Input 
                                label="label" 
                                name="label" 
                                value={formik.values.channelColor}
                                onChange={formik.handleChange}
                                />
                            <Input 
                                label="minimum" 
                                name="minimum" 
                                value={formik.values.channelColor}
                                onChange={formik.handleChange}
                                />
                        </div>

                        <div className="flex flex-row px-2 justify-around">
                            <Input 
                                label="maximum" 
                                name="maximum" 
                                value={formik.values.channelColor}
                                onChange={formik.handleChange}
                                />
                            
                        </div>
                        
                    </div>
                    

                    <div className="p-2 flex">
                        <div className="w-1/2"></div>
                        <div className="w-1/2 flex justify-end">
                            <Button 
                                type="submit"
                                className="bg-gray-500 text-white p-2 rounded text-sm w-auto"
                                loading={loading}
                                >
                                Submit
                            </Button>
                            <Button
                                className="bg-red-500 text-white p-2 ml-6 rounded text-lg w-auto"
                                onClick={handleCloseModal}
                                >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    
                </form>
                
            </div>
        </div>
      </MainWrapper>
    )
  }
  
export default AddChannel;