import { ArcElement, CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import { useEffect, useMemo, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import Datepicker from "react-tailwindcss-datepicker";
import { getUserMetric } from "../../../../api";
import { UserMetricData } from "../../../../types";


interface IChart{
    labels: string[]
    data: number[],
    backgroundColor: string[],
    borderColor: string[]

}

interface IDonutGraphData{
    bgColor?: string
    quantity?: number
    label?: string
}

const DonutGraphDetails = ({bgColor, quantity, label}: IDonutGraphData)=>{
    return (
        <div className="flex w-full">
            <div className="flex w-2/3 justify-between items-center">
                <div className="flex gap-1">
                    <span className="inline-flex items-center rounded-md w-7 py-0.5" style={{background: bgColor}}></span>
                    <div className="text-14">{label}</div>
                </div>
                <div className="flex">≈</div>
            </div>
            
            <div className="flex justify-end w-1/3 text-14">{quantity}</div>
        </div>
    )
}


const UserMetric = ()=> { 
    const [userMetric, setUserMetrics] = useState<UserMetricData[]>([]);
    const [metricData, setMetricData] = useState<IChart>();
    const [inactiveUser, setInactiveUser] = useState<number>(0);

    const [dateRange, setDateRange] = useState<any>({ endDate: null, startDate: null });

    const handleDateChange = (_date: any) => {
        setDateRange(_date);
    };

    useEffect(() => {
        getUserMetric({fromDate: dateRange?.startDate ? new Date(dateRange?.startDate).toISOString() : undefined,
            toDate: dateRange?.endDate ? new Date(dateRange?.endDate).toISOString() : undefined})
        .then(userMetric => {
            setUserMetrics(userMetric.data)
            setInactiveUser(userMetric.totalActiveUser)
        })
        .catch(console.log)
    }, [dateRange])

    const totalUsers = useMemo(()=>{
        return userMetric.reduce((total, val) => total + Number(val.totalUsers), 0)
    }, [userMetric])
   
    const getDonutMetricData = (userMetric: UserMetricData[], backgroundColor:string[]): IChart =>{
        const groupedData1 = new Map<string, number>();
        const verifiedPhone = userMetric.filter(metric => Boolean(metric.isPhoneVerified));
        groupedData1.set("Verified phone number", verifiedPhone.reduce((total, val)=> total + Number(val.totalUsers), 0));

        const verifiedEmail = userMetric.filter(metric => Boolean(metric.isEmailVerified));
        groupedData1.set("Verified email addresses", verifiedEmail.reduce((total, val)=> total + Number(val.totalUsers), 0));

        const completedKYC = userMetric.filter(metric => metric.idKycStatus === "VERIFIED");
        groupedData1.set("Completed KYC", completedKYC.reduce((total, val)=> total + Number(val.totalUsers), 0));

        const activatedUser = userMetric.filter(metric => metric.status === "ACTIVE");
        groupedData1.set("Activated users", activatedUser.reduce((total, val)=> total + Number(val.totalUsers), 0));

        groupedData1.set("Active users", inactiveUser);
        groupedData1.set("Inactive users",totalUsers - inactiveUser);
        const labels: string[] = [];
        const data: number[] = [];
        groupedData1?.forEach((value, key) => {
            labels.push(key);
            data.push(value)
        })

        return {labels: labels,  backgroundColor, borderColor:backgroundColor, data: data}
    }

    
    useEffect(()=> {
        setMetricData(getDonutMetricData(userMetric, ["#5D73E8", '#18C1CC','#CC0A96','#E6DD15', '#EB9F44', '#f53a8e']));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userMetric])

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
    )
    const optionsc = {
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      
      const userMetricData = {
        
        labels: metricData?.labels,
        datasets: [
          {
            label: 'total',
            data: metricData?.data,
            backgroundColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',
              '#EB9F44', 
              '#f53a8e',
            ],
            borderColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',
              '#EB9F44', 
              '#f53a8e',
            ],
            borderWidth: 1,
          },
        ],
      };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
            },
            legend: {
                display: false, // Set display to false to remove the label
            },
        },
        scales: {
            y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            },
            y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
            },
        },
    };
    

    const data = {
        labels : ['12:00am', '1:00am', '2:00am', '3:00am', '4:00am', '5:00am', '6:00am'],
        datasets: [
            {
                data: [10, 54, 88, 80, 2, 32, 71],
                borderColor: '#CF5050',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                data: [73, 99, 200, 43, 16, 30, 100],
                borderColor: '#52B96F',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            },
            {
                data: [73, 99, 20, 43, 16, 30, 100],
                borderColor: '#52B96F',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y2',
            },
            {
                data: [72, 99, 20, 40, 16, 30, 100],
                borderColor: '#962720',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y3',
            },
        ],
    };
    return (
    <div className="flex flex-col shadow rounded mb-4">
        <div className="flex px-5 py-3 p-2 justify-between shadow">
            <p className="text-14 font-medium">User Manager</p>
            <p className="text-12">Total number of Registered Users: <span className="text-16 font-bold">{userMetric.reduce((total, val) => total + Number(val.totalUsers), 0)}</span></p>
            <div>
                <Datepicker
                    useRange={false}
                    containerClassName='border rounded-md text-13'
                    value={dateRange}
                    onChange={handleDateChange}
                />
            </div>
        </div>
        <div className="flex p-8 gap-1">
            <div className="flex-col w-1/2">
                <Line options={options} data={data} />
            </div>
            <div className="flex flex-col flex-1 shadow-md rounded px-8">
                <div className="flex items-center justify-center pb-11">
                    <div className="h-40 w-40">
                        <Doughnut options={optionsc} data={userMetricData}/>
                    </div>
                </div>
                
                <div className="flex-col gap-2 pb-8">
                    
                    {userMetricData.labels?.map((val, i)=> (
                        <DonutGraphDetails key={i} label={val} quantity={metricData?.data[i]}  bgColor={metricData?.backgroundColor[i]}/>
                    ))}
                    
                </div>
            </div>
        </div>
    </div>
)};
export default UserMetric;