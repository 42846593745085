import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AdminUserDto } from '../../../types';
import { RelativeTimeCell, StatusCell } from '../common';



export const adminUserColumns = (showFull?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }: Cell<AdminUserDto>) => <Fragment>{row.original.firstName} {row.original.lastName}</Fragment>,
  },
  
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    Cell: StatusCell,
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  {
    Header: '',
    accessor: 'id',
    Cell: ({value}: Cell)=> { return <Link to={value}>view more</Link>},
  },
  
];


