import { AppModuleDto, AppServiceDto, CreatePaymentChannelInput, PaymentChannelDto, ServiceMetricDto, SysStatsDto, TokenInput } from '../types';
import AxiosInstance from './config';


export const getStats = async (): Promise<SysStatsDto> => {
  try {
    const { data } = await AxiosInstance.get<SysStatsDto>('/systems/stats');
    return data;
  } catch (error) {
    throw error;
  }
};


export const getAppModules = async (): Promise<AppModuleDto[]> => {
  try {
    const { data } = await AxiosInstance.get<AppModuleDto[]>('/systems/appModule');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAppModuleById = async (moduleId: string): Promise<AppModuleDto> => {
  try {
    const { data } = await AxiosInstance.get<AppModuleDto>(`/systems/appModule/${moduleId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAppModuleStatus = async (moduleId: string, payload: TokenInput): Promise<AppModuleDto> => {
  try {
    const { data } = await AxiosInstance.put<AppModuleDto>(`/systems/appModule/${moduleId}/status`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAppServices = async (): Promise<AppServiceDto[]> => {
  try {
    const { data } = await AxiosInstance.get<AppServiceDto[]>('/systems/appService');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAppServiceById = async (serviceId: string): Promise<AppServiceDto> => {
  try {
    const { data } = await AxiosInstance.get<AppServiceDto>(`/systems/appService/${serviceId}`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const updateAppServiceStatus = async (serviceId: string, payload: TokenInput): Promise<AppServiceDto> => {
  try {
    const { data } = await AxiosInstance.put<AppServiceDto>(`/systems/appService/${serviceId}/status`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getServiceMetrics = async (): Promise<ServiceMetricDto> => {
  try {
    const { data } = await AxiosInstance.get<ServiceMetricDto>(`/systems/metrics`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addPaymentChannel = async (payload: CreatePaymentChannelInput): Promise<PaymentChannelDto> => {
  try {
    const { data } = await AxiosInstance.post<PaymentChannelDto>('/systems/paymentChannelConfig', { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};
export const getPaymentChannels = async (): Promise<PaymentChannelDto[]> => {
  try {
    const { data } = await AxiosInstance.get<PaymentChannelDto[]>(`/systems/paymentChannelConfig`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentChannelById = async (id: string): Promise<PaymentChannelDto> => {
  try {
    const { data } = await AxiosInstance.get<PaymentChannelDto>(`/systems/paymentChannelConfig/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const updatePaymentChannel = async (id: number, payload: TokenInput): Promise<PaymentChannelDto> => {
  try {
    const { data } = await AxiosInstance.put<PaymentChannelDto>(`/systems/paymentChannelConfig/${id}/status`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};
