import { Fragment } from 'react';
import { Cell } from 'react-table';
import { RelativeTimeCell } from '../common';

export const disputeLogColumns = (showFull?: boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'DisputeId',
    accessor: 'disputeId',
  },
  {
    Header: 'Comment',
    accessor: 'comment',
  },
  {
    Header: 'Recommendation',
    accessor: 'recommendation',
  },
  {
    Header: 'status',
    accessor: 'status',
  },
  {
    Header: 'createdAt',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
];

