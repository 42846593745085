import { io as SocketIO } from 'socket.io-client';

const dataSocket = SocketIO(`wss://api.v1.bitbarter.dev/data`, {
    transports: ['websocket']
})

const useTradeRate = (rateUpdate?: (data: any) => void) => {

    dataSocket.on('connect', () => {

        console.log('connected');
        dataSocket.emit('sub', { type: 'MARKET_UPDATE' })
    })

    dataSocket.on('MARKET_UPDATE', (data: any) => {
        console.log('sub');
        console.log(data)
    })

    dataSocket.on('error', (data) => {
        console.log(data);
    })


    return {


    }
};

export default useTradeRate;
