import { IKycFilter, IPaginatedDto, KycDto, KycOverrideDto, KycPreviewDto, UpdateKycStatusInput } from '../types';
import AxiosInstance from './config';
export const getIdentities = async (params?: IKycFilter): Promise<IPaginatedDto<KycDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<KycDto>>('/kyc', { params });
    return data;
};

export const getIdentityByUserId = async (id: string): Promise<KycPreviewDto> => {
    const { data } = await AxiosInstance.get<KycPreviewDto>(`/kyc/user/${id}`);
    return data;
};

export const updateIdentityStatus = async (id: string, payload: UpdateKycStatusInput): Promise<KycOverrideDto> => {
    const { data } = await AxiosInstance.put<KycOverrideDto>(`/kyc/user/${id}/status`, payload );
    return data;
};