import MainWrapper from "../../../components/MainWrapper";
import { AdsList } from "../../../components/pageComponents";

const Ads = () => {
    return (
      <MainWrapper title='Manage Ads'>
        <AdsList />
      </MainWrapper>
    );
  };
  
  export default Ads;