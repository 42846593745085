import MainWrapper from "../../../components/MainWrapper";
import { TradeMetrics, TradesList } from "../../../components/pageComponents/marketplace";
import { useAdminPermission } from "../../../hooks";

const Trade = () => {
  const { hasRequiredPermissions } = useAdminPermission();
  const canViewMetric = hasRequiredPermissions([], 'ADMIN');
  return (
    <MainWrapper title='Trade Manager'>
      {canViewMetric && 
        <TradeMetrics />
      }
      <TradesList />
    </MainWrapper>
  );
};
  
export default Trade;