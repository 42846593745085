import { GoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from '../../../components/AuthWrapper';
import Loader from '../../../components/Loader';
import { authRoutes } from '../../../config';
import { useAuth } from '../../../hooks';
import cn from 'classnames';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const { shouldSetup2fa, shouldVerifyAuth, login, loading } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldSetup2fa) {
      navigate(authRoutes.init2fa, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSetup2fa]);

  useEffect(() => {
    if (shouldVerifyAuth) {
      navigate(authRoutes.verifyLogin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldVerifyAuth]);

  return (
    <AuthWrapper title='Admin Login' siteTitle='Admin Portal Login | BitBarter Technology'>
      <div className='w-full max-w-[100%] md:max-w-[400px]'>
        <div className='flex justify-left items-center gap-3'>
          <GoogleLogin
            width='300px'
            size='large'
            shape='circle'
            logo_alignment='center'
            onSuccess={({ credential }) => {
              login(credential!);
            }}
            onError={() => {
              toast.error('Login failed. Please retry!');
            }}
            useOneTap
          />
          <Loader color='blue-500' className={cn({ hidden: !loading })} />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
