import React, { useEffect, useMemo, useState } from 'react';
import { getAppServices } from '../../../api';
import { useAdminPermission } from '../../../hooks';
import { AppServiceDto } from '../../../types';
import { UpdateServiceStatusModal } from '../../Modals';
import Table from '../../Table';
import { AapServiceColums } from './config';


const AppServiceTable: React.FC<unknown> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [appServices, setAppServices] = useState<AppServiceDto[]>([]);
  const columns = useMemo(() => AapServiceColums, []);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [serviceId, setAppServiceId] = useState<string>('');

  const { hasRequiredPermissions } = useAdminPermission();
  const showViewMore = hasRequiredPermissions([], 'ADMIN');


  useEffect(() => {
    setLoading(true);
    getAppServices()
      .then((data ) => setAppServices(data))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  const closeModal = () => setOpenModal(false);


  const openStatusModal = (id: string)=>{
    setAppServiceId(id)
    setOpenModal(true);
  }

  const UpdateTable = ()=> {
    setLoading(true);
    getAppServices()
      .then((data ) => setAppServices(data))
      .catch(console.log)
      .finally(() => setLoading(false));
  }
  
  
  return (
    <div>
      <Table columns={columns(openStatusModal, showViewMore)} data={appServices} loading={loading} />
      <UpdateServiceStatusModal closeModal={closeModal} id={serviceId} open={openModal} type='AppService' updateTable={UpdateTable} />
    </div>
  );
};

export default AppServiceTable;
