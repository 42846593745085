import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '../middlewares';
import UserDetails from '../pages/users/details';
import User from '../pages/users';

const UserRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route index element={<User />} />
      </Route>
      <Route element={<AuthGuard userType='ADMIN' permissions={['MANAGE_USERS']} />}>
        <Route path=':userId' element={<UserDetails />} />
      </Route>
    </Routes>
  );
};

export default UserRoutes;
