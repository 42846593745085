import { AdsDto, DisputeLogDto, IDisputeLogFilter, IPageFilter, IPaginatedDto, ITradeDisputeFilter, ResolveDisputeInput, ServiceResponseDto, TradeDisputeDto, TradeDisputeMetricsDto } from "../types";
import { ITradeMetricsFilter, P2pTradeDto, TradeMetricsDto } from "../types/P2pTradeDto";
import AxiosInstance from "./config";

export const getAds = async (params?: IPageFilter): Promise<IPaginatedDto<AdsDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<AdsDto>>('/marketplace/ads', {
        params,
    });
    return data;
};

export const getAdById = async (id: string): Promise<AdsDto> => {
    const { data } = await AxiosInstance.get<AdsDto>(`/marketplace/ads/${id}`);
    return data;
};

export const getTrades = async (params?: IPageFilter): Promise<IPaginatedDto<P2pTradeDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<P2pTradeDto>>('/marketplace/trades', {
        params,
    });
    return data;
};

export const getTradeMetrics = async (params?: ITradeMetricsFilter): Promise<TradeMetricsDto> => {
    const { data } = await AxiosInstance.get<TradeMetricsDto>(`/marketplace/trades/metrics`, {
        params
    });
    return data;
};

export const getTradeById = async (id: string): Promise<P2pTradeDto> => {
    const { data } = await AxiosInstance.get<P2pTradeDto>(`/marketplace/trades/${id}`);
    return data;
};

export const getTradeDisputes = async (params?: ITradeDisputeFilter): Promise<IPaginatedDto<TradeDisputeDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<TradeDisputeDto>>('/dispute', {
        params,
    });
    return data;
};
export const getTradeDisputeById = async (id: string): Promise<TradeDisputeDto> => {
    const { data } = await AxiosInstance.get<TradeDisputeDto>(`/dispute/${id}`);
    return data;
};
export const getTradeDisputeMetrics = async (params?: ITradeDisputeFilter): Promise<TradeDisputeMetricsDto[]> => {
    const { data } = await AxiosInstance.get<TradeDisputeMetricsDto[]>(`/dispute/metrics`, { params });
    return data;
};

export const getDisputeLogs = async (params?: IDisputeLogFilter): Promise<IPaginatedDto<DisputeLogDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<DisputeLogDto>>('/dispute/logs', {
        params,
    });
    return data;
};

export const getDisputeLogById = async (id: number): Promise<DisputeLogDto> => {
    const { data } = await AxiosInstance.get<DisputeLogDto>(`/dispute/logs/${id}`);
    return data;
};

export const resolveTradeDispute = async (payload: ResolveDisputeInput): Promise<ServiceResponseDto> => {
    const { data } = await AxiosInstance.post<ServiceResponseDto>('/dispute/resolve', { ...payload });
    return data;
};