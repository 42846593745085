import { Outlet } from 'react-router-dom';

const AuthLayout = () => (
  <main className='w-full min-h-screen grid grid-cols-1 md:grid-cols-2'>
    <div className='col-span-1 self-center'>
      <Outlet />
    </div>
    <div className='hidden md:block border-l border-black-500'></div>
  </main>
);

export default AuthLayout;
