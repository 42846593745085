import React from "react";
import Loader from "../Loader";

const LoadingCard: React.FC<unknown> = () => {
    return (
        <div className='w-full flex min-h-[100px]  justify-center items-center'>
            <Loader color='blue-600' />
        </div>
    )
}

export default LoadingCard;