import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '../middlewares';
import { Transaction, TransactionDetails } from '../pages';

const TransactionRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route index element={<Transaction />} />
      </Route>
      <Route element={<AuthGuard userType='ADMIN' permissions={['MANAGE_USERS']} />}>
        <Route path=':transactionId' element={<TransactionDetails />} />
      </Route>
    </Routes>
  );
};

export default TransactionRoutes;
