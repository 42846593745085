import { Persistor } from './_type';
import { AdminAuthDto, AdminUserDto } from '../types';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  user: AdminUserDto;
  auth: AdminAuthDto;
  loggedIn: boolean;
}

interface AuthActions {
  logout: () => void;
  setUser: (user: AdminUserDto) => void;
  setAuth: (auth: AdminAuthDto) => void;
  setLoggedIn: (loggedIn: boolean) => void;
}

const initAuthData: AdminAuthDto = {
  exp: 0,
  sub: '',
  verified: false,
  initialized: false,
};

const initUserData: AdminUserDto = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  type: undefined,
  googleId: '',
  status: undefined,
  permissions: [],
  isTotpVerified: undefined,
  createdAt: undefined,
  updatedAt: undefined,
};

export const useAuthStore = create<AuthState & AuthActions>(
  (persist as Persistor<AuthState & AuthActions>)(
    (set) => ({
      auth: initAuthData,
      user: initUserData,
      loggedIn: false,
      setUser: (user: AdminUserDto) => set({ user }),
      logout: () => set({ auth: initAuthData, user: initUserData, loggedIn: false }),
      setAuth: (auth: AdminAuthDto) => set({ auth }),
      setLoggedIn: (loggedIn: boolean) => set({ loggedIn }),
    }),
    {
      name: 'persis:authStore',
      version: 1,
    }
  )
);
