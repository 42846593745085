import React from 'react';
import Pill from './Pill';

interface IStat {
  title: string;
  count: string | number;
  meta?: Array<{ title: string; count: number }>;
}

const StatCard: React.FC<IStat> = ({ title, count, meta }) => (
  <div className='bg-white rounded shadow px-6 py-4'>
    <p className='text-gray-600'>{title}</p>
    <h1 className='title text-center pt-6'>{count}</h1>
    <div className='pt-6 flex justify-between'>
      {(meta || []).map((data, key) => (
        <Pill {...data} key={key} />
      ))}
    </div>
  </div>
);

export default StatCard;
