import { Route, Routes } from 'react-router-dom';
import { AuthLayout } from '../components/layouts';
import { Init2fa, Login, NotFound, Verify2fa } from '../pages';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='2fa/*'>
          <Route path='init' element={<Init2fa />} />
          <Route path='verify' element={<Verify2fa />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='login' element={<Login />} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AuthRoutes;
