import { Fragment } from 'react';
import { Cell } from 'react-table';
import { AppModuleDto } from '../../../types';
import Button from '../../Button';
import { BooleanStatusCell, RelativeTimeCell } from '../common';

export const AppModuleColums = (openStatusModal: (id: string)=>void, showViewMore: boolean) => {
  return [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Module Name',
    accessor: 'moduleName',
  },
  
  {
    Header: 'Status',
    accessor: 'isActive',
    className: 'text-center',
    Cell: BooleanStatusCell,
  },
  {
    Header: 'Activated Time',
    accessor: 'activatedAt',
    Cell: RelativeTimeCell
  },
  {
    Header: 'Deactivated Time',
    accessor: 'deactivatedAt',
    Cell: RelativeTimeCell
  },
  ...(showViewMore ? [
    {
      Header: ' ',
      accessor: 'moduleId',
      Cell: ({row, value}: Cell<AppModuleDto>)=>(<Button onClick={() => openStatusModal(value)} className="bg-[#FFDCDC] text-[#A72828]">{row.original.isActive? "Detactivate" : "Activate"}</Button>)
    }
  ]: [])
  
]};
