import {
    Dialog
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { getAdById } from "../../api";
import { AdsDto } from "../../types";

export default function AdsDetailsModal({open, closeModal, adId}: any) {
    const [ad, setAd] = useState<AdsDto|null>(null);
    const handleCloseModal = ()=>{
        closeModal();
    }

    useEffect(()=>{
        if(adId){
            getAdById(adId)
            .then(setAd)
            .catch(console.log)
        }
    }, [adId])
  return (
    <Fragment>
      <Dialog className="flex flex-col px-6 py-8 gap-1 w-full" open={open} handler={handleCloseModal} >
        <div className="flex gap-6">
            <div className="text-24 font-semibold">AD details</div>
            <div className="rounded-full bg-[#EBFFF0] px-7 py-3 text-[#2A9147] text-16 font-semibold">{ad?.status}</div>
            <div className="flex">
                <div className="py-3 px-7 rounded-full bg-[#F5F5F5]">
                    <p className="text-[#717171]">Ad ID: <span className="text-[#353535] text-14">{ad?.id}</span></p>
                </div>
                <div className="">close</div>
            </div>
        </div>

        <div className="flex mt-6 gap-10">
            <div className="text-14 font-medium">Created: <span className="text-14 font-semibold">{dayjs(ad?.createdAt).format('D, MMMM, YYYY')}</span></div>
            <div className="text-14 font-medium">Trades: <span className="text-14 font-semibold">{ad?.trades?.length}</span></div>
            <div className="text-14 font-medium">Completed: <span className="text-14 font-semibold">{ad?.trades.filter(trade => trade.status ==="COMPLETED").length}</span></div>
            <div className="text-14 font-medium">Pending: <span className="text-14 font-semibold">{ad?.trades.filter(trade => trade.status ==="PENDING").length}</span></div>
        
            <div className="text-14 font-medium">Cancelled: <span className="text-14 font-semibold">{ad?.trades.filter(trade => trade.status ==="CANCELLED").length}</span></div>
        </div>
        <div className="flex flex-col mt-8 py-6 w-full gap-4">
            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Type</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.orderType}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Asset</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.crypto}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Type</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.fiat}</div>
                </div>
            </div>

            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-1/2 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Trade limit</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.fiat} {Number(ad?.minOrderAmount).toFixed(2)} - {ad?.fiat} {Number(ad?.maxOrderAmount).toFixed(2)}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/2 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Buy Volume</div>
                    <div className="text-16 font-medium text-[#353535]">{Number(ad?.quantity).toFixed(4)} {ad?.crypto}</div>
                </div>
            </div>

            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-1/2 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Available Amount</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.fiat} {Number(ad?.availableQuantity).toFixed(2)}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/2 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Completed Amount</div>
                    <div className="text-16 font-medium text-[#353535]">{Number(ad?.quantity).toFixed(4)} {ad?.crypto}</div>
                </div>
            </div>
            
            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Merchant price</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.fiat} {Number(ad?.advertisedRate).toFixed(2)}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-2/3 rounded-xl border border-[#E9E9E9] gap-1">
                    <div className="text-14 text-[#5D5D5D]">Type</div>
                    <div className="flex text-16 font-medium text-[#353535] gap-4">
                        {ad?.paymentChannels.map(chan => (
                            <div className="px-3 py-2 text-14 font-medium text-[#353535] bg-[#FAFAFA]">
                                {chan.title}
                            </div>
                        ))}
                        
                    </div>
                </div>
            </div>

            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Counterparty conditions</div>
                    <div className="text-16 font-medium text-[#353535]">Buy Ad</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Payment time limit</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.paymentWindow}</div>
                </div>
                <div className="flex flex-col px-7 py-4 w-1/3 rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Visibility</div>
                    <div className="text-16 font-medium text-[#353535]">Buy Ad</div>
                </div>
            </div>

            <div className="flex w-full gap-2">
                <div className="flex flex-col px-7 py-4 w-full rounded-xl border border-[#E9E9E9]">
                    <div className="text-14 text-[#5D5D5D]">Merchant’s comment</div>
                    <div className="text-16 font-medium text-[#353535]">{ad?.tradeTerms}</div>
                </div>
            </div>
        </div>
      </Dialog>
    </Fragment>
  );
}