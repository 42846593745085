import React from 'react';
interface IProps extends React.ParamHTMLAttributes<HTMLParagraphElement> {
  error?: string;
}
const FormErrorText: React.FC<IProps> = ({ error, ...props }) => {
  return error ? (
    <p className='text-[0.8rem] text-red-500 font-tiny pt-1' {...props}>
      {error}
    </p>
  ) : null;
};

export default FormErrorText;
