import {useCallback, useEffect, useMemo, useState} from 'react';
import { getCurrentUser, init2faSetup, loginUser, verifyAuth2fa } from '../api';
import { authRoutes } from '../config';
import { useAuthStore } from '../store';

const useAuth = (forceAuth: boolean = false) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false)
  const { auth, user, loggedIn, setAuth, logout, setLoggedIn, setUser } = useAuthStore();
  const [totpQrcode, setTotpQrcode] = useState<string>('');

  const shouldSetup2fa = useMemo(() => !auth.initialized && auth.sub, [auth.initialized, auth.sub]);
  const shouldVerifyAuth = useMemo(
    () => auth.initialized && !auth.verified,
    [auth.initialized, auth.verified]
  );

  const login = async (token: string) => {
    try {
      setLoading(true);
      const data = await loginUser(token);
      setAuth(data);
    } catch (error) {
      // TODO: Toast error
    } finally {
      setLoading(false);
    }
  };

  const init2fa = async () => {
    try {
      setLoading(true);
      const data = await init2faSetup();
      setTotpQrcode(data.qrcodeUrl);
    } catch (error) {
      // TODO: Toast error
    } finally {
      setLoading(false);
    }
  };

  const verifyLogin = async (token: string) => {
    try {
      setLoading(true);
      const data = await verifyAuth2fa(token);
      setAuth(data);
      setLoggedIn(true);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getAuthUser = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingUser(true);
      const data = await getCurrentUser();
      setUser(data);
    } catch (error) {
      // TODO: Toast error
      logout();
      window.location.href = authRoutes.login;
    } finally {
      setLoading(false);
      setLoadingUser(false);
    }
  }, [logout, setUser]);

  const clearSession = () => {
    logout();
  };

  useEffect(() => {
    if(forceAuth) {
      setLoadingUser(true);
      getAuthUser().then(() => {});
    }
  }, [forceAuth, getAuthUser]);

  return {
    auth,
    user,
    login,
    init2fa,
    loading,
    loggedIn,
    totpQrcode,
    loadingUser,
    verifyLogin,
    getAuthUser,
    clearSession,
    shouldSetup2fa,
    shouldVerifyAuth,
  };
};

export default useAuth;
