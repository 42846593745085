import {
  Dialog, Textarea, Typography
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { Fragment, useMemo, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { updateUserStatus } from "../../api";
import { ActionType } from "../../constants";
import { tokenSchema } from "../../schema";
import { AccountUserDto } from "../../types";
import Button from "../Button";
import Input from "../Input";


interface IForm {
  token: string;
  comment: string;
}


type IActionType = typeof ActionType[number]
interface IModal{
  closeModal : ()=> void,
  open: boolean,
  userId?: string,
  actionType: IActionType| undefined,
  setUser: (user : AccountUserDto) => void
}

export default function UpdateUserStatusModal({open, closeModal, userId, actionType, setUser}: IModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const handleCloseModal = ()=>{
      closeModal();
  }
  const initialValues: IForm = {
    token: '',
    comment: ''
  };

  const status = useMemo(()=>
      actionType === 'ACTIVATE' ? 'ACTIVE' :'SUSPENDED'
  , [actionType])


  const formik = useFormik({
    initialValues:initialValues,
    validationSchema: tokenSchema,
    onSubmit: async (value)=>{
      
      setLoading(true)
      updateUserStatus(userId!, {...value, status}  )
      .then((data)=>{
        setUser(data);
        closeModal()
        toast.success("Request was successful");
      })
      .catch((error) => {
          const errors =error?.response?.data;
          if(Array.isArray(errors)){
              for (const key in errors) {
                formik.setFieldError(key, errors[key]);
              }
          }else{
            const message = error?.response?.data?.message || 'Verification failed. Please retry';
              toast.error(message);
              formik.setFieldError('token', message);
          }
          
          
      })
      .finally(()=>{
        setLoading(false)
        closeModal();
      })
    }
  });
  return (
    <Fragment>
      
      <Dialog className="flex flex-col px-6 py-8 items-center gap-1" open={open} handler={handleCloseModal} >
        <div className="text-24 font-medium">{actionType === "ACTIVATE"? "Activate account" : actionType === "DEACTIVATE"? "Suspend account" : ""} </div>   
        <div className="text-14 font-medium mb-4">Provide reason for {actionType === "ACTIVATE"? "activating" : actionType === "DEACTIVATE"? "Suspending" : ""} this account before you proceed</div>
        <form className="flex flex-col w-full" onSubmit={formik.handleSubmit}>
          <div className="w-full">
            <Typography
              variant="small"
              color="#5D5D5D"
              className="mb-2 font-normal text-14"
            >
              Reason
            </Typography>
            <Textarea 
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
            />
          </div>

          <div className="w-full">
            <Typography
              variant="small"
              color="#5D5D5D"
              className="mb-2 font-normal text-14"
            >
              Enter OTP from your Google authenticator
            </Typography>
            <Input  
              name="token"
              value={formik.values.token}
              error={formik.touched.token ? formik.errors.token : undefined}
              onChange={formik.handleChange}/>
          </div>
          
          <Button 
            className="bg-blue-gray-100 w-full mt-2"
            loading={loading}
            type='submit'
            disabled={!formik.dirty || !formik.isValid}>Confirm</Button>
        </form>
        
      </Dialog>
    </Fragment>
  );
}