import MainWrapper from "../../../components/MainWrapper";
import { ServiceTable } from "../../../components/pageComponents";
import SeviceMetricCards from "../../../components/pageComponents/setting/ServiceTable/Metrics";


const Service = () => {
  return (
    <MainWrapper title='Service'>
      <SeviceMetricCards />
      <ServiceTable />
    </MainWrapper>
  );
};

export default Service;