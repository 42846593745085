import { Dialog, DialogBody } from '@material-tailwind/react';
import React, { Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import { GrDocumentCsv } from 'react-icons/gr';
import { VscClose, VscListFilter, VscRefresh } from 'react-icons/vsc';
import { useAdminPermission } from '../../../../hooks';
import { IUserFilter } from '../../../../types';
import Button from '../../../Button';
import Loader from '../../../Loader';
import FilterFields, { IVerifiedSelect } from './FilterFields';

interface IProps {
  allowFull?: boolean;
  downloading?: boolean;
  handelDownload?: VoidFunction;
  updateQueryFilter?: React.Dispatch<SetStateAction<IUserFilter>>;
}

const UserFilter: React.FC<IProps> = ({ updateQueryFilter, handelDownload, downloading }) => {
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<IVerifiedSelect>('all');
  const [phoneVerified, setPhoneVerified] = useState<IVerifiedSelect>('all');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [dateRange, setDateRange] = useState<any>({ endDate: null, startDate: null });

  const { hasRequiredPermissions } = useAdminPermission();
  const enableDownload = hasRequiredPermissions([], 'ADMIN');

  const handleDateChange = (_date: any) => {
    setDateRange(_date);
  };

  const setBoolVerification = (_value: 'all' | 'verified' | 'unverified') => {
    return _value === 'all' ? undefined : _value === 'verified' ? 1 : 0;
  };

  const isEmailVerified = useMemo(() => setBoolVerification(emailVerified), [emailVerified]);
  const isPhoneVerified = useMemo(() => setBoolVerification(phoneVerified), [phoneVerified]);
    useEffect(() => {
    if (updateQueryFilter && !showMobileFilter) {
      updateQueryFilter((state) => ({
        ...state,
        isEmailVerified,
        isPhoneVerified,
        email,
        phone,
        fromDate: dateRange?.startDate ? new Date(dateRange?.startDate).toISOString() : undefined,
        toDate: dateRange?.endDate ? new Date(dateRange?.endDate).toISOString() : undefined,
      }));
    }
  }, [
    showMobileFilter,
    dateRange?.endDate,
    dateRange?.startDate,
    isEmailVerified,
    isPhoneVerified,
    email,
    phone,
    updateQueryFilter,
  ]);

  const resetFilter = () => {
    setEmailVerified('all');
    setPhoneVerified('all');
    setEmail('');
    setPhone('');
    setDateRange({ endDate: null, startDate: null });
  };

  const handleMobileFilter = () => setShowMobileFilter((state) => !state);

  return (
    <Fragment>
      <div className='w-full bg-white px-5 md:px-8 rounded-md p-4'>
        <div className='flex items-center gap-4 justify-end lg:justify-start'>
          <div className='hidden lg:flex flex-grow gap-8'>
            <FilterFields
              {...{
                dateRange,
                emailVerified,
                phoneVerified,
                email,
                phone,
                handleDateChange,
                setEmailVerified,
                setPhoneVerified,
                setEmail,
                setPhone,
              }}
            />
          </div>
          <div className='hidden lg:block'>
            <VscRefresh size={20} className='cursor-pointer' onClick={() => resetFilter()} />
          </div>
          <div className='lg:hidden'>
            <VscListFilter
              size={20}
              className='cursor-pointer'
              onClick={() => handleMobileFilter()}
            />
          </div>
          <div>
            {
            enableDownload ?
            downloading ? (
              <Loader width='20' />
            ) : (
              <GrDocumentCsv
                size='20'
                className='cursor-pointer'
                onClick={() => handelDownload && handelDownload()}
              />
            ) : ""}
          </div>
        </div>
      </div>
      <Dialog
        className='max-w-[600px!important] w-[90%]'
        open={showMobileFilter}
        handler={handleMobileFilter}
        dismiss={{
          enabled: true,
        }}>
        <DialogBody className='w-full grid grid-cols-2 gap-y-5 gap-x-4 py-8'>
          <div className='col-span-2 flex justify-end items-center'>
            <VscClose size={20} className='cursor-pointer' onClick={() => handleMobileFilter()} />
          </div>
          <FilterFields
            {...{
              dateRange,
              emailVerified,
              phoneVerified,
              email,
              phone,
              handleDateChange,
              setEmailVerified,
              setPhoneVerified,
              setEmail,
              setPhone,
            }}
          />
          <div className='col-span-2 flex justify-end items-center gap-x-10'>
            <VscRefresh size={20} className='cursor-pointer' onClick={() => resetFilter()} />
            <Button size='sm' onClick={() => handleMobileFilter()}>
              Search
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export default UserFilter;
