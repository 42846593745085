import MainWrapper from "../../components/MainWrapper";
import { KycList } from "../../components/pageComponents";

const Kyc = () => {
    return (
      <MainWrapper title='Manual KYC Verification'>
        <KycList />
      </MainWrapper>
    );
  };
  
  export default Kyc;