import { useAdminPermission } from '../../../../hooks';
import { TransactionsTable } from '../../../tables';

const TransactionsList = () => {
    const { hasRequiredPermissions } = useAdminPermission();
    const canViewMore = hasRequiredPermissions([], 'SUPPORT');
  
    return (
      <div className='mt-8'>
        <TransactionsTable limit={20} enableFilter showPagination enableViewMore={canViewMore}/>
      </div>
      
    );
  };
  
export default TransactionsList;

