import React from 'react';
type IPill = React.FC<{
  count: number;
  title: string;
}>;

const Pill: IPill = ({ count, title }) => (
  <p className='text-11 font-bold flex gap-2'>
    <span>{count}</span>
    <span className='text-gray-500'>{title} </span>
  </p>
);

export default Pill;
