
import { AuditTrailDto, IAuditFilter, IPaginatedDto } from '../types';
import AxiosInstance from './config';
export const getAuditTrail = async (params: IAuditFilter): Promise<IPaginatedDto<AuditTrailDto>> => {
    const { data } = await AxiosInstance.get<IPaginatedDto<AuditTrailDto>>('/audit', { params });
    return data;
};

export const getAuditTrailById = async (id: string): Promise<AuditTrailDto> => {
    const { data } = await AxiosInstance.get<AuditTrailDto>(`/audit/${id}`);
    return data;
};