import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getTrades } from '../../../api';
import { IPaginationMeta } from '../../../types';
import { ITradeFilter, P2pTradeDto } from '../../../types/P2pTradeDto';
import Table from '../../Table';
import { tradeColumns } from './config';
import TradeFilter from './TradeFilter';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
  viewMore: boolean
}

const TradesTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination, viewMore }) => {
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [filterQuery, setFilterQuery] = useState<ITradeFilter>({});
  const [trades, setTrades] = useState<P2pTradeDto[]>([]);

  const columns = useMemo(() => tradeColumns(viewMore, showFull), [showFull, viewMore]);

  const filter: ITradeFilter = useMemo(
    () => ({ limit, page, ...filterQuery }),
    [filterQuery, limit, page]
  );
  

  const { isFetching } = useQuery({
    queryKey: ['projects', filter],
    queryFn: () => getTrades(filter),
    onSuccess: ({ data, meta }) => {
      setMeta(meta);
      setTrades(data);
    },
  });


  return (
    <div>
      {enableFilter && (
        <TradeFilter
          updateQueryFilter={setFilterQuery}
        />
      )}
      <Table
        columns={columns}
        data={trades}
        setTablePage={setPage}
        loading={isFetching}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default TradesTable;
