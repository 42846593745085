import { PaymentChannelTable } from '../../../tables';


const PaymentChannelList = () => {

  return (
    <PaymentChannelTable />
  );
};

export default PaymentChannelList;