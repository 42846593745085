import React from 'react';
import { useAuth } from '../../../hooks';
import { useMenu } from '../../../providers';
import { FaSignOutAlt } from 'react-icons/fa';
import { VscAccount, VscMenu, VscChevronDown } from 'react-icons/vsc';
import { Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';

type IHeader = React.FC<{
  pageHeading?: string;
}>;

const Header: IHeader = ({ pageHeading }) => {
  const { clearSession } = useAuth();
  const { showSideNav, setShowSideNav } = useMenu();

  return (
    <header className='bg-white shadow px-3 sm:px-4 md:px-8 py-4 sticky top-0'>
      <nav className='flex justify-between items-center'>
        <div className='flex items-center justify-start gap-8'>
          <VscMenu
            size={30}
            className='cursor-pointer'
            onClick={() => setShowSideNav(!showSideNav)}
          />
          {pageHeading && <p className='font-bold text-24 capitalixe'>{pageHeading}</p>}
        </div>
        <Menu placement='bottom-end'>
          <MenuHandler>
            <div className='flex items-center justify-end gap-2 cursor-pointer'>
              <VscAccount size={30} />
              <VscChevronDown />
            </div>
          </MenuHandler>
          <MenuList className='min-w-[250px] px-8'>
            <MenuItem className='flex items-center gap-3' onClick={() => clearSession()}>
              <FaSignOutAlt /> Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </nav>
    </header>
  );
};

export default Header;
