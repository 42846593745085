import React, { Fragment, SetStateAction } from 'react';
import { useTable } from 'react-table';
import cn from 'classnames';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { IconButton } from '@material-tailwind/react';
import { IPaginationMeta } from '../../types';
import LoadingCard from "../LoadingCard";

type ITable = React.FC<{
  setTablePage?: React.Dispatch<SetStateAction<number>>;
  pagination?: IPaginationMeta;
  loading?: boolean;
  columns: any[];
  data: any;
}>;

const Table: ITable = ({ columns, data, loading, pagination, setTablePage }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleNavigate = (page: number) => {
    page !== pagination?.page && setTablePage && setTablePage(page);
  };

  // Render the UI for your table
  return (
    <Fragment>
      {/* table */}
      <div className='mt-4 flex flex-col bg-white px-5 md:px-8 py-8 rounded-md shadow'>
        <div className='-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='overflow-hidden border-b border-gray-200'>
              <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope='col'
                          className='group px-6 py-3 text-left text-xs uppercase tracking-wider text-12'
                          {...column.getHeaderProps()}>
                          <div className={cn([(column as any).className])}>
                            {column.render('Header')}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={columns.length}>
                        <LoadingCard />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody
                    {...getTableBodyProps()}
                    className='bg-white divide-y divide-gray-200 text-12'>
                    {rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className='px-6 py-4 whitespace-nowrap'
                                role='cell'>
                                {(cell.column.Cell as any)?.name === 'defaultRenderer' ? (
                                  <div>{cell.render('Cell')}</div>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      {pagination && (
        <nav className='py-6'>
          <div className='flex-1 flex justify-between md:hidden'>
            <IconButton
              size='sm'
              color='blue-gray'
              disabled={!pagination.hasPreviousPage}
              onClick={() => handleNavigate(pagination.page - 1)}>
              <FiChevronLeft />
            </IconButton>
            <IconButton
              size='sm'
              color='blue-gray'
              disabled={!pagination.hasNextPage}
              onClick={() => handleNavigate(pagination.page + 1)}>
              <FiChevronRight />
            </IconButton>
          </div>
          <div className='hidden md:flex items-center justify-between'>
            <div className='flex items-center justify-start gap-8'>
              <IconButton
                size='sm'
                color='blue-gray'
                disabled={!(pagination.page > 1 && pagination.pages > 1)}
                onClick={() => handleNavigate(1)}>
                <FiChevronsLeft />
              </IconButton>
              <IconButton
                size='sm'
                color='blue-gray'
                disabled={!pagination.hasPreviousPage}
                onClick={() => handleNavigate(pagination.page - 1)}>
                <FiChevronLeft />
              </IconButton>
            </div>
            <div className='flex items-center justify-end gap-8'>
              <IconButton
                size='sm'
                color='blue-gray'
                disabled={!pagination.hasNextPage}
                onClick={() => handleNavigate(pagination.page + 1)}>
                <FiChevronRight />
              </IconButton>
              <IconButton
                size='sm'
                color='blue-gray'
                disabled={!(pagination.page < pagination.pages)}
                onClick={() => handleNavigate(pagination.pages)}>
                <FiChevronsRight />
              </IconButton>
            </div>
          </div>
        </nav>
      )}
    </Fragment>
  );
};

export default Table;
