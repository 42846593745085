import { Option, Select } from '@material-tailwind/react';
import React, { Fragment, SetStateAction } from 'react';
import { KycStatuses } from '../../../../constants';
import { IKycStatus } from '../../../../types';

export type IKycStatusSelect = 'all' | IKycStatus;
export type All = 'all';

interface IProps {
  status: IKycStatusSelect;
  setStatus: React.Dispatch<SetStateAction<IKycStatusSelect>>;
}

const KycFilterFields: React.FC<IProps> = (props) => {
  const labelClassName = 'text-10 text-gray-500 font-semibold mb-2';
  return (
    <Fragment>
      
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Kyc status</div>
        <Select
          variant='outlined'
          value={props.status}
          onChange={(value) => props.setStatus(value as any)}>
          {['all', ...KycStatuses].map((val, i) => 
            <Option key={i} value={val}>{val === 'all' ? 'All Status' : val}</Option>
          )}
        </Select>
      </div>
    </Fragment>
  );
};

export default KycFilterFields;
