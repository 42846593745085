import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useMenu } from '../../../../../providers';

export interface INavItemProps {
  route?: string;
  NavIcon: IconType;
  text: string;
}

const NavItem: React.FC<INavItemProps> = ({ route, NavIcon, text }) => {
  const { setShowSideNav } = useMenu();
  const { pathname } = useLocation();
  const isActive = useMemo(() => pathname === route, [pathname, route]);
  return (
    <Link to={route ?? '#'}>
      <div
        onClick={() => setShowSideNav(false)}
        className={cn([
          'flex items-center justify-start gap-3 font-bold text-14',
          {
            'text-gray-700': !isActive,
            'text-blue-500': isActive,
          },
        ])}>
        <NavIcon size='20' />
        <span>{text}</span>
      </div>
    </Link>
  );
};

export default NavItem;
