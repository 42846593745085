import { useCallback, useMemo } from 'react';
import { AdminPermission, AdminType, AdminTypePoint } from '../types';
import useAuth from './useAuth';

const useAdminPermission = () => {
  const { user } = useAuth();

  const permissions = useMemo(() => user?.permissions || [], [user?.permissions]);
  const rolePoint = useMemo(() => (user?.type ? AdminTypePoint[user.type!] : 0), [user.type]);

  const hasRequiredPermissions = useCallback(
    (requiredPermissions: AdminPermission[] = [], userType: AdminType = 'MEMBER'): boolean => {
      return (
        rolePoint >= AdminTypePoint[userType] && (
            (['SUPER', 'ADMIN'].includes(user?.type || '') && permissions.includes('ALL'))
            || requiredPermissions.every(p => permissions.includes(p))
        )
      );
    },
    [permissions, rolePoint, user.type]
  );

  return {
    hasRequiredPermissions,
  };
};

export default useAdminPermission;
