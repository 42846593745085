import React, { Fragment } from 'react';
import { withPluralSuffix } from '../../../../helpers';
import { useSys } from '../../../../hooks';
import StatCard from './StatCard';

const StatCards: React.FC<unknown> = () => {
  const { stats } = useSys();

  return (
    <section className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-y-2 lg:gap-x-4'>
      {stats && (
        <Fragment>
          <StatCard
            title='Registred users'
            count={stats.users}
            meta={[
              { count: stats.usersWithVerifiedEmail, title: 'Email verified' },
              { count: stats.usersWithVerifiedPhone, title: 'Phone verified' },
            ]}
          />
          <StatCard
            title='Sent bitcodes'
            count={stats.bitcodes}
            meta={[
              {
                count: stats.bitcodeInvoices,
                title: withPluralSuffix('Invoice', 's', stats.bitcodeInvoices),
              },
              {
                count: stats.bitcodeVouchers,
                title: withPluralSuffix('Voucher', 's', stats.bitcodeVouchers),
              },
            ]}
          />
          <StatCard
            title='Wallet transactions'
            count={stats.transactions}
            meta={[
              {
                count: stats.depositTransactions,
                title: withPluralSuffix('Deposit', 's', stats.depositTransactions),
              },
              {
                count: stats.withdrawalTransaction,
                title: withPluralSuffix('Withdrawal', 's', stats.withdrawalTransaction),
              },
            ]}
          />
          <StatCard
            title='P2P trades'
            count={stats.p2pTrades}
            meta={[
              {
                count: stats.p2pBuyTrades,
                title: withPluralSuffix('Buy trade', 's', stats.p2pBuyTrades),
              },
              {
                count: stats.p2pSellTrades,
                title: withPluralSuffix('Sell trade', 's', stats.p2pSellTrades),
              },
            ]}
          />
        </Fragment>
      )}
    </section>
  );
};

export default StatCards;
