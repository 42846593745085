import React, { useEffect, useMemo, useState } from 'react';
import { getAdminUsers } from '../../../api';
import { AdminUserDto, IPaginationMeta } from '../../../types';
import Table from '../../Table';
import { adminUserColumns } from './config';

interface IProps {
  enableFilter?: boolean;
  showFull?: boolean;
  showPagination?: boolean;
  limit?: number;
}

const AdminUsersTable: React.FC<IProps> = ({ showFull, limit, enableFilter, showPagination }) => {
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IPaginationMeta | undefined>();
  const [users, setUsers] = useState<AdminUserDto[]>([]);

  const columns = useMemo(() => adminUserColumns(showFull), [showFull]);



  useEffect(()=>{
    getAdminUsers()
    .then(({data, meta})=>{
        setUsers(data)
        setMeta(meta)
    })
    .catch(console.log)
    .finally(()=>{setLoading(false)})
  }, [page])

  return (
    <div>
     
      <Table
        columns={columns}
        data={users}
        setTablePage={setPage}
        loading={loading}
        {...(showPagination && { pagination: meta })}
      />
    </div>
  );
};

export default AdminUsersTable;
