import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../middlewares";
import { Kyc, KycDetails, NotFound } from "../pages";


const KycRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthGuard userType='SUPPORT' />}>
          <Route index element={<Kyc />} />
        </Route>
        <Route element={<AuthGuard userType='SUPPORT' permissions={['MANAGE_USER_KYC']} />}>
          <Route path=':id' element={<KycDetails />} />
        </Route>
        <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default KycRoutes;
