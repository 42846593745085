import React, { Fragment, PropsWithChildren } from 'react';
import { useAuth } from '../../hooks';
import Header from './Header';
import {Helmet} from "react-helmet-async";

type IMainWrapper = React.FC<
  PropsWithChildren<{
    title: string;
  }>
>;

const MainWrapper: IMainWrapper = ({ title, children }) => {
  const { loadingUser } = useAuth(true);
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Helmet><title>{`${title} | BitBarter Admin Portal`}</title></Helmet>
      {!loadingUser ? (
        <Fragment>
          <Header pageHeading={title} />
          <section className='max-w-[1280px] mx-auto px-3 sm:px-4 md:px-8 py-4 md:py-12'>
            {children}
          </section>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default MainWrapper;
