import React, { Fragment, PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

type IAuthWrapper = React.FC<PropsWithChildren<{
  title?: string | ReactNode;
  siteTitle?: string;
}>>;

const AuthWrapper: IAuthWrapper = ({ title, siteTitle, children }) => (
  <Fragment>
    {/*  @ts-ignore */}
    <Helmet>{siteTitle && <title>{siteTitle}</title>}</Helmet>
    <div className='p-4 md:p-8 lg:p-16 xl:p-24'>
      {title && <h2 className='title-sm mb-4'>{title}</h2>}
      <div className='my-8'>{children}</div>
    </div>
  </Fragment>
);

export default AuthWrapper;
