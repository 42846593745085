import MainWrapper from '../../components/MainWrapper';
import { AuditList } from '../../components/pageComponents';

const AuditTrail = () => {
  return (
    <MainWrapper title='Admin Audit Trail'>
      <AuditList />
    </MainWrapper>
  );
};

export default AuditTrail;
