
interface ICardProps {
    title: string
    count: number
    period: string
}
interface IProps {
    reviewedKycCount: number 
    rejectedKycCount : number
    approvedKycCount : number
}
const KycCard: React.FC<ICardProps>  = ({title, count, period}: ICardProps)=>{
    return (
        <div className="flex flex-1 py-7 bg-[#F5F5F5] rounded-[4px] justify-center">
            <div className="flex flex-col items-center">
                <div className="flex text-24">KYC - <span className="font-medium">{title}</span></div>
                <div className="flex pt-4 font-bold text-32">{count}</div>
                {/* <div className="flex text-16">+ 24</div>
                <div className="flex pt-4 text-16 text-[#999999]">vs {period}</div> */}
            </div>
        </div>
    );
}
const KycMetricCards: React.FC<IProps> = ({reviewedKycCount, rejectedKycCount, approvedKycCount}:IProps) => {
    

    return (
      <div className='flex gap-3 mb-4'>
        <KycCard title="Review Required" count={reviewedKycCount} period='previous 30 days'/>
        <KycCard title="Rejected" count={rejectedKycCount} period='previous 30 days'/>
        <KycCard title="Verified" count={approvedKycCount} period='previous 30 days'/>
        
      </div>
    );
  };
  
  export default KycMetricCards;