import { useEffect, useState } from 'react';

interface IWindowDimensions {
  windowWidth: number;
  windowHeight: number;
}
const getWindowDimensions = (): IWindowDimensions => {
  const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
  return { windowHeight, windowWidth };
};

const useWindowSizes = (): IWindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowSizes;
