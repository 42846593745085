import {
  Dialog, Textarea, Typography
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { updateIdentityStatus } from "../../api";
import { IActionType } from "../../types";
import Button from "../Button";

interface IModal{
  closeModal : ()=> void;
  open: boolean;
  userId?: string;
  actionType?: IActionType;
}

export default function UpdateKycStatusModal({open, closeModal, userId, actionType}: IModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string | undefined>();

  const handleCloseModal = ()=>{
    closeModal();
  }
  const handleReasonChange = (e:any)=> {
    setReason(e.target.value)
  }

  const updateStatus = ()=> {
    const status = actionType === 'Approve' ? 'VERIFIED' : 'REJECTED';
    setLoading(true)
    updateIdentityStatus(userId!, {status, rejectionReason: reason })
    .then(()=> {
        // TODO: Update state from response
      toast.success('Request Successful');
    })
    .catch((error)=>{
      const message = error?.response?.data?.message || 'Update failed. Please retry';
      toast.error(message);
    })
    .finally(()=>{
        setLoading(false)
        closeModal();
    })
  }

  const actionName = actionType === 'Approve' ? 'approve' : 'reject';

  return (
    <Fragment>

      <Dialog className="flex flex-col px-6 py-8 items-center gap-1" open={open} handler={handleCloseModal} >


        <div className="flex flex-col">
            <div className="text-24 font-semibold text-center px-20">{`Do you want to ${actionName} this user?`}</div>
            {actionType === 'Reject' &&
            <div className="w-full px-8">
              <Typography
              variant="small"
              color="#5D5D5D"
              className="mb-2 font-normal text-14"
              >
              Reason
              </Typography>
              <Textarea
                  value={reason}
                  onChange={handleReasonChange}
              />
          </div>}
            <div className="flex px-8 justify-between w-full gap-4 mt-10">
                <Button onClick={closeModal} className="w-full bg-white text-black border-2 border-black shadow-none">No, Cancel</Button>
                <Button onClick={updateStatus} loading={loading} className="w-full bg-[#8D6ABD] border-2 text-white">{`Yes ${actionName}`}</Button>
            </div>
        </div>
      </Dialog>
    </Fragment>
  );
}