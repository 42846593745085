import { PaymentChannelDto } from "../../../../../types";
import StatCard from "../../../dashboard/StatCards/StatCard";

interface IProps {
    PaymentChannels?: PaymentChannelDto[]
}
const PaymentConfigMetricCards: React.FC<IProps> = ({PaymentChannels}) => {
    

    return (
      <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-y-2 lg:gap-x-4'>
        {
         PaymentChannels &&
         <StatCard
            title='Payment Channel'
            count={PaymentChannels?.length ?? 0}
            meta={[
              { count: PaymentChannels?.filter(x => x.isActive === true).length ?? 0, title: 'Active' },
              { count: PaymentChannels?.filter(x => x.isActive === false).length ?? 0, title: 'Inactive' },
            ]}
          />
        }
      </div>
    );
  };
  
  export default PaymentConfigMetricCards;