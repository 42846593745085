import dayjs from 'dayjs';

const exportDataToCsv = (data: Object[], file_name: string = 'download') => {
  const timestamp = dayjs().format('YYYY-MM-DD-HH-mm-ss');
  
  if (!data.length) return;
  
  const refinedData = [];
  refinedData.push(Object.keys(data[0]).map((_key) => String(_key).toUpperCase()));
  
  for (const row of data) {
    refinedData.push(Object.values(row));
  }

  let content = '';

  for( const row of refinedData) {
    content += row.join(',')  + '\n';
  }

  const csvBlob = new Blob([content], { type: 'text/csv;charset=utf-8,' })
  const csvUrl = URL.createObjectURL(csvBlob);

  const link = document.createElement('a')
  link.setAttribute('href', csvUrl)
  link.setAttribute('download', `${file_name}-${timestamp}.csv`);
  document.body.appendChild(link);
  link.click()
};

export default exportDataToCsv;
