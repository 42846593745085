import { useEffect, useState } from "react";

type PeriodOfDay = 'morning' | 'afternoon' | 'evening';

const useClock = () => {
  
  const [date, setDate] = useState<Date>(new Date())
  const [periodOfDay, setPeriodOfDay] = useState<PeriodOfDay | undefined>();

  setTimeout(() => {
    setDate(new Date());
  }, 1000);

  useEffect(() => {
    const getPeriodOfDay = (): PeriodOfDay  => {
      const hour = date.getHours();
      if(hour >= 5 && hour < 12) return 'morning';
      else if( hour >= 12 && hour < 17) return 'afternoon';
      else return 'evening'
    }
    setPeriodOfDay(getPeriodOfDay())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return {
    periodOfDay
  }
}

export default useClock;