import { useEffect, useMemo, useState } from "react";
import { VscAdd } from "react-icons/vsc";
import { getPaymentChannels } from "../../../api";
import { useAdminPermission } from "../../../hooks";
import { PaymentChannelDto } from "../../../types";
import Button from "../../Button";
import { AddPaymentChannelModal, UpdatePaymentChannelStatusModal } from "../../Modals";
import PaymentConfigMetricCards from "../../pageComponents/setting/PaymentChannelList/Metrics";
import Table from "../../Table";
import { paymentChannelColumns } from "./config";

const PaymentChannelTable: React.FC<unknown> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [paymentChannels, setPaymentChannels] = useState<PaymentChannelDto[]>([]);
    const [paymentChannelId, setPaymentChannelId] = useState<number>();
    const columns = useMemo(() => paymentChannelColumns, []);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const { hasRequiredPermissions } = useAdminPermission();
    const canActivate = hasRequiredPermissions(['MANAGE_PAYMENT_METHODS'], 'ADMIN');
    useEffect(() => {
      setLoading(true);
      getPaymentChannels()
        .then(( data ) => setPaymentChannels(data))
        .catch(console.log)
        .finally(() => setLoading(false));
    }, []);
    
    const handleStatusChanged = (paymentChannel: PaymentChannelDto) => {
      const index = paymentChannels.findIndex(x => x.id === paymentChannel.id);
      if(index !== -1){
        paymentChannels[index] = paymentChannel;
        setPaymentChannels(paymentChannels);
      }
    }

    const closeModal = () => setOpenForm(false);
    const closeStatusModal = () => setOpenStatus(false);

    const openStatusModal = (channelId:number) => {
      setPaymentChannelId(channelId)
      setOpenStatus(true);
    }

    
    return (
      <div>
        
        
        <div className="flex flex-col mt-8">
          <PaymentConfigMetricCards PaymentChannels={paymentChannels}/>
          {canActivate && 
          <div className="flex items-center gap-14 mb-7 justify-end">
            <Button
             onClick={() => setOpenForm(true)}
             className="flex p-3 gap-1 items-center bg-[#E59330] rounded">
              <VscAdd color="white"/>
              <p className="text-14 text-white font-medium">Add new payment channel</p>
            </Button>
          </div>
          }
          <div className="mb-2 bg-white px-11 py-6 rounded shadow items-center text-16 font-semibold">
            Payment channels
          </div>
        </div>
        <Table columns={columns(openStatusModal, canActivate)} data={paymentChannels} loading={loading} />
        <AddPaymentChannelModal open={openForm} closeModal={closeModal}/>
        <UpdatePaymentChannelStatusModal open={openStatus} closeModal={closeStatusModal} channelId={paymentChannelId} setData={handleStatusChanged} />
        
      </div>
    );
  };
  
  export default PaymentChannelTable;