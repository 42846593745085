import {
  Dialog, Typography
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { updatePaymentChannel } from "../../api";
import { tokenSchema } from "../../schema";
import { PaymentChannelDto } from "../../types";
import Button from "../Button";
import Input from "../Input";
  
  
  
  
  interface IModal{
    closeModal : ()=> void,
    open: boolean,
    channelId: number|undefined,
    setData: (paymentChannel : PaymentChannelDto) => void
  }
  interface IForm {
    token: string;
  }
  export default function UpdatePaymentChannelStatusModal({open, closeModal, channelId, setData}: IModal) {
      const [loading, setLoading] = useState<boolean>(false);
  
      const handleCloseModal = ()=>{
        closeModal();
      }
      
  
      const toggleStatus = (value: IForm)=> {
        setLoading(true)
        updatePaymentChannel(channelId!, value)
        .then((data)=> {
          setData(data);
            closeModal();
            toast.success("Update successful");
            
        })
        .catch((error)=>{
          const message = error?.response?.data?.message || 'Update failed. Please retry';
          toast.error(message);
        })
        .finally(()=>{
            setLoading(false)
        })
      }

      const initialValues: IForm = {
        token: ''
      };
      const formik = useFormik({
        initialValues:initialValues,
        validationSchema: tokenSchema,
        onSubmit: async (value)=>{
          toggleStatus(value)
        }
      });
      
    return (
      <Fragment>
        
        <Dialog className="flex flex-col px-6 py-8 items-center gap-1" open={open} handler={handleCloseModal} >
            
          <div className="flex flex-col">
            <div className="text-24 font-semibold text-center px-20">Are you sure you want to deactivate this payment channel?</div>
            <form className="flex flex-col pt-4 w-full" onSubmit={formik.handleSubmit}>
                  
              <div className="w-full">
                <Typography
                  variant="small"
                  color="trueGray-600"
                  className="mb-2 font-normal text-14"
                >
                  Enter OTP from your Google authenticator
                </Typography>
                <Input  
                  name="token"
                  value={formik.values.token}
                  error={formik.touched.token ? formik.errors.token : undefined}
                  onChange={formik.handleChange}/>
              </div>
              
              <div className="flex justify-between w-full gap-4 mt-4">
                <Button onClick={closeModal} className="w-full bg-white text-black border-2 border-black shadow-none">No</Button>
                <Button disabled={!formik.dirty || !formik.isValid} loading={loading} type='submit' className="w-full bg-[#FFF0F0] border-2 text-[#A72828] border-[#CF5050]">Yes</Button>
              </div>
            </form>
            
          </div>
          
        </Dialog>
      </Fragment>
    );
  }