import MainWrapper from "../../../components/MainWrapper";
import { DisputeStatCards, TradeDisputeList } from "../../../components/pageComponents";
import { useAdminPermission } from "../../../hooks";

const TradeDispute = () => {
  const { hasRequiredPermissions } = useAdminPermission();
  const canViewMetric= hasRequiredPermissions([], 'ADMIN');
    return (
      <MainWrapper title='Manager Dispute'>
        {canViewMetric && <DisputeStatCards />}
        <TradeDisputeList />
      </MainWrapper>
    );
  };
  
  export default TradeDispute;