import { useAdminPermission } from '../../../../hooks';
import { UsersTable } from '../../../tables';

const UsersList = () => {
  const { hasRequiredPermissions } = useAdminPermission();

  const showFull = hasRequiredPermissions(['VIEW_USERS'], 'MANAGER');

  return (
    <div>
      <UsersTable limit={20} showFull={showFull} enableFilter showPagination enableViewMore={showFull} />
    </div>
  );
};

export default UsersList;
