import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { RelativeTimeCell, StatusCell } from "../common";

export const walletColumns = () => [
    {
      Header: '#',
      Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
    },
    {
      Header: 'Daily Limit',
      accessor: 'dailyLimit',
    },
    {
      Header: 'Daily Trade Limit',
      accessor: 'dailyTradeLimit',
    },
    {
        Header: 'Total Balance',
        accessor: 'totalBalance',
    },
    {
      Header: 'Display Currency',
      accessor: 'displayCurrency',
    },
    {
      Header: 'Status',
      accessor: 'status',
      className: 'text-center',
      Cell: StatusCell,
    },
    {
      Header: 'Last Debit At',
      accessor: 'lastDebitAt',
      Cell: RelativeTimeCell,
    },
    {
      accessor: 'id',
      Cell: ({value}: Cell)=> { return <Link to={value}>view more</Link>},
    },
  ];