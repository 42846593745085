import { useRateStore } from "../store";

interface IProps {
    asset: string;
    fiat: string;
    qty: number;
}

const useRate = () => {
    const { rate } = useRateStore();


    const getFiatValue = ({ asset, fiat, qty }: IProps) => {
        const key = `${asset}_${fiat}`;
        const rateKey = rate[key] ? rate[key] : 1;
        const price = +rateKey * qty;
        return Number(price).toFixed(2);
    }
    return { getFiatValue }
}
export default useRate;
