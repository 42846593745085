export type AdminStatus = 'ENABLED' | 'DISABLED';
export const AdminRoles = [
    'SUPER',
    'ADMIN',
    'MANAGER',
    'SUPPORT',
    'MEMBER'
] as const;

export type AdminType = typeof AdminRoles[number];

export const AdminPermissions = [
    'ALL',
    'BASIC',
    'VIEW_USERS',
    'VIEW_MERCHANTS',
    'VIEW_ADMIN_USER',
    'VIEW_CUSTOMERS',
    'MANAGE_USERS',
    'MANAGE_USER_KYC',
    'MANAGE_ADMINS',
    'MANAGE_API_ACCESS',
    'MANAGE_PAYMENT_METHODS',
    'REVIEW_DISPUTE',
    'UPGRADE_TO_MERCHANT',
    'SUSPEND_ACCOUNT'
];

export type AdminPermission = typeof AdminPermissions[number];

export enum AdminTypePoint { MEMBER = 0, SUPPORT, MANAGER, ADMIN, SUPER }

export interface AdminUserDto {
    id: string;

    type?: AdminType;

    firstName: string;

    lastName: string;

    googleId: string;

    email: string;

    phone: string;

    isTotpVerified?: boolean;

    permissions: AdminPermission[];

    status?: AdminStatus;

    createdAt?: Date;

    updatedAt?: Date;
}

export interface AdminMetricObj {
    type: AdminType;
    status: AdminStatus;
    totalUsers: number
}