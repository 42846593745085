export const DisputeResolver = ['ADMIN', 'OWNER'] as const;

export const DisputeLogStatus = ['OPEN', 'CLOSED'] as const;

export const DisputeResponseStatus = ['OPEN', 'PROCESSING', 'CLOSED'] as const;

export const DisputeActions = ['CANCEL', 'CONFIRM', 'RESOLVED'] as const;

export const TradeType =['ONRAMP','OFFRAMP'] as const;

export const TradeStatus = ['PAID', 'PENDING', 'COMPLETED', 'CANCELLED'] as const;
export const OrderType = ['BUY', 'SELL'] as const;
