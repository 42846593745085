import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import {PillVariant} from "../../types";

interface IProps {
  variant?: PillVariant;
  text?: string;
  style?: cn.ArgumentArray
}

const Pill: React.FC<PropsWithChildren<IProps>> = ({ variant = 'primary', text, style, children }) => {
  return (
    <span
      className={cn([
        'text-10 py-1 px-4 font-semibold rounded-md',
        {
          'bg-gray-500 text-black': variant === 'primary',
          'text-white': variant !== 'primary',
          'bg-green-500': variant === 'success',
          'bg-red-500': variant === 'danger',
          'bg-yellow-500': variant === 'warning',
        },
          ...(style || [])
      ])}>
      {children ?? text}
    </span>
  );
};

export default Pill;
