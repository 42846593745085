import React from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { authRoutes, googleClientId } from './config';
import { useAuth } from './hooks';
import AppRoot from './routes';
import { WebSocketProvider } from './providers';

function App() {
  const { clearSession } = useAuth();

  window.addEventListener(
    'ClearAuth',
    () => {
      clearSession();
      window.location.href = authRoutes.login;
    },
    false
  );

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
        <BrowserRouter>
          <WebSocketProvider>
            <AppRoot />
          </WebSocketProvider>
        </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
