import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '../middlewares';
import { AuditTrail, NotFound } from '../pages';

const AuditRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
          <Route index element={<AuditTrail />} />
        </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AuditRoutes;
