import React, { Fragment, useEffect, useState } from "react";
import { getAdminUserMetrics } from "../../../../../api";
import { AdminMetricObj } from "../../../../../types";
  


const AdminUserMetricCards: React.FC<unknown> = () => {

    const [loading, setLoading] = useState<boolean>(true)
    const [adminMetric, setAdminMetric] = useState<AdminMetricObj[]>([]);


    useEffect(()=>{
        getAdminUserMetrics()
        .then((data)=> {
            setAdminMetric(data);
            setLoading(false);
        })
        .catch(console.log)
        .finally(()=>{
            setLoading(false);
        })

    },[])


    return (
      <section className='flex gap-3'>
        {
         !loading &&
         
        <Fragment>
            <div className='bg-white rounded shadow px-6 py-4'>
                <p className='text-14 font-bold pb-1'>Total number of Admins: <span className="inline font-bold">{adminMetric?.reduce((total, obj)=> Number(total) + Number(obj.totalUsers), 0)}</span></p>
                {adminMetric.map((data, i)=> (
                    <p key={i} className='text-12 pb-1'>{data.type}: <span className="inline font-bold">{data.totalUsers}</span></p>
                ))}
            </div>

            <div className='bg-white rounded shadow px-6 py-4'>
                <p className='text-12 pb-1'>Active Admin: <span className="inline font-bold">{adminMetric?.filter((data) => data.status === 'ENABLED' ).reduce((total, obj)=> Number(total) + Number(obj.totalUsers), 0)}</span></p>
                <p className='text-12 pb-1'>Deactivated Admin: <span className="inline font-bold">{adminMetric?.filter((data) => data.status === 'DISABLED' ).reduce((total, obj)=> Number(total) + Number(obj.totalUsers), 0)}</span></p>
            </div>
        </Fragment>
        }
      </section>
    );
  };
  
  export default AdminUserMetricCards;