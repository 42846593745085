import { Dialog, DialogBody } from '@material-tailwind/react';
import React, { Fragment, SetStateAction, useEffect, useState } from 'react';
import { GrDocumentCsv } from 'react-icons/gr';
import { VscClose, VscListFilter, VscRefresh } from 'react-icons/vsc';
import { ITradeFilter } from '../../../../types';
import Button from '../../../Button';
import Loader from '../../../Loader';
import AuditFilterFields from './AuditFilterFields';

interface IProps {
  allowFull?: boolean;
  downloading?: boolean;
  handelDownload?: VoidFunction;
  updateQueryFilter?: React.Dispatch<SetStateAction<ITradeFilter>>;
}

const AuditFilter: React.FC<IProps> = ({ updateQueryFilter, handelDownload, downloading }) => {
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [module, setModule] = useState<string>('');
  const [dateRange, setDateRange] = useState<any>({ endDate: null, startDate: null });

  const handleDateChange = (_date: any) => {
    setDateRange(_date);
  };

  

  useEffect(() => {
    if (updateQueryFilter && !showMobileFilter) {
      updateQueryFilter((state) =>{ 
        return ({
        ...state,
        email,
        module,
        fromDate: dateRange?.startDate ? new Date(dateRange?.startDate).toISOString() : undefined,
        toDate: dateRange?.endDate ? new Date(dateRange?.endDate).toISOString() : undefined,
      })});
    }
  }, [
    showMobileFilter,
    dateRange?.endDate,
    dateRange?.startDate,
    email,
    module,
    updateQueryFilter,
  ]);

  const resetFilter = () => {
    setDateRange({ endDate: null, startDate: null });
  };

  const handleMobileFilter = () => setShowMobileFilter((state) => !state);

  return (
    <Fragment>
      <div className='w-full bg-white px-5 md:px-8 rounded-md p-4'>
        <div className='flex items-center gap-4 justify-end lg:justify-start'>
          <div className='hidden lg:flex flex-grow gap-8'>
            <AuditFilterFields
              {...{
                dateRange,
                email,
                module,
                handleDateChange,
                setModule,
                setEmail
              }}
            />
          </div>
          <div className='hidden lg:block'>
            <VscRefresh size={20} className='cursor-pointer' onClick={() => resetFilter()} />
          </div>
          <div className='lg:hidden'>
            <VscListFilter
              size={20}
              className='cursor-pointer'
              onClick={() => handleMobileFilter()}
            />
          </div>
          <div>
            {downloading ? (
              <Loader width='20' />
            ) : (
              <GrDocumentCsv
                size='20'
                className='cursor-pointer'
                onClick={() => handelDownload && handelDownload()}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        className='max-w-[600px!important] w-[90%]'
        open={showMobileFilter}
        handler={handleMobileFilter}
        dismiss={{
          enabled: true,
        }}>
        <DialogBody className='w-full grid grid-cols-2 gap-y-5 gap-x-4 py-8'>
          <div className='col-span-2 flex justify-end items-center'>
            <VscClose size={20} className='cursor-pointer' onClick={() => handleMobileFilter()} />
          </div>
          <AuditFilterFields
            {...{
                dateRange,
                email,
                module,
                handleDateChange,
                setModule,
                setEmail
            }}
          />
          <div className='col-span-2 flex justify-end items-center gap-x-10'>
            <VscRefresh size={20} className='cursor-pointer' onClick={() => resetFilter()} />
            <Button size='sm' onClick={() => handleMobileFilter()}>
              Search
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export default AuditFilter;
