import { AccountUserDto, AdminMetricObj, AdminUserDto, ApiAccessDto, IPaginatedDto, IUserFilter, IUserMetricFilter, LeanAccountUserDto, UpdateAdminPermissionInput, UpdateAdminRoleInput, UpdateAdminStatusInput, UpdateUserApiAccessInput, UpdateUserInput, UpdateUserRolesInput, UpdateUserStatusInput, UserMetricDto, WalletDto } from '../types';
import AxiosInstance from './config';

export const getUsers = async (
  params?: IUserFilter
): Promise<IPaginatedDto<LeanAccountUserDto>> => {
  const { data } = await AxiosInstance.get<IPaginatedDto<LeanAccountUserDto>>('/account/users', {
    params,
  });
  return data;
};

export const getUserMetric = async (
  params?: IUserMetricFilter
): Promise<UserMetricDto> => {
  const { data } = await AxiosInstance.get<UserMetricDto>('/account/users/metrics', {
    params,
  });
  return data;
};

export const getFullUsers = async (
  params?: IUserFilter
): Promise<IPaginatedDto<AccountUserDto>> => {
  const { data } = await AxiosInstance.get<IPaginatedDto<AccountUserDto>>('/account/users/full', {
    params,
  });
  return data;
};

export const getUserById = async (
  id: string
): Promise<AccountUserDto> => {
  try {
    const { data } = await AxiosInstance.get<AccountUserDto>(`/account/users/${id}`,);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserWallet = async (
  id: string
): Promise<WalletDto> => {
  try {
    const { data } = await AxiosInstance.get<WalletDto>(`/account/users/${id}/wallet`,);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id: string, payload: UpdateUserInput): Promise<AccountUserDto> => {
  try {
    const { data } = await AxiosInstance.post<AccountUserDto>(`/account/users/${id}`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUserApiAccess = async (id: string, payload: UpdateUserApiAccessInput): Promise<ApiAccessDto> => {
  try {
    const { data } = await AxiosInstance.put<ApiAccessDto>(`/account/users/${id}/api-access`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAdminUsers = async (
  params?: IUserFilter
): Promise<IPaginatedDto<AdminUserDto>> => {
  const { data } = await AxiosInstance.get<IPaginatedDto<AdminUserDto>>('/account/admins', {
    params,
  });
  return data;
};

export const getAdminUserById = async (
  id: string
): Promise<AdminUserDto> => {
  try {
    const { data } = await AxiosInstance.get<AdminUserDto>(`/account/admins/${id}`,);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAdminUserMetrics = async (): Promise<AdminMetricObj[]> => {
  try {
    const { data } = await AxiosInstance.get<AdminMetricObj[]>(`/account/admins/metrics`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const updateUserStatus = async (id: string, payload: UpdateUserStatusInput): Promise<AccountUserDto> => {
  try {
    const { data } = await AxiosInstance.put<AccountUserDto>(`/account/users/${id}/status`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUserRoles = async (id: string, payload: UpdateUserRolesInput): Promise<AccountUserDto> => {
  try {
    const { data } = await AxiosInstance.put<AccountUserDto>(`/account/users/${id}/roles`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};


export const updateAdminRole = async (id: string, payload: UpdateAdminRoleInput): Promise<AdminUserDto> => {
  try {
    const { data } = await AxiosInstance.put<AdminUserDto>(`/account/admins/${id}/adminType`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAdminPermission = async (id: string, payload: UpdateAdminPermissionInput): Promise<AdminUserDto> => {
  try {
    const { data } = await AxiosInstance.put<AdminUserDto>(`/account/admins/${id}/permission`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAdminStatus = async (id: string, payload: UpdateAdminStatusInput): Promise<AdminUserDto> => {
  try {
    const { data } = await AxiosInstance.put<AdminUserDto>(`/account/admins/${id}/status`, { ...payload });
    return data;
  } catch (error) {
    throw error;
  }
};



