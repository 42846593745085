import { ArcElement, CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import cn from 'classnames';
import { useEffect, useMemo, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import Datepicker from "react-tailwindcss-datepicker";
import { getTradeMetrics } from "../../../../../api";
import { TradeMetricsDataDto, TradeMetricsDto } from "../../../../../types";
import { PriceSpan } from "../../../../shared";


interface IChart{
    labels: string[]
    data: number[],
    totalQty: number[]
    backgroundColor: string[],
    borderColor: string[]

}

interface IDonutGraphData{
    bgColor?: string
    quantity?: number
    amount?: number,
    currency?: string
}

const DonutGraphDetails = ({bgColor, quantity, amount, currency}: IDonutGraphData)=>{
    return (
        <div className="flex flex-1 gap-3 items-center">
            <div className="flex gap-1">
                <span className={cn(["py-1 px-4  rounded-md"])} style={{background:bgColor}}></span>
                <span className="text-14 font-medium">{currency}</span>
            </div>
            <span>≈</span>
            <div className="flex-col">
                <div className="text-14 font-bold">{quantity}</div>
                <div className="text-12 font-normal"><PriceSpan qty={amount!} asset={currency!} /></div>
            </div>
        </div>
    )
}

const TradeMetric = ()=> { 
    const [tradeMetric, setTradeMetrics] = useState<TradeMetricsDto>();
    const [offRampmetricData, setOffRampMetricData] = useState<IChart>();
    const [onRampmetricData, setOnRampMetricData] = useState<IChart>();

    const [dateRange, setDateRange] = useState<any>({ endDate: null, startDate: null });

    const handleDateChange = (_date: any) => {
        setDateRange(_date);
    };

    useEffect(()=>{
        getTradeMetrics({fromDate: dateRange?.startDate ? new Date(dateRange?.startDate).toISOString() : undefined,
            toDate: dateRange?.endDate ? new Date(dateRange?.endDate).toISOString() : undefined})
        .then(data => {
            setTradeMetrics(data)
        }).catch(console.log)
    },[dateRange])

    const totalTrade = useMemo(()=> tradeMetric?.trade?.reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);
    const totalCompletedTrade = useMemo(()=> tradeMetric?.trade?.filter(x => x.status === 'COMPLETED').reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);

    const totalAds = useMemo(()=> tradeMetric?.ads?.reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);
    const totalCompletedAds = useMemo(()=> tradeMetric?.ads?.filter(x => x.status === 'CLOSED').reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);

    const totalDispute = useMemo(()=> tradeMetric?.dispute?.reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);
    const totalCompletedDispute = useMemo(()=> tradeMetric?.dispute?.filter(x => x.recievedResponse === 'CLOSED').reduce((total, val)=> total + Number(val.total), 0), [tradeMetric]);
   
    const getDonutMetricData = (type: string, tradeMetric: TradeMetricsDataDto[], backgroundColor:string[]): IChart =>{

        const groupedData = new Map<string, Array<TradeMetricsDataDto>>();
        tradeMetric?.forEach(item => {
            const dateKey = item.tradeType;
            const group = groupedData.get(dateKey);
            if (group) {
            group.push(item);
            } else {
            groupedData.set(dateKey, [item]);
            }
        });
        const set = new Set<string>();
        

        let totalQtyUsdt =0;
        let totalQtyBnb =0;
        let totalQtyBtc =0;
        let totalQtyEth =0;

        groupedData.get(type)?.forEach(item => {
            if(item.crypto === "BTC"){
                totalQtyBtc += Number(item.totalQuantity)
            } 
            if(item.crypto === "ETH"){
                totalQtyEth += Number(item.totalQuantity)
            } 
            if(item.crypto === "BNB"){
                totalQtyBnb += Number(item.totalQuantity)
            } 
            if(item.crypto === "USDT"){
                totalQtyUsdt += Number(item.totalQuantity)
            } 
            set.add(item.crypto);
        })

        const totalQtyValArr: number[] = [];
        totalQtyValArr[Array.from(set).indexOf("ETH")] = totalQtyEth;
        totalQtyValArr[Array.from(set).indexOf("BTC")] = totalQtyBtc;
        totalQtyValArr[Array.from(set).indexOf("BNB")] = totalQtyBnb;
        totalQtyValArr[Array.from(set).indexOf("USDT")] = totalQtyUsdt;
        return {labels: Array.from(set),  backgroundColor, borderColor:backgroundColor, data: totalQtyValArr, totalQty: totalQtyValArr}
    }

    useEffect(()=> {
        setOnRampMetricData(getDonutMetricData('ONRAMP', tradeMetric?.trade!, ["#5D73E8", '#18C1CC','#CC0A96','#E6DD15']))
        setOffRampMetricData(getDonutMetricData('OFFRAMP', tradeMetric?.trade!, ["#5D73E8", '#18C1CC','#CC0A96','#E6DD15']))
    },[tradeMetric])

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
    )
    const optionsc = {
        plugins: {
          legend: {
            display: false,
          },
        },
      };

    const offRampdata = {
        
        labels: offRampmetricData?.labels, 
        datasets: [
          {
            label: '',
            data: offRampmetricData?.data,
            backgroundColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',
            ],
            borderColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',   
            ],
            borderWidth: 1,
          },
        ],
      };

      const onRampdata = {
        
        labels: onRampmetricData?.labels,
        datasets: [
          {
            label: '',
            data: onRampmetricData?.data,
            backgroundColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',
            ],
            borderColor: [
              '#5D73E8',
              '#18C1CC',
              '#CC0A96',
              '#E6DD15',   
            ],
            borderWidth: 1,
          },
        ],
      };

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
            },
            legend: {
                display: false, // Set display to false to remove the label
            },
        },
        scales: {
            y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            },
            y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
            },
        },
    };
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
            data: [10, 54, 88, 80, 2, 32, 71],
            borderColor: '#CF5050',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            yAxisID: 'y',
            },
            {
            data: [73, 99, 200, 43, 16, 30, 100],
            borderColor: '#52B96F',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y1',
            },
        ],
    };
    return (
    <div className="flex flex-col shadow rounded mb-4">
        <div className="flex px-5 py-3 p-2 justify-between shadow">
            <div className="flex items-center justify-between gap-32">
                <p className="text-16 font-medium">Completed P2p Transaction value</p>
                <p className="text-12 font-medium">Total number of P2p user: <span className="text-16 font-bold">{tradeMetric?.p2pUser?.total || 0}</span></p>
            </div>
            <div className="flex">
                <Datepicker
                    useRange={false}
                    containerClassName='border rounded-md text-13'
                    value={dateRange}
                    onChange={handleDateChange}
                />
            </div>
           
        </div>
        <div className="flex p-8 gap-1">
            <div className="flex-col w-2/5">
                <Line options={options} data={data} />
                <div className="flex items-center justify-around">
                    <div className="flex gap-1 my-4">
                        <span className="py-1 px-4  rounded-md bg-[#52B96F]"></span>
                        <span className="text-14 font-medium">On-ramp</span>
                        
                    </div>
                    <div className="flex gap-1 my-4">
                        <span className="py-1 px-4  rounded-md bg-[#CF5050]"></span>
                        <span className="text-14 font-medium">Off-ramp</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 gap-1">
                <div className="flex-col w-3/5 gap-1">
                    <div className="flex-col p-4 shadow-md rounded">
                        <p className="test-16 font-medium mb-2">Completed Off-ramp transaction</p>
                        <div className="flex gap-8">
                            <div className="flex h-28 w-28"><Doughnut options={optionsc} data ={offRampdata}/></div>
                            
                            <div className="flex- gap-1">
                            {offRampdata.labels?.map((val, i)=> {
                                return  <DonutGraphDetails key={i} currency={val} amount={offRampmetricData?.data[i]} quantity={offRampmetricData?.totalQty[i]} bgColor={offRampmetricData?.backgroundColor[i]}/>
                            }
                            )}
                            
                            </div>
                        </div>
                    </div>
                    <div className="flex-col p-4 shadow-md rounded">
                        <p className="test-16 font-medium mb-2">Completed On-ramp transaction</p>
                        <div className="flex gap-8">
                            <div className="flex h-28 w-28"><Doughnut options={optionsc} data ={onRampdata}/></div>
                            
                            <div className="flex- gap-1">
                            {onRampdata.labels?.map((val, i)=> (
                                <DonutGraphDetails key={i} currency={val} amount={onRampmetricData?.data[i]} quantity={onRampmetricData?.totalQty[i]} bgColor={onRampmetricData?.backgroundColor[i]}/>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col w-2/5 gap-2">
                    <div className="flex flex-col flex-1 w-full shadow-md rounded py-4 px-3 h-1/3">
                        <div className="text-14 font-medium mb-3">P2P Trades:<span className="text-16 font-bold pl-1">{totalTrade}</span></div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">{totalCompletedTrade}</span> Completed</div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">{totalTrade! - totalCompletedTrade!}</span> Uncompleted</div>
                    </div>
                    <div className="flex flex-col flex-1 w-full shadow-md rounded py-4 px-3 h-1/3">
                        <div className="text-14 font-medium mb-3">P2P Ads:<span className="text-16 font-bold pl-1">{totalAds}</span></div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">{totalCompletedAds}</span> Completed</div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">{totalAds! - totalCompletedAds!}</span> Uncompleted</div>
                    </div>
                    <div className="flex flex-col flex-1 w-full shadow-md rounded py-4 px-3 h-1/3">
                        <div className="text-14 font-medium mb-3">P2P Disputes:<span className="text-16 font-bold pl-1">{totalDispute}</span></div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">{totalCompletedDispute}</span> Resolved</div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">162</span> Canceled</div>
                        <div className="text-14 mb-1"><span className="text-14 font-semibold">162</span> Pending</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)};
export default TradeMetric;