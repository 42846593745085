import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../middlewares";
import { WalletDetails } from "../pages";
import Wallet from "../pages/wallets";

const WalletRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthGuard />}>
          <Route index element={<Wallet />} />
        </Route>
        <Route element={<AuthGuard userType='ADMIN' permissions={['MANAGE_USERS']} />}>
          <Route path=':walletId' element={<WalletDetails />} />
        </Route>
      </Routes>
    );
  };
  
  export default WalletRoutes;