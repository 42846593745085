import { Option, Select } from '@material-tailwind/react';
import React, { Fragment, SetStateAction } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import Input from '../../../Input';

export type IVerifiedSelect = 'all' | 'verified' | 'unverified';

interface IProps {
  dateRange: any;
  emailVerified: IVerifiedSelect;
  phoneVerified: IVerifiedSelect;
  email: string,
  phone: string,
  handleDateChange: (value: any) => void;
  setEmailVerified: React.Dispatch<SetStateAction<IVerifiedSelect>>;
  setPhoneVerified: React.Dispatch<SetStateAction<IVerifiedSelect>>;
  setEmail: React.Dispatch<SetStateAction<string>>;
  setPhone: React.Dispatch<SetStateAction<string>>;
}

const FilterFields: React.FC<IProps> = (props) => {
  const labelClassName = 'text-10 text-gray-500 font-semibold mb-2';
  return (
    <Fragment>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Email Verified</div>
        <Select
          variant='outlined'
          value={props.emailVerified}
          onChange={(value) => props.setEmailVerified(value as any)}>
          <Option value='all'>Any Status</Option>
          <Option value='verified'>Verified</Option>
          <Option value='unverified'>Unverified</Option>
        </Select>
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Phone Verified</div>
        <Select
          variant='outlined'
          value={props.phoneVerified}
          onChange={(value) => props.setPhoneVerified(value as any)}>
          <Option value='all'>Any Status</Option>
          <Option value='verified'>Verified</Option>
          <Option value='unverified'>Unverified</Option>
        </Select>
      </div>

      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Phone</div>
        <Input 
          value={props.phone}
          onChange={(e) => props.setPhone(e.target.value as any)}
        />
      </div>
      <div className='col-span-2 md:col-span-1'>
        <div className={labelClassName}>Email</div>
        <Input 
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value as any)}
        />
      </div>
      <div className='col-span-2'>
        <div className={labelClassName}>Registration Date</div>
        <Datepicker
          useRange={false}
          value={props.dateRange}
          onChange={props.handleDateChange}
          containerClassName='border rounded-md text-13'
        />
      </div>
    </Fragment>
  );
};

export default FilterFields;
