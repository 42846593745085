import { Fragment, useEffect, useState } from "react";
import { getServiceMetrics } from "../../../../../api";
import { ServiceMetricDto } from "../../../../../types";
import StatCard from "../../../dashboard/StatCards/StatCard";

const SeviceMetricCards: React.FC<unknown> = () => {
  
    const [serviceMetric, setServiceMetric] = useState<ServiceMetricDto|null>(null);
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(()=>{
        getServiceMetrics()
        .then((data)=> {
            setServiceMetric(data);
            setLoading(false);
        })
        .catch(console.log)
        .finally(()=>{
            setLoading(false);
        })

    },[])
    

    return (
      <section className='flex gap-3'>
        {
         !loading &&
         
        <Fragment>
            <StatCard
            
                title='BitBarter AppService' 
                count={serviceMetric?.appService?.reduce((total, obj)=> total + obj.count, 0) ?? 0}
                meta={[
                { count: serviceMetric?.appService.find(x => x.isActive === true)?.count ?? 0, title: 'online'},
                { count: serviceMetric?.appService.find(x => x.isActive === false)?.count ?? 0, title: 'ofline'},
                ]}
            />

<           StatCard
                title='BitBarter AppModule'
                count={serviceMetric?.appModule?.reduce((total, obj)=> total + obj.count, 0) ?? 0}
                meta={[
                { count: serviceMetric?.appModule.find(x => x.isActive === true)?.count ?? 0, title: 'online'},
                { count: serviceMetric?.appModule.find(x => x.isActive === false)?.count ?? 0, title: 'ofline'},
                ]}
            />  
        
        </Fragment>
        }
      </section>
    );
  };
  
  export default SeviceMetricCards;