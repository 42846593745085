import create from 'zustand';
import { persist } from 'zustand/middleware';
import { PriceTickerData } from '../types/common/priceTickerData';
import { Persistor } from './_type';

interface RateState {
    rate: Record<string, number | string>;
}

interface RateActions {
    setRate: (data: PriceTickerData) => void;
}


const initRateData: Record<string, number | string> = {};

export const useRateStore = create<RateState & RateActions>(
    (persist as Persistor<RateState & RateActions>)(
        (set) => ({
            rate: initRateData,
            setRate: (data: PriceTickerData) => {
                const key = `${data.from}_${data.to}`;
                initRateData[key] = data.price
                set({ rate: initRateData })
            }
        }),
        {
            name: 'persis:rateStore',
            version: 1,
        }
    )
);
