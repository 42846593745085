import React, { Fragment, useEffect, useState } from 'react';
import { getTradeDisputeMetrics } from '../../../../../../api';
import { useSys } from '../../../../../../hooks';
import { TradeDisputeMetricsDto } from '../../../../../../types';
import StatCard from '../../../../dashboard/StatCards/StatCard';

const DisputeStatCards: React.FC<unknown> = () => {
  const { stats } = useSys();
  const [stat, setStat] = useState<TradeDisputeMetricsDto[] | undefined>();
  const [statsLoading, setStatsLoading] = useState<boolean>(false);
  const [totalDispute, setTotalDispute] = useState<number>(0);
  const [openDispute, setOpenDispute] = useState<number>(0);
  const [pendingDispute, setPendingDispute] = useState<number>(0);
  const [resolvedDispute, setResolvedDispute] = useState<number>(0);

  const fetchDisputeStats = () => {
    
    setStatsLoading(true);
    getTradeDisputeMetrics()
      .then((data) => {
        setStat(data)
        const openVal = data.find(x => x.status === "OPEN");
        const pendingVal = data.find(x => x.status === "PROCESSING");
        const resolvedVal = data.find(x => x.status === "CLOSED");
        setTotalDispute(data.reduce((total, obj)=> { return total + parseInt(obj.total)}, 0))
        setOpenDispute(openVal ? parseInt(openVal.total) : 0)
        setPendingDispute(pendingVal ? parseInt(pendingVal.total) : 0)
        setResolvedDispute(resolvedVal ? parseInt(resolvedVal.total) : 0)
    })
      .catch(console.error)
      .finally(() => setStatsLoading(false));
  };

  useEffect(() => {
    fetchDisputeStats()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(()=>{
    if(stat){
      const openVal = stat.find(x => x.status === "OPEN");
      const pendingVal = stat.find(x => x.status === "PROCESSING");
      const resolvedVal = stat.find(x => x.status === "CLOSED");
      setTotalDispute(stat.reduce((total, obj)=> { return total + parseInt(obj.total)}, 0))
      setOpenDispute(openVal ? parseInt(openVal.total) : 0)
      setPendingDispute(pendingVal ? parseInt(pendingVal.total) : 0)
      setResolvedDispute(resolvedVal ? parseInt(resolvedVal.total) : 0)
    }
    
  },[stat])


  return (
    <section className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-y-2 lg:gap-x-4'>
      {(stats && !statsLoading) && (
        <Fragment>
          <StatCard
            title='Total dispute(s)'
            count={totalDispute}
            
          />
          <StatCard
            title='Pending dispute(s)'
            count={pendingDispute}
            
          />
          <StatCard
            title='Resolved dispute(s)'
            count={resolvedDispute}
          />
          <StatCard
            title='Open dispute(s)'
            count={openDispute}
          />
        </Fragment>
      )}
    </section>
  );
};

export default DisputeStatCards;
