import cn from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { RelativeTimeCell } from '../common';

const AmountCell = ({ value, column, row }: Cell) => {
  return <span>{`${parseFloat(value).toFixed(4 )} ${(row.original as any).currency}`}</span>;
};

const Status = ({ column, value }: Cell) => (
  <div className={(column as any).className}>
    <span className={cn(['bg-gray-500 py-1 px-4 text-10 font-semibold rounded-md'])}>{value}</span>
  </div>
);

export const transactionColums = (enableViewMore?:boolean) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'ID',
    accessor: 'txId',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Type',
    accessor: 'side',
  },
  {
    Header: 'Network',
    accessor: 'network'
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: AmountCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
    Cell: Status,
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell
  },
  ...(enableViewMore?[
    {
      Header: ' ',
      accessor: 'id',
      Cell: ({value}: Cell)=>(<Link to={`${value}`}>view more</Link>)
    }
  ]:[])
  
];
