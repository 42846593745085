import React from 'react';
import './styles/global.scss';
import App from './App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { sentryDns } from './config';
import "react-toastify/dist/ReactToastify.css";
import {HelmetProvider} from "react-helmet-async";

Sentry.init({
  dsn: sentryDns,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
  environment: process.env.NODE_ENV || 'development'
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // @ts-ignore
    <React.StrictMode>
        {/* @ts-ignore */}
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
