import { VscCreditCard, VscHome, VscOrganization, VscSettings } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { Logo } from '../../../../../assets';
import NavItem, { INavItemProps } from './NavItem';

const navItems: INavItemProps[] = [
  {
    route: '/',
    text: 'Home',
    NavIcon: VscHome,
  },
  {
    route: '/user',
    text: 'User Manager',
    NavIcon: VscOrganization,
  },
  {
    route: '/setting',
    text: 'Service Manager',
    NavIcon: VscSettings
  },
  {
    route: '/marketplace/ads',
    text: 'P2P ADs Manager',
    NavIcon: VscCreditCard
  },
  {
    route: '/marketplace',
    text: 'P2P Trade Manager',
    NavIcon: VscCreditCard
  },
  {
    route: '/marketplace/disputes',
    text: 'Dispute Manager',
    NavIcon: VscCreditCard
  },
  {
    route: '/transaction',
    text: 'Transaction Manager',
    NavIcon: VscCreditCard
  },
  {
    route: '/paymentChannel',
    text: 'Payment Settings Manager',
    NavIcon: VscSettings
  },
  
  {
    route: '/admin',
    text: 'Admin Manager',
    NavIcon: VscOrganization,
  },

  {
    route: '/audit',
    text: 'Admin Audit Trail',
    NavIcon: VscOrganization,
  },

  {
    route: '/kyc',
    text: 'KYC Manager',
    NavIcon: VscOrganization,
  },

  
];

const SideNav = () => (
  <nav className='w-full'>
    <div className='border-b px-4 py-6'>
      <Link to='/'>
        <Logo height='40' />
      </Link>
    </div>
    <div className='px-4 py-10 grid grid-cols-1 gap-y-6'>
      {navItems.map((props, key) => (
        <NavItem {...props} key={key} />
      ))}
    </div>
    <div className='w-full absolute bottom-0 p-4'>
      <p className='text-12 text-gray-600'>&copy; BitBarter {new Date().getFullYear()}</p>
    </div>
  </nav>
);

export default SideNav;
