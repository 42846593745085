import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { VscChevronLeft, VscChevronRight, VscCopy } from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import { getTradeById } from "../../../../api";
import Button from "../../../../components/Button";
import MainWrapper from "../../../../components/MainWrapper";
import { AdsDetailsModal } from "../../../../components/Modals";
import { PriceSpan } from "../../../../components/shared";
import { P2pTradeDto } from "../../../../types";

const TradeDetails = () => {
  const { tradeId } = useParams(); 
  const [trade, setTrade] = useState<P2pTradeDto|null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);


  const closeModal = () => setOpenModal(false);
  useEffect(()=>{
    if(tradeId){
      getTradeById(tradeId)
      .then(data => setTrade(data))
      .catch(console.log)
    }
  }, [tradeId])
  return (
    <MainWrapper title='Trade Deatails'>
      <div className="flex flex-col">
        <Link to={"/marketplace"} className="flex items-center gap-1">
          <VscChevronLeft />
          <div className="rounded">Back to trade Manager</div>
        </Link>
        <div className="flex gap-4">
          <div className="flex basis-3/5 ">
            <div className="flex flex-1 bg-[#F5FFF8]">
              <div className="flex flex-col flex-1 py-4 pl-7" >
                <div className="flex">
                  <div className="flex flex-col">
                    <div className="flex gap-3">
                      <div className="flex justify-center tems-center rounded-full p-2 text-12 bg-[#BDFFCF]" >Buyer</div>
                      {trade?.maker?.roles?.includes("MERCHANT") === true && <div className="flex justify-center items-center rounded-full px-2 text-12 bg-[#E9E9E9]">Merchant</div>
                      }
                    </div>
                    <div className="pt-5 text-16 font-semibold">{trade?.maker?.profile?.fullName}</div>
                    <div className="flex gap-1 items-center">
                      <div className="pt-1 text-14 font-medium">
                        @{trade?.maker?.username}
                      </div>
                      <div><VscCopy /></div>
                      
                    </div>
                    <div className="flex gap-1 items-center">
                      <div className="pt-2 text-14 font-medium">
                        {trade?.maker?.phone}
                      </div>
                      <div><VscCopy /></div>
                      
                    </div>
                    <div className="pt-2 text-14">1,234 Completion | 88% Completion</div>
                    <div className="flex">
                      <div>5.0</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center px-2">
                <VscChevronRight />
              </div>
            </div>
            
            <div className="flex flex-1 bg-[#FFF0F0]">
              <div className="flex flex-col flex-1 py-4 pl-7" >
                <div className="flex">
                  <div className="flex flex-col">
                    <div className="flex gap-3">
                      <div className="flex justify-center tems-center rounded-full p-2 text-12 bg-[#FFC8C8]">Seller</div>
                      {trade?.maker?.roles?.includes("MERCHANT") === true && <div className="flex justify-center items-center rounded-full px-2 text-12 bg-[#E9E9E9]">Merchant</div>
                      }
                      <div className="flex justify-center tems-center rounded-full p-2 text-12 bg-[#FFC8C8]">Ads</div>
                    </div>
                    <div className="pt-5 text-16 font-semibold">{trade?.taker?.profile?.fullName}</div>
                    <div className="flex gap-1 items-center">
                      <div className="pt-1 text-14 font-medium">
                        @{trade?.taker?.username}
                      </div>
                      <div><VscCopy /></div>
                      
                    </div>
                    <div className="flex gap-1 items-center">
                      <div className="pt-2 text-14 font-medium">
                        {trade?.taker?.phone}
                      </div>
                      <div><VscCopy /></div>
                      
                    </div>
                    <div className="pt-2 text-14">1,234 Completion | 88% Completion</div>
                    <div className="flex">
                      <div>5.0</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center px-2">
                <VscChevronRight />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between basis-2/5">
            <div className="flex py-9 pl-7 gap-1 shadow-md rounded">
              <div className="text-14 font-medium text-gray-700">Trade status: </div>
              <div className="text-14 font-bold">{trade?.status}</div>
            </div>
            <div className="flex py-9 px-7 justify-between shadow-md rounded">
              <div className="flex gap-1">
                <div className="text-14 font-medium text-gray-700">Dispute status: </div>
                <div className="text-14 font-semibold">{trade?.disputed ? "DISPUTE" :"NO DISPUTE"}</div>
              </div>
              {trade?.disputed && <div className="text-14">Manage</div>}
              
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-6 shadow-md rounded">
          <div className="flex px-7 py-4  shadow-sm">
            <div className="flex items-center justify-between basis-2/5">
              <div className="text-14">
                Trade details
              </div>
              <Button onClick={()=>{setOpenModal(true)}} className="flex bg-black items-center justify-center py-2 px-4 rounded">
                <div className="text-14 text-white">
                  See Ad details
                </div>
                <div className="">
                  <VscChevronRight color="#D9D9D9"/>
                </div>
              </Button>
            </div>
            <div className="flex px-5 basis-3/5 items-center justify-between">
              <p className="text-14 text-gray-400">
                Created: <span className="text-black">{dayjs(trade?.createdAt).format('D MMMM, YYYY | h:mm a')}</span> 
              </p>
              <div className="flex gap-1 items-center">
                <p className="pt-2 text-14 text-gray-400">
                  Ad ID: <span className="font-normal text-black">{trade?.order?.id}</span>
                </p>
                <div><VscCopy /></div>
              </div>
              <div className="flex gap-1 items-center">
                <p className="pt-2 text-14 text-gray-400">
                  Order ID: <span className="font-normal text-black">{trade?.tradeOrderId}</span>
                </p>
                <div><VscCopy /></div>
              </div>
              
            </div>
          </div>
          <div className="flex flex-col m-7 rounded">
            <div className="flex items-start justify-between p-7 shadow">
              <p className="flex-1 text-14 font-normal">Trade amount</p>
              <p className="flex-1 text-14 font-normal">Trade pair</p>
              <p className="flex-1 text-14 font-normal">Trade price</p>
              <p className="flex-1 text-14 font-normal">Payment method</p>
              <p className="flex-1 text-14 font-normal">Completion time</p>
              <p className="flex-1 text-14 font-normal">Trade fee</p>
              <p className="flex-1 text-14 font-normal">Quantity</p>
            </div>
            <div className="flex items-start justify-between p-7 shadow">
              <p className="flex-1 text-14 font-normal">{Number(trade?.amount).toFixed(2)}</p>
              <p className="flex-1 text-14 font-normal">{trade?.order?.crypto}/{trade?.fiat}</p>
              <p className="flex-1 text-14 font-normal"><PriceSpan qty={trade?.quantity!} asset={trade?.crypto!} /></p>
              <p className="flex-1 text-14 font-normal">{trade?.sellerPaymentMethod?.accountTag || "Bank Transfer"}</p>
              <p className="flex-1 text-14 font-normal">{`${dayjs(trade?.updatedAt).diff(dayjs(trade?.createdAt), 'm')} minute(s)`}</p>
              <p className="flex-1 text-14 font-normal">{Number(trade?.tradeFee).toFixed(2)}</p>
              <p className="flex-1 text-14 font-normal">{Number(trade?.quantity).toFixed(8)}{trade?.crypto}</p>
            </div>
          </div>
        </div>
      </div>
      <AdsDetailsModal open={openModal} closeModal={closeModal} adId={trade?.order?.id} />
    </MainWrapper>
  );
};
  
export default TradeDetails;