import {
  Dialog
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { updateUserApiAccess } from "../../api";
import { ApiAccessDto } from "../../types";
import Button from "../Button";
    
    
    
    
  interface IModal{
    closeModal : ()=> void;
    open: boolean;
    userId?: string;
    apiAccessEnabled?: boolean;
  }
  
  export default function UpdateApiAcccessModal({open, closeModal, userId, apiAccessEnabled}: IModal) {
    const [loading, setLoading] = useState<boolean>(false);
    const [display, setDisplay] = useState<boolean>(true);
    const [apiAccessDto, setApiAccessDto] = useState<ApiAccessDto>();

    const handleCloseModal = ()=>{
      closeModal();
    }
    
    const handleSuccess = ()=>{
      closeModal();
      window.location.reload();
    }
    const toggleStatus = ()=> {
      setLoading(true)
      updateUserApiAccess(userId!, {apiAccessEnabled : !apiAccessEnabled!})
      .then((data)=> {
        if(data.completed){
          setDisplay(false);
        }
        setApiAccessDto(data)
      })
      .catch((error)=>{
        const message = error?.response?.data?.message || 'Update failed. Please retry';
        toast.error(message);
      })
      .finally(()=>{
          setLoading(false)
      })
    }

      
    return (
      <Fragment>
        
        <Dialog className="flex flex-col px-6 py-8 items-center gap-1" open={open} handler={handleCloseModal} >
            
          {display && 
            <div className="flex flex-col">
                <div className="text-24 font-semibold text-center px-20">{`Are you sure you want to ${apiAccessEnabled ? 'restrict' : 'grant access to'} this user?`}</div>
                <div className="flex px-8 justify-between w-full gap-4 mt-10">
                    <Button onClick={closeModal} className="w-full bg-white text-black border-2 border-black shadow-none">No</Button>
                    <Button onClick={toggleStatus} loading={loading} className="w-full bg-[#FFF0F0] border-2 text-[#A72828] border-[#CF5050]">Yes</Button>
                </div>
            </div>
          }
            
          
            {apiAccessDto?.completed && 
                <div className="flex flex-col">
                    <div className="text-24 font-semibold text-center px-20">{`You have successfully ${apiAccessEnabled ? 'restricted': 'grant'} api access to this user`}</div>
                    <div className="flex w-full px-24 mt-10">
                        <Button onClick={handleSuccess} className="w-full bg-[#EB9F44] text-white shadow-none">Okay</Button>
                    </div>
                </div>
            }
        </Dialog>
      </Fragment>
    );
  }