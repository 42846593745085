import { useAdminPermission } from "../../../../hooks";
import { DisputeLogTable } from "../../../tables";

const DisputeLogList = () => {
    const { hasRequiredPermissions } = useAdminPermission();
    const showFull = hasRequiredPermissions(['MANAGE_USERS'], 'ADMIN');
  
    return (
      <div>
        <DisputeLogTable limit={20} showFull={showFull} enableFilter showPagination />
      </div>
    );
  };
  
export default DisputeLogList;