import { Dialog } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateAdminRole } from '../../api';
import { AdminRoles, AdminType, AdminUserDto } from '../../types';
import Button from '../Button';

interface IModal {
  closeModal: () => void;
  open: boolean;
  user?: AdminUserDto;
  setUser: (user: AdminUserDto) => void;
}

export default function RolesModal({ open, closeModal, user, setUser }: IModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<AdminType | undefined>(user?.type);

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (user?.type) {
      setRole(user.type);
    }
  }, [user]);

  const handleChangeRole = () => {
    setLoading(true);
    updateAdminRole(user?.id!, { type: role! })
      .then((data) => {
        setUser(data);
        closeModal();
      })
      .catch((error) => {
        const message = error?.response?.data?.message || 'Update failed. Please retry';
        toast.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const roles = AdminRoles.map((val, i) => {
    return (
      <div
        onClick={() => setRole(val)}
        key={val}
        className={`flex text-14 font-medium px-6 py-3 border rounded-full ${
          role === val ? 'text-[#8D6ABD]  border-[#8D6ABD] bg-[#F9F5FF]' : 'border-[#ADADAD]'
        }`}>
        Make {val}
      </div>
    );
  });

  return (
    <Fragment>
      <Dialog className='flex flex-col px-6 py-8 gap-1' open={open} handler={handleCloseModal}>
        <div className='text-[#353535] text-24 font-bold'>Set roles</div>
        <div className='flex flex-wrap mt-14 gap-2'>{roles}</div>
        <Button
          onClick={handleChangeRole}
          loading={loading}
          className='mt-8 p-4 w-full bg-[#8D6ABD] rounded-lg'>
          Confirm
        </Button>
      </Dialog>
    </Fragment>
  );
}
