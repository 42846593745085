import AxiosInstance from './config';
import { AdminAuthDto, AdminUserDto, TwoFactorSetupDto } from '../types';

export const loginUser = async (authToken: string): Promise<AdminAuthDto> => {
  const { data } = await AxiosInstance.post<AdminAuthDto>('/auth/login', { authToken });
  return data;
};

export const init2faSetup = async (): Promise<TwoFactorSetupDto> => {
  const { data } = await AxiosInstance.post<TwoFactorSetupDto>('/auth/2fa');
  return data;
};

export const verifyAuth2fa = async (token: string): Promise<AdminAuthDto> => {
  const { data } = await AxiosInstance.post<AdminAuthDto>('/auth/2fa/verify', { token });
  return data;
};

export const getCurrentUser = async (): Promise<AdminUserDto> => {
  const { data } = await AxiosInstance.get<AdminUserDto>('/auth/me');
  return data;
};
