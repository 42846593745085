import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { VscArrowRight, VscChevronLeft } from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import { getTransactionById } from "../../../api";
import MainWrapper from "../../../components/MainWrapper";
import { TransactionDto } from "../../../types";

const TransactionDetails = () => {
  const [transaction, setTransaction] = useState<TransactionDto| null>(null);
  const { transactionId } = useParams();

  useEffect(()=>{
    if(transactionId){
      getTransactionById(transactionId)
      .then(data => setTransaction(data))
      .catch(console.log)
    }
  }, [transactionId])

  return (
    <MainWrapper title="Manage Transaction">
    <Link to={"/transaction"} className="flex items-center gap-1 pb-2">
        <VscChevronLeft />
        <div className="rounded">Back to Transaction Manager</div>
    </Link>
      <div className="flex flex-col rounded-lg shadow-md">
        <div className="flex p-4  shadow-md">
            <div className="flex flex-1 items-center justify-start gap-4">
                {
                    transaction?.category === "WALLET" && 
                    <div className={["p-4", transaction?.side === "WITHDRAWAL"? "text-[#A72828] bg-[#FFF0F0] " : "text-[#02691F] bg-[#EBFFF0]", "text-14 font-medium rounded-lg"].join(" ")}>
                        Crypto {transaction?.side?.toLocaleLowerCase()}
                    </div>
                }
                {
                    transaction?.category === "BITCODE"  &&
                    <div className="p-4 text-[#CB7916] bg-[#FFF5E8] text-14 font-medium rounded-lg">
                        Crypto {transaction?.side?.toLocaleLowerCase()}
                    </div>
                }
                <div className="p-4  text-14 font-medium bg-gray-300 rounded-lg">
                    {transaction?.currency}
                </div>
                {
                    transaction?.category === "BITCODE"  &&
                    <div className="p-4 text-14 font-medium bg-gray-200 rounded-lg">
                        Transaction ID: <span>{transaction?.txId}</span>
                    </div>
                }
                
            </div>
            <div className="flex px-7 py-3 justify-center items-center bg-black rounded-md">
                <Link to={`/user/${transaction?.wallet?.account?.id}`} className="text-white">Go to user profile <VscArrowRight className="inline"/></Link> 
            </div>
        </div>
        <div className="flex px-6 py-6 gap-12">
            <div className="flex flex-col gap-2">
                <div className="text-14">Deposit amount</div>
                <div className="text-14 font-medium">{Number(transaction?.amount).toFixed(8)} {transaction?.currency}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Network</div>
                <div className="text-14 font-medium">{transaction?.network}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Fees</div>
                <div className="text-14 font-medium">{Number(transaction?.paidFee).toFixed(8)}{transaction?.currency}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Status</div>
                <div className="text-14 font-medium">{transaction?.status}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Sender</div>
                <div className="text-14 font-medium">{transaction?.sender}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Duration</div>
                <div className="text-14 font-medium">{`${dayjs(transaction?.updatedAt).diff(dayjs(transaction?.createdAt), 'm')} minute(s)`}</div>
            </div>
            
            <div className="flex flex-col gap-2">
                <div className="text-14">Reciever’s Wallet address</div>
                <div className="text-14 font-medium">{transaction?.recipient}</div>
            </div>
        </div>
        
        <div className="flex px-6 py-6 gap-12">
            <div className="flex flex-col gap-2">
                <div className="text-14">Date</div>
                <div className="text-14 font-medium">{dayjs(transaction?.createdAt).toDate().toLocaleDateString()}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Time</div>
                <div className="text-14 font-medium">{dayjs(transaction?.createdAt).toDate().toLocaleTimeString()}</div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-14">Transaction reference</div>
                <div className="text-14 font-medium">{transaction?.refId}</div>
            </div>
        </div>
      </div>
    </MainWrapper>
  )
}

export default TransactionDetails;