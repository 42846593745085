import MainWrapper from "../../components/MainWrapper";
import { WalletsList } from "../../components/pageComponents/wallet";

const Wallet = () => {
    return (
      <MainWrapper title="Manager Wallet">
        <WalletsList />
  
      </MainWrapper>
    )
  }
  
  export default Wallet;