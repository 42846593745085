import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../middlewares";
import { AddPaymentChannel, PaymentChannel } from "../pages";

const PaymentChannelRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthGuard userType='ADMIN'/>}>
          <Route index element={<PaymentChannel />} />
        </Route>
        <Route element={<AuthGuard userType='ADMIN' permissions={['MANAGE_USERS']} />}>
          <Route path='add' element={<AddPaymentChannel />} />
        </Route>
      </Routes>
    );
  };
  
  export default PaymentChannelRoutes;