import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../middlewares";
import { Ads, DisputeLogs, Trade, TradeDetails, TradeDispute, TradeDisputeDetails } from "../pages";

const MarketPlaceRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthGuard />}>
          <Route index element={<Trade />} />
          <Route path="/trades/:tradeId" element={<TradeDetails />} />
          <Route path='/ads' element={<Ads />} />
          
        
          
        </Route>
        <Route element={<AuthGuard userType='ADMIN' />}>
        <Route path='/disputes' element={<TradeDispute />} />
        <Route path='/disputes/:tradeDisputeId' element={<TradeDisputeDetails />} />
        <Route path='/disputelogs' element={<DisputeLogs />} />
        

      </Route>
      </Routes>
    );
  };
  
  export default MarketPlaceRoutes;