import { GetTxFilter, IPaginatedDto, ITransactionAggregateFilter, TransactionAggregateDto, TransactionDto } from '../types';
import AxiosInstance from './config';

export const getTransactions = async (
  params?: GetTxFilter
): Promise<IPaginatedDto<TransactionDto>> => {
  try {
    const { data } = await AxiosInstance.get<IPaginatedDto<TransactionDto>>('/transactions', {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionById = async (id: string): Promise<TransactionDto> => {
  try {
    const { data } = await AxiosInstance.get<TransactionDto>(`/transactions/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionAggregate = async (params?: ITransactionAggregateFilter): Promise<TransactionAggregateDto[]> => {
  try {
    const { data } = await AxiosInstance.get<TransactionAggregateDto[]>(`/transactions/aggregator`, {
      params
    });
    return data;
  } catch (error) {
    throw error;
  }
};
