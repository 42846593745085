import cn from 'classnames';
import React, { SetStateAction, useState } from 'react';
import { TransactionsTable, UsersTable } from '../../../tables';

type ITabTitle = 'Users' | 'Transactions';

type ITab = React.FC<{
  title: ITabTitle;
  activeTab: ITabTitle;
  setActiveTab: React.Dispatch<SetStateAction<ITabTitle>>;
}>;

const Tab: ITab = ({ title, activeTab, setActiveTab }) => (
  <span
    className={cn([
      'py-5 px-8 border-b-2 hover:border-blue-500',
      {
        'border-transparent': title !== activeTab,
        'border-blue-500': title === activeTab,
      },
    ])}
    onClick={() => setActiveTab(title)}>
    {title}
  </span>
);

const RecentTable = () => {
  const [activeTable, setActiveTable] = useState<ITabTitle>('Users');

  return (
    <div className='mt-8'>
      <div className='bg-white px-5 md:px-8 mb-5 rounded-md py-0 flex justify-start align-center shadow'>
        <Tab title='Users' activeTab={activeTable} setActiveTab={setActiveTable} />
        <Tab title='Transactions' activeTab={activeTable} setActiveTab={setActiveTable} />
      </div>
      <div>
        {activeTable === 'Users' && <UsersTable limit={10} enableFilter={false} showPagination={false} enableViewMore={false} />}
        {activeTable === 'Transactions' && <TransactionsTable limit={10} enableFilter={false} showPagination={false} enableViewMore={false}  />}
      </div>
    </div>
  );
};

export default RecentTable;
