import { useAdminPermission } from "../../../../hooks";
import { AdminUsersTable } from "../../../tables";

const AdminUsersList = () => {
    const { hasRequiredPermissions } = useAdminPermission();
    const showFull = hasRequiredPermissions(['MANAGE_USERS'], 'ADMIN');
  
    return (
      <div>
        <AdminUsersTable limit={20} showFull={showFull} enableFilter showPagination />
      </div>
    );
  };
  
  export default AdminUsersList;