import React from 'react';
import { FormErrorText } from '../shared';
import { Input as MaterialInput } from '@material-tailwind/react';

type IInput = Omit<React.ComponentProps<typeof MaterialInput>, 'error'> & {
  error?: string;
};

const Input = ({ error, ...props }: IInput) => (
  <div>
    <MaterialInput error={!!error} {...props} />
    <FormErrorText error={error} />
  </div>
);

export default Input;
