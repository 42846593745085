import React from 'react';
import Loader from '../Loader';
import cn from 'classnames';
import { Button as MaterialButton } from '@material-tailwind/react';

type IButton = React.ComponentProps<typeof MaterialButton> & {
  loading?: boolean;
};

const Button = ({ loading, children, disabled, className, ...props }: IButton) => (
  <MaterialButton
    className={cn(['capitalize font-inherit', className])}
    disabled={disabled || loading}
    {...props}>
    {children}
    <Loader className={cn(['ml-3', { hidden: !loading }])} />
  </MaterialButton>
);

export default Button;
