import { useAdminPermission } from '../../../../../hooks';
import { TradesTable } from '../../../../tables';


const TradesList = () => {
  const { hasRequiredPermissions } = useAdminPermission();
  const showFull = hasRequiredPermissions(['MANAGE_USERS'], 'ADMIN');
  const canViewMore = hasRequiredPermissions([], 'SUPPORT');

  return (
    <div>
      <TradesTable limit={20} showFull={showFull} enableFilter showPagination viewMore={canViewMore} />
    </div>
  );
};

export default TradesList;