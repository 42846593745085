export const TxStatuses = [
  'CANCELLED',
  'COMPLETED',
  'PROCESSING',
  'PENDING',
  'WAITING',
  'FAILED',
] as const;

export const TxCategories = ['BITCODE', 'SWAP', 'WALLET'] as const;

export const TxSides = ['DEPOSIT', 'DISTRIBUTION', 'SWAP', 'WITHDRAWAL'] as const;

export const Currency = [
  'BTC',
  'ETH',
  'USDT',
  'BNB'
] as const;
