import MainWrapper from '../../components/MainWrapper';
import { GreetAdmin, RecentTable, StatCards } from '../../components/pageComponents';

const Dashboard = () => {
  return (
    <MainWrapper title='Dashboard'>
      <GreetAdmin />
      <StatCards />
      <RecentTable />
    </MainWrapper>
  );
};

export default Dashboard;
