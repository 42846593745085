import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { P2pTradeDto } from '../../../types';
import Pill from '../../Pill';
import { PriceSpan } from '../../shared';
import { RelativeTimeCell } from '../common';
const BooleanCell = ({ value }: Cell) => (
  value ? 'Yes' : 'No'
);
export const tradeColumns = (viewMore: boolean, showFull?: boolean,) => [
  {
    Header: '#',
    Cell: ({ row }: Cell) => <Fragment>{row.index + 1}</Fragment>,
  },
  {
    Header: 'Order Id',
    accessor: 'tradeOrderId',
  },
  {
    Header: 'Trade pair',
    accessor: 'order.crypto',
    Cell: ({row, value}: Cell<P2pTradeDto>)=> `${value}/${row.original.fiat}`
  },
  {
    Header: 'Trade Amount',
    accessor: 'amount',
    Cell: ({row, value}: Cell<P2pTradeDto>)=> `${Number(value).toFixed(8)}  ${row.original.crypto}`
  },
  {
    Header: 'Trade Price',
    accessor: 'amountToTransfer',
    Cell: ({row, value}: Cell<P2pTradeDto>)=>(<PriceSpan asset={row.original.crypto} qty={value} />) 
  },
  {
    Header: 'Payment Method',
    cell: ()=> "Bank Transfer"
  },
  {
    Header: 'Trade status',
    accessor: 'status',
    cell: ({value}: Cell)=>(<Pill>{value}</Pill>)
  },
  {
    Header: 'Dispute status',
    accessor: 'disputed',
    Cell: BooleanCell
  },
  {
    Header: 'Time',
    accessor: 'createdAt',
    Cell: RelativeTimeCell,
  },
  ...(viewMore ? [
    {
      Header: ' ',
      accessor: 'id',
      Cell: ({value}: Cell)=>(<Link to={`trades/${value}`}>view more</Link>)
    }
  ]:[])
  
];

