import {
    Dialog, Switch
} from "@material-tailwind/react";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { addPaymentChannel } from "../../api";
import Button from "../Button";
import Input from "../Input";
import { FormErrorText } from "../shared";

export default function AddPaymentChannelModal({open, closeModal}: any) {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleCloseModal = ()=>{
        formik.resetForm();
        closeModal();
    }
    const formik = useFormik({
        initialValues: {
            slug:"",
            title: "",
            channelColor:"",
            isGlobal: false,
            options: ""
        },
        validationSchema: Yup.object().shape({
            slug: Yup.string().required(),
            title: Yup.string().required(),
        }),
        onSubmit: (value)=>{
            
            setError("");
            setLoading(true)
            addPaymentChannel(value)
            .then((_)=>{
                toast.success("Request was successful")
            })
            .catch((error) => {
                const errors =error?.response?.data;
                if(error){
                    for (const key in errors) {

                        console.log(`${key}: ${errors[key]}`);
                        formik.setFieldError(key, errors[key]);
                    }
                }else{
                    toast.error(error?.response?.data?.message || 'Request failed. Please retry!');
                }
                
                
            })
            .finally(()=>{setLoading(false)})
        }
    });
  return (
    <Fragment>
      
      <Dialog className="flex flex-col px-6 py-8" open={open} handler={handleCloseModal} >
        <h3 className="flex text-4xl font-bold justify-center items-center">Create Payment Channel</h3>    
        <form className="flex flex-col gap-4 justify-start" onSubmit={formik.handleSubmit}>
            <div className="p-2 flex justify-center">
                <FormErrorText error={error}/>
            </div>
            
            <div className="flex flex-col gap-1">
                <p className="text-14 font-normal">Slug</p>
                <Input 
                    name="slug" 
                    placeholder="Enter slug"
                    value={formik.values.slug}
                    onChange={formik.handleChange}
                    error={formik.errors.slug}
                />
            </div>

            <div className="flex flex-col gap-1">
                <p className="text-14 font-normal">Title</p>
                <Input 
                    placeholder="Enter title" 
                    name="title" 
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.errors.title}
                    />
                    
            </div>

            <div className="flex flex-col gap-1">
                <p className="text-14 font-normal">Channel Color</p>
                <Input 
                    placeholder="Enter channel color" 
                    name="channelColor" 
                    value={formik.values.channelColor}
                    onChange={formik.handleChange}
                    />
            </div>
            <Switch 
                label="Is Global" 
                name="isGlobal"
                checked={formik.values.isGlobal}
                onChange={formik.handleChange}
            />
            
            <p className="text-black">Payment channel configuration</p>

            <div className="flex flex-col gap-1">
                <p className="text-14 font-normal">Json String</p>
                <Input 
                    placeholder="Enter options" 
                    name="options" 
                    value={formik.values.options}
                    onChange={formik.handleChange}
                    error={formik.errors.options}
                    />
                    
            </div>

            <div className="flex justify-between gap-4">
                <Button 
                    type="submit"
                    className="bg-gray-500 text-white p-2 rounded text-sm w-1/2"
                    loading={loading}
                    >
                    Submit
                </Button>
                <Button
                    className="bg-amber-500 text-white p-2 rounded text-lg w-1/2"
                    onClick={handleCloseModal}
                    >
                    Cancel
                </Button>
            </div>
                
        </form>
      </Dialog>
    </Fragment>
  );
}