import MainWrapper from "../../components/MainWrapper";
import { TransactionMetric, TransactionsList } from "../../components/pageComponents";
import { useAdminPermission } from "../../hooks";

const Transaction = () => {
    const { hasRequiredPermissions } = useAdminPermission();
    const canViewMetric = hasRequiredPermissions([], 'ADMIN');
    return (
      <MainWrapper title="Transactions Manager">
        {canViewMetric && 
          <TransactionMetric />
        }
        
        <TransactionsList />
      </MainWrapper>
    )
  }
  
  export default Transaction;