import { WalletTable } from "../../../tables";

const WalletsList = () => {
    
    return (
      <div>
        <WalletTable limit={20} />
      </div>
    );
  };
  
  export default WalletsList;