import React, { Fragment } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import Input from '../../../Input';


interface IProps {
  dateRange: any;
  module:string;
  email:string;
  handleDateChange: (value: any) => void;
  setEmail: (value: any) => void;
  setModule: (value: any) => void;
}

const AuditFilterFields: React.FC<IProps> = (props) => {
  const labelClassName = 'text-10 text-gray-500 font-semibold mb-2';
  return (
    <Fragment>

      <div className='col-span-2'>
        <div className={labelClassName}>Module</div>
        <Input
          value={props.module}
          onChange={(e) => props.setModule(e.target.value as any)}
          className='border rounded-md text-13'
        />
      </div>
      <div className='col-span-2'>
        <div className={labelClassName}>Email</div>
        <Input
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value as any)}
          className='border rounded-md text-13'
        />
      </div>
      <div className='col-span-2'>
        <div className={labelClassName}>Date</div>
        <Datepicker
          useRange={false}
          value={props.dateRange}
          onChange={props.handleDateChange}
          containerClassName='border rounded-md text-13'
        />
      </div>

      
    </Fragment>
  );
};

export default AuditFilterFields;
