import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '../middlewares';
import { AdminUserDetails, AdminUsers } from '../pages';

const AdminUserRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthGuard userType='ADMIN' />}>
        <Route index element={<AdminUsers />} />
      </Route>
      <Route element={<AuthGuard userType='ADMIN' permissions={['VIEW_ADMIN_USER']} />}>
        <Route path=':userId' element={<AdminUserDetails />} />
      </Route>
    </Routes>
  );
};

export default AdminUserRoutes;
