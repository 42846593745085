import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useWindowSizes } from '../hooks';

interface IMenuContext {
  pageHeading: string;
  showSideNav: boolean;
  isSmallScreen: boolean;
  setShowSideNav: React.Dispatch<React.SetStateAction<boolean>>
  setPageHeading: React.Dispatch<React.SetStateAction<string>>
}

export const MenuProviderContext = React.createContext<IMenuContext | null>(null);

export const MenuProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { windowWidth } = useWindowSizes();
  const [pageHeading, setPageHeading] = useState<string>('');
  const [showSideNav, setShowSideNav] = useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(true);
  

  useEffect(() => {
    setIsSmallScreen(windowWidth <= 768);
    return () => setIsSmallScreen(true)
  }, [windowWidth]);

  useEffect(() => {
    setShowSideNav(!isSmallScreen);
    return () => setShowSideNav(false)
  }, [isSmallScreen]);

  const value: IMenuContext = {
    pageHeading,
    showSideNav,
    isSmallScreen,
    setShowSideNav,
    setPageHeading
  };
  return <MenuProviderContext.Provider value={value}>{children}</MenuProviderContext.Provider>;
};

export const useMenu = (pageHeading: string = ''): IMenuContext => {
  const context = React.useContext(MenuProviderContext);
  if (!context) throw new Error(`useMenu hook cannot be called outside MenuProvider context.`);  
  return context;
};
