import { Checkbox, Dialog } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateAdminPermission } from '../../api';
import { AdminPermission, AdminPermissions, AdminUserDto } from '../../types';
import Button from '../Button';

interface IModal {
  closeModal: () => void;
  open: boolean;
  user?: AdminUserDto;
  setUser: (user: AdminUserDto) => void;
}

export default function PermissionsModal({ open, closeModal, user, setUser }: IModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<AdminPermission[]>([]);

  useEffect(() => {
    if (user?.permissions) {
      setPermissions(user.permissions);
    }
  }, [user]);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleCheckBocChange = (e: any) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((v) => v !== value));
    }
  };

  const handleChangeRole = () => {
    setLoading(true);
    updateAdminPermission(user?.id!, { permissions: permissions! })
      .then((data) => {
        setUser(data);
        closeModal();
      })
      .catch((error) => {
        const message = error?.response?.data?.message || 'Update failed. Please retry';
        toast.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const userPermission = AdminPermissions.map((val, i) => {
    return (
      <Checkbox
        color='purple'
        checked={permissions.includes(val)}
        onChange={handleCheckBocChange}
        key={i}
        value={val}
        label={val}
      />
    );
  });

  return (
    <Fragment>
      <Dialog className='flex flex-col px-6 py-8 gap-1' open={open} handler={handleCloseModal}>
        <div className='text-[#353535] text-24 font-bold'>Set permission</div>
        <div className='flex flex-wrap mt-14 gap-2'>{userPermission}</div>
        <Button
          onClick={handleChangeRole}
          loading={loading}
          className='mt-8 p-4 w-full bg-[#8D6ABD] rounded-lg'>
          Confirm
        </Button>
      </Dialog>
    </Fragment>
  );
}
