import { Dialog } from "@material-tailwind/react";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { getWalletById, updateWalletLimit } from "../../api";
import { WalletDto } from '../../types';
import Button from "../Button";
import Input from "../Input";
import { FormErrorText } from "../shared";

interface IProp{
    open:boolean,
    closeModal:()=>void,
    walletId?: string

}
export default function UpdateWalletLimitModal({open, closeModal, walletId}: IProp) {
    const [loading, setLoading] = useState<boolean>(false);
    const [wallet, setWallet] = useState<WalletDto|null>(null);
    const [error, setError] = useState<string>('');

    useEffect(()=>{
        if(walletId){
          getWalletById(walletId)
          .then(data => {
            setWallet(data)
            })
          .catch(console.log)
        }
      }, [walletId])
    
    const formik = useFormik({
        initialValues: {
            dailyLimit : 0,
            dailyTradeLimit: 0,
        },
        validationSchema: Yup.object().shape({
            dailyLimit:Yup.string(),
            dailyTradeLimit:Yup.string(),
        }).test(
            'is-optional',
            `dailyLimit or dailyTradeLimit is required`,
            function({ dailyLimit, dailyTradeLimit }) {
                return dailyLimit === '' && dailyTradeLimit === '' ? false : true
            }
        ),
        onSubmit: (value)=>{
            setError("");
            setLoading(true);
            updateWalletLimit(walletId!, value)
            .then(_ => {
                toast.success("Request was successful")
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || 'Request failed. Please retry!');
            })
            .finally(()=>{setLoading(false)})
        }
    });

    useEffect(()=>{
        if (wallet) {
            formik.setValues({'dailyLimit': wallet.dailyLimit, 'dailyTradeLimit': wallet.dailyTradeLimit})
        }
    }, [wallet, formik])

  return (
    <Fragment>
      
      <Dialog open={open} handler={closeModal} >
        <div>
            <div className="flex flex-col items-center pt-2 pb-10 sm:justify-center sm:pt-0 bg-gray-50">
                <div className="pt-4">
                    <h3 className="text-4xl font-bold ">
                        Update Wallet Limit
                    </h3>
                </div>
                <div className="w-full px-6 py-4 mt-4 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-2 flex justify-center">
                            <FormErrorText error={error}/>
                        </div>
                        <div className="mt-4">
                            <Input 
                                label="Daily Limit" 
                                name="dailyLimit" 
                                type='number'
                                value={formik.values.dailyLimit}
                                onChange={formik.handleChange}
                                error={formik.errors?.dailyLimit}
                            />
                        </div>

                        <div className="mt-4">
                            <Input 
                                label="Daily Trade limit" 
                                name="dailyTradeLimit" 
                                type='number'
                                value={formik.values.dailyTradeLimit}
                                onChange={formik.handleChange}
                                error={formik.errors.dailyTradeLimit}/>
                                
                        </div>

                        <div className="p-2 flex">
                            <div className="w-1/2"></div>
                            <div className="w-1/2 flex justify-end">
                                <Button 
                                    type="submit"
                                    className="bg-gray-500 text-white p-2 rounded text-sm w-auto"
                                    //onClick={()=> {toggleStatus(appModule.moduleId)}}
                                    loading={loading}
                                    >
                                    Submit
                                </Button>
                                <Button
                                    className="bg-red-500 text-white p-2 ml-6 rounded text-lg w-auto"
                                    onClick={closeModal}
                                    >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
      </Dialog>
    </Fragment>
  );
}