import { useEffect } from 'react';

import { useAuth } from '../../../../hooks';
import AuthWrapper from '../../../../components/AuthWrapper';
import { Button } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { authRoutes } from '../../../../config';

const Init2fa = () => {
  const navigate = useNavigate();
  const { totpQrcode, init2fa, loading } = useAuth();

  useEffect(() => {
    if (!totpQrcode && !loading) {
      init2fa();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToVerify = () => {
    navigate(authRoutes.verifyLogin);
  };

  return (
    <AuthWrapper siteTitle='Setup 2FA | BitBarter Technology'>
      <div className='flex flex-col max-w-md items-center mx-auto gap-6'>
        <h2 className='title-sm'>Setup BitBarter/Google Authentication</h2>
        <img src={totpQrcode} alt='' />
        <p className='text-center text-8 text-red-200'>
          Open Goole or Authy authenticator and add your BitBarter Account by scanning the QR code
          above. After that click continue to complete your setup.
        </p>
        <div className='w-full border-t py-4 text-center'>
          <Button
            size='sm'
            className='capitalize font-inherit max-w-xs px-10'
            onClick={() => goToVerify()}>
            Continue
          </Button>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Init2fa;
