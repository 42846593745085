import { Cell } from 'react-table';
import Pill from '../../../Pill';
import cn from 'classnames';

const MultiStatusCell = ({ column, value }: Cell) => (
  <div className={cn([(column as any).className, 'flex gap-2'])}>
    {(Array.isArray(value) ? value : [value]).map((text, key) => (
      <Pill key={key} text={text} />
    ))}
  </div>
);

export default MultiStatusCell;
